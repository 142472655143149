import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Typography, Layout, Row, Col, Image, Flex, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
// import '../login.css';
import wlogo from '../assets/Zipler-logo.png';
import blogo from '../assets/zblogo.png';
import welcomeImg from '../assets/welcome.png';
import { GiLaptop } from 'react-icons/gi';
import { BsBalloonHeart, BsChatLeftHeart, BsRobot, BsWhatsapp } from 'react-icons/bs';

const { Link, Title, Text, Paragraph } = Typography;

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    let deviceType, deviceOS;

    if (/android/i.test(userAgent)) {
        deviceType = 'Android';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/Android\s([0-9\.]+)/);
        deviceOS = match ? `Android ${match[1]}` : 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        deviceType = 'iOS';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/OS\s([0-9\._]+)/);
        deviceOS = match ? `iOS ${match[1].replace(/_/g, '.')}` : 'iOS';
    } else if (/Windows NT/.test(userAgent)) {
        deviceType = 'Windows';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/Windows NT\s([0-9\.]+)/);
        deviceOS = match ? `Windows ${match[1].replace(/_/g, '.')}` : 'Windows';
    } else {
        deviceType = 'Web';
        deviceOS = 'N/A';
    }
    return { deviceType, deviceOS };
};

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [shake, setShake] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        setShake(false);
        const url = `${window.API_BASE_URL}/auth/login`;
        const deviceId = uuidv4();
        const { deviceType, deviceOS } = getDeviceInfo();

        const requestBody = {
            identifier: values.identifier,
            user_password: values.password,
            device_id: deviceId,
            device_type: deviceType,
            device_os: deviceOS
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('Response:', data);

            if (response.ok) {
                if (data.success === true) {
                    message.success('Login Successfull');
                    localStorage.setItem('loginToken', data.token);
                    localStorage.setItem('deviceId', deviceId);
                    setTimeout(() => {
                        navigate('/');
                    }, 500);
                } else {
                    message.success('Login Failed');
                    console.error('Login failed:', data.message);
                }
            } else {
                console.error('Login failed:', data.message);
                message.success('Login Failed');
                setShake(true);
                setTimeout(() => setShake(false), 500);
            }
        } catch (error) {
            console.error('Error:', error);
            setShake(true);
            setTimeout(() => setShake(false), 500);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitFailed = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };

    return (
        <Layout>
            <Row>
                <Col span={12} className='hidden-on-phone'>
                    <div className='register-left-side'>
                        <Flex justify='center' align='center' style={{ marginTop: 24 }}>
                            <div className='main-reg-logo'>
                                <Image src={wlogo} preview={false} className='reg-logo' />
                            </div>
                        </Flex>
                        <Flex gap={16} align='center'>
                            <Image src={welcomeImg} preview={false} className='reg-welcome-image' />
                            <Title level={4} className='reg-main-title'>Grow your business with best Customer Retention Platform</Title>
                        </Flex>
                        <div className='reg-content'>
                            <ul className='reg-content-ul'>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <GiLaptop size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Integrate POS</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>Seamlessly plug and play with any POS</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsBalloonHeart size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Activate loyalty program</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>What if Zipler.io tell you that you can turn your one-time buyers into loyal customers? Yes! With Zipler.io you can launch loyalty programs</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsWhatsapp size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Send Campaigns</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>What if Zipler.io tell you: “You have 3,177 high-spending customers who visited 3+ times every month but haven’t returned in last 3 months. Send them a campaign now to win them back!</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsRobot size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Autopilot Campaigns</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>An option to send a series of marketing messages based on key events like their first purchase date, Point Earned, Referrals, their birthday, or if they’re at risk of not coming back.</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsChatLeftHeart size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Activate Feedback</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>See your customer's compliments and suggestion easily to boost sales and satisfaction. Get instant alerts for negative feedback on WhatsApp to resolve issues promptly.</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col sm={24} md={12} lg={12} >
                    <div className='register-right-side'>
                        <div className={`reg-right-main-div ${shake ? 'shake' : ''}`} style={{ minWidth: 415 }}>
                            <Flex justify='center' align='center' style={{ marginTop: 24 }} className='on-phone'>
                                <div className='main-reg-logo'>
                                    <Image src={blogo} preview={false} className='reg-logo' />
                                </div>
                            </Flex>
                            <div className='reg-right-main-title-div'>
                                <Title className='reg-right-main-title' level={2}><span>Welcome back!</span></Title>
                                <Paragraph className='reg-right-sub-title'>Happy to serve you again.</Paragraph>
                            </div>
                            <Form
                                name="login_form"
                                onFinish={handleSubmit}
                                onFinishFailed={handleSubmitFailed}
                                layout="vertical"
                            >
                                <Form.Item
                                    name="identifier"
                                    label="Email or Phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email or phone number!',
                                        },
                                    ]}
                                >
                                    <Input autoComplete='username' placeholder="Enter your email or phone number" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="current-password"
                                        placeholder="Enter your password"
                                        iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                        onClick={togglePasswordVisibility}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    <Button type="primary" htmlType="submit" block loading={loading} style={{ height: 44, fontWeight: 500, marginTop: 24 }}>
                                        {!loading && 'Login to your Zipler.io account'}
                                    </Button>
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    <Text>
                                        Don't have an account? <Link href="/register" style={{ fontWeight: 'bold' }}>Register now</Link>
                                    </Text>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default LoginForm;