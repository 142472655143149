import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Flex, message, Typography, Image, Col, Row } from 'antd';
import chatImage from '../assets/chat-img.webp';
import { PiCheckCircleDuotone } from 'react-icons/pi';
const { Paragraph, Title } = Typography;

const WhatsAppSignup = ({ userData, sessionData, storeData, settings }) => {
    const [sessionInfoResponse, setSessionInfoResponse] = useState(null);
    const [sdkResponse, setSdkResponse] = useState(null);

    useEffect(() => {
        // Load Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '562174432875062', // Your Facebook App ID
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v21.0',
            });
        };

        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            const js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // Add event listener for postMessage from Facebook
        const handleMessage = (event) => {
            if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
                return;
            }
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        message.success(`Signup complete! Phone number ID: ${phone_number_id}, WhatsApp business account ID: ${waba_id}`);
                        // updateWhatsappData(phone_number_id, waba_id);
                    } else if (data.event === 'CANCEL') {
                        const { current_step } = data.data;
                        message.warning(`Signup canceled at: ${current_step}`);
                    } else if (data.event === 'ERROR') {
                        const { error_message } = data.data;
                        message.error(`Error during signup: ${error_message}`);
                    }
                }
                setSessionInfoResponse(data);
            } catch (error) {
                console.error('Non-JSON Response', event.data);
            }
        };

        window.addEventListener('message', handleMessage);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const fbLoginCallback = (response) => {
        if (response.authResponse) {
            const { code } = response.authResponse;
        } else {
            message.error('Login failed or canceled.');
        }
        setSdkResponse(response);
    };

    const getAccessToken = useCallback(async (code, phone_number_id, waba_id) => {
        try {
            const authResponse = await fetch(`${window.API_BASE_URL}/users/facebook-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({ code, phone_number_id, waba_id }),
            });

            const data = await authResponse.json();
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    }, [sessionData]);

    useEffect(() => {
        if (sdkResponse && sessionInfoResponse) {
            if (sdkResponse.authResponse) {
                const { code } = sdkResponse.authResponse;
                if (sessionInfoResponse.type === 'WA_EMBEDDED_SIGNUP') {
                    if (sessionInfoResponse.event === 'FINISH') {
                        getAccessToken(code, sessionInfoResponse.data?.phone_number_id, sessionInfoResponse.data?.waba_id);
                    }
                }
            }
        }
    }, [getAccessToken, sdkResponse, sessionInfoResponse]);

    const launchWhatsAppSignup = () => {
        window.FB.login(fbLoginCallback, {
            config_id: '1541039033217696',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                setup: {},
                featureType: '',
                sessionInfoVersion: '2',
            },
        });
    };

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={16} justify={'center'}>
                <Col span={16}>
                    <Card bordered={false} hoverable>
                        <Title level={4} style={{ margin: 0 }}>Guide to connect your number to WhatsApp Business API</Title>
                        <Flex align='center'>
                            <div>
                                <Image src={chatImage} preview={false} />
                            </div>
                            <div>
                                <Flex align='center' gap={8} flex=''>
                                    <div>
                                        <PiCheckCircleDuotone size={36} />
                                    </div>
                                    <div>
                                        <Paragraph style={{ margin: 0 }}>Keep your business phone number handy to receive OTP!</Paragraph>
                                    </div>
                                </Flex>
                                <Flex align='center' gap={8}>
                                    <div>
                                        <PiCheckCircleDuotone size={36} />
                                    </div>
                                    <div>
                                        <Paragraph style={{ margin: 0 }}>If you are currently using WhatsApp with that number, backup and remove it.</Paragraph>
                                    </div>
                                </Flex>
                                <Flex align='center' gap={8}>
                                    <div>
                                        <PiCheckCircleDuotone size={36} />
                                    </div>
                                    <div>
                                        <Paragraph style={{ margin: 0 }}>Keep your Facebook Business Manager account ready.</Paragraph>
                                    </div>
                                </Flex>
                            </div>
                        </Flex>
                        <Flex justify='end'>
                            <Button type="primary" onClick={launchWhatsAppSignup}>Connect your whatsapp number</Button>
                        </Flex>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default WhatsAppSignup;