import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { parsePhoneNumber } from 'libphonenumber-js';
import waitImage from '../assets/review.gif';
import { Layout, Row, Col, Typography, Flex, Button, Input, Card, List, Spin, Drawer, Upload, Table, message, Modal, Image, Select } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { PiArrowArcLeftDuotone, PiArrowArcRight, PiClockClockwiseDuotone, PiDownloadDuotone, PiHeartStraightDuotone, PiMegaphoneDuotone, PiPlayDuotone, PiPlusDuotone, PiSwapDuotone, PiTagDuotone, PiTrashDuotone, PiUploadDuotone } from 'react-icons/pi';
import eComImage from '../assets/1690797230296.jpeg';
import CreateCustomers from './CreateCustomer';
import confetti from 'canvas-confetti';
import sampleFile from '../assets/sample/zipler-sample-customers.csv';
import { FcBinoculars } from 'react-icons/fc';
const { Paragraph, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const listData = [
    'Remove duplicate, incorrect, Zomato/ Swiggy virtual numbers',
    'Make sure your birthday and anniversary are in format: DD-MM-YYYY',
    'Imported customers that haven’t visited will be a part of the imported segment'
];

const listDataTwo = [
    'Remove duplicate, incorrect, Zomato/ Swiggy virtual numbers',
    'Transaction date should be in specified format: DD-MM-YYYY HH:MM:SS',
    'Make sure that each unique bill number only has one redemption action in txn_type.'
];

const listDataOne = [
    {
        key: '1',
        icon: <PiDownloadDuotone size={24} />,
        value: 'Export your past customers data from POS or phone',
    },
    {
        key: '2',
        icon: <PiUploadDuotone size={24} />,
        value: 'Import them in ZIpler.io via csv template',
    },
    {
        key: '3',
        icon: <PiHeartStraightDuotone size={24} />,
        value: 'Give them bonus points to promote your loyalty program',
    },
    {
        key: '4',
        icon: <PiMegaphoneDuotone style={{ transform: 'scaleX(-1)' }} size={24} />,
        value: 'Send campaigns to them in future',
    },
    {
        key: '5',
        icon: <PiPlayDuotone size={24} />,
        value: <span style={{ fontWeight: 'bold', color: '#3A1A55' }}>Watch How to import customers?</span>,
    },
];

const itemStyle = { border: 'none', padding: '4px 0' };
const itemContainerStyle = { display: 'flex', alignItems: 'center', gap: 8 };
const textStyle = { color: '#697777', margin: 0, wordBreak: 'break-word' };

const importAll = (requireContext) => requireContext.keys().map(requireContext);
const images = importAll(require.context('../assets/pos/', false, /\.(png|jpe?g|svg)$/));

const AddCustomersContent = ({ userData, sessionData, storeData }) => {
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [importNewCustomerDrawer, setImportNewCustomerDrawer] = useState(false);
    const [addNewCustomerDrawer, setAddNewCustomerDrawer] = useState(false);
    const [customerFile, setCustomerFile] = useState(null);
    const [customerData, setCustomerData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [onUpload, setOnUpload] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const { contentType } = useParams();
    useEffect(() => {
        setPageLoading(false);
    }, [contentType]);

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (value) => {
        setSearchTerm(value);
        console.log(searchTerm);
    };

    const handleChangeContentType = (contentType) => {
        navigate(`/add-customers/${contentType}`);
    };

    const handleAddNewCustomer = () => {
        setAddNewCustomerDrawer(true);
    };

    const handleConfetti = () => {
        confetti({
            particleCount: 500,
            spread: 100,
            origin: { y: 0.6 },
        });
    };

    const beforeUpload = (file) => {
        setCustomerFile(file);
        return false;
    };

    const handleUploadData = () => {
        setOnUpload(true);
    };

    const filteredCustomers = customerData.filter(customer => {
        // Check if the customer is not existing, not a duplicate, and valid
        return !customer.existing && !customer.duplicate && !customer.invalid;
    });

    const handleSubmitData = async () => {
        setSubmitting(true);
        const data = {
            tags: selectedTags,
            customers: filteredCustomers,
            file_name: customerFile?.name,
            store_id: localStorage.getItem('storeId')
        };
        try {
            const url = `${window.API_BASE_URL}/users/import-bulk-customers`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            if (!response.ok) {
                console.error("Error while uploading customers:", response);
            } else {
                handleConfetti();
                message.success('Customr data uploaded successfully.');
                console.log(responseData);
            }
        } catch (error) {
            console.error("Error proceeding payment data:", error);
        } finally {
            setImportNewCustomerDrawer(false);
            setCustomerData([]);
            setPageNumber(1);
            setPageSize(20);
            setCustomerFile(null);
            setSubmitting(false);
            setOnUpload(false);
            setSelectedTags(null);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone#',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => {

                return (
                    <span className={`phoneno ${record?.invalid ? 'invalid' : record?.existing ? 'alreadyexist' : record?.duplicate ? 'duplicate' : 'valid'}`}>
                        {record.phone.startsWith('+') ? `${record.countrycode}${record.phone}` : `+${record.countrycode}${record.phone}`}
                    </span>
                );
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Birth Date',
            dataIndex: 'birthdate',
            key: 'birthdate',
        },
        {
            title: 'Anniversary',
            dataIndex: 'anniversary',
            key: 'anniversary',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
        },
    ];

    const checkExistingCustomers = async (customers) => {
        setLoading(true);
        const data = {
            customers: customers,
        };
        try {
            const url = `${window.API_BASE_URL}/users/check-existing-customers`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            if (responseData.success) {
                return responseData.existingCustomers;
            } else {
                console.error("Error while checking existing customers:", response);
                return [];
            }
        } catch (error) {
            console.error("Error checking existing customers:", error);
            return [];
        } finally {
            setLoading(false);
        }
    };

    const HandleImportCustomers = async () => {
        const fileExtension = customerFile.name.split('.').pop().toLowerCase();
        const removeNonNumericPhoneEntries = (data) => {
            const seenPhones = new Set();
            return data.map(row => {
                const phoneValue = row.phone;
                const phoneNumber = row.countrycode && row.countrycode !== '' ? row.countrycode.startsWith('+') ? parsePhoneNumber(`${row.countrycode}${row.phone}`) : parsePhoneNumber(`+${row.countrycode}${row.phone}`) : undefined;
                let isDuplicate = false;
                if (!isNaN(phoneValue) && phoneValue !== '') {
                    if (seenPhones.has(phoneValue)) {
                        console.log('duplicate');
                        isDuplicate = true;
                    } else {
                        seenPhones.add(phoneValue);
                    }
                }
                return { ...row, duplicate: isDuplicate, invalid: !phoneNumber };
            }).filter(row => !isNaN(row.phone) && row.phone !== '');
        };

        if (fileExtension === 'csv') {
            Papa.parse(customerFile, {
                header: true,
                complete: async (results) => {
                    const cleanedData = removeNonNumericPhoneEntries(results.data);
                    const existingCustomers = await checkExistingCustomers(cleanedData);
                    const markedData = cleanedData.map(row => {
                        const isExisting = existingCustomers.some(existing => existing.customer_number === `${row.countrycode}${row.phone}` || existing.customer_email === row.email);
                        return { ...row, existing: isExisting };
                    });
                    console.log('Processed CSV data:', markedData);
                    setCustomerData(markedData);
                    setImportNewCustomerDrawer(true);
                },
                error: (err) => {
                    console.error('Error reading CSV file:', err);
                }
            });
        } else if (fileExtension === 'xlsx') {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                const cleanedData = removeNonNumericPhoneEntries(jsonData);
                const existingCustomers = await checkExistingCustomers(cleanedData);
                const markedData = cleanedData.map(row => {
                    const isExisting = existingCustomers.some(existing => existing.customer_number === `${row.countrycode}${row.phone}` || existing.customer_email === row.email);
                    return { ...row, existing: isExisting };
                });

                console.log('Processed XLSX data:', markedData);
                setCustomerData(markedData);
                setImportNewCustomerDrawer(true);
            };
            reader.readAsBinaryString(customerFile);
        } else {
            message.error('Unsupported file format. Please upload a CSV or XLSX file.');
            console.error('Unsupported file format. Please upload a CSV or XLSX file.');
        }
        return false;
    };

    const handleTagsSelect = (value) => {
        setSelectedTags(value);
    };

    const handleCloseImportDrawer = () => {
        setPageNumber(1);
        setPageSize(20);
        setCustomerData([]);
        setSelectedTags(null);
        setImportNewCustomerDrawer(false);
    };

    useEffect(() => {
        console.log("Current page number:", pageNumber);
        console.log("Current page size:", pageSize);
    }, [pageNumber, pageSize]);

    return (
        pageLoading ? (
            <Layout className='contentLayout'>
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Layout>
        ) : (
            <Layout className='contentLayout'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                    <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Add Customers</b></Title>
                </div>
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex gap='middle'>
                            <Button type={!contentType ? 'primary' : contentType === 'add-manually' && 'primary'} onClick={() => handleChangeContentType('add-manually')} shape="round" size='large'>Add Manually</Button>
                            <Button type={contentType === 'import-customers' && 'primary'} onClick={() => handleChangeContentType('import-customers')} shape="round" size='large'>Import Customers</Button>
                            <Button type={contentType === 'pos-integrations' && 'primary'} onClick={() => handleChangeContentType('pos-integrations')} shape="round" size='large'>POS Integrations</Button>
                            <Button type={contentType === 'ecommerce-integration' && 'primary'} onClick={() => handleChangeContentType('ecommerce-integration')} shape="round" size='large'>E-Commerce Integration</Button>
                            {/* <Button type={contentType === 'import-transactions' && 'primary'} onClick={() => handleChangeContentType('import-transactions')} shape="round" size='large'>Import Transactions</Button> */}
                        </Flex>
                    </Col>
                </Row>
                {!contentType || contentType === 'add-manually' ? (
                    <Row gutter={16} className='custom-margin'>
                        <Col span={24}>
                            <Title level={3}>Add Manually</Title>
                            <Paragraph style={{ color: '#697777' }}>If you can’t find your POS to integrate, use Cashier Portal to add customers manually to successfully run your loyalty program, send campaigns and get unique insights about your customers.</Paragraph>
                        </Col>
                        <Col span={24}>
                            <Row gutter={8} className='custom-margin'>
                                <Col span={10}>
                                    <Card bordered={false} style={{ backgroundColor: '#f7f8f8', borderRadius: 16 }} hoverable>
                                        <div style={{marginBottom:16}}>
                                            <Button type='primary' onClick={handleAddNewCustomer}><PiPlusDuotone /> Add New Customer</Button>
                                        </div>
                                        <div>
                                            <Button type='primary' onClick={() => navigate('/billing')}><PiPlusDuotone /> Modify Customers</Button>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : contentType === 'import-customers' ? (
                    <Row gutter={16} className='custom-margin'>
                        <Col span={16}>
                            <Card bordered={false} style={{ backgroundColor: '#f5f8ff', paddingBottom: 9, borderRadius: 16 }} hoverable>
                                <Flex justify='space-between' align='center'>
                                    <div>
                                        <Paragraph style={{ margin: 0, color: '#374040', fontWeight: '500' }}>STEP 1</Paragraph>
                                        <Title level={4} style={{ margin: 0 }}>Download Import Template</Title>
                                        <Paragraph style={{ color: '#697777' }}>Only mobile number is mandatory. All the other fields are optional</Paragraph>
                                    </div>
                                    <Button href={sampleFile} target='_blank'><PiDownloadDuotone /> Download</Button>
                                </Flex>
                                {customerFile ? (
                                    <Flex justify='space-between' align='center'>
                                        <div className='custom-margin'>
                                            <Paragraph style={{ margin: 0, color: '#374040', fontWeight: '500' }}>STEP 2</Paragraph>
                                            <Title level={4} style={{ marginTop: 0 }}>{customerFile.name}</Title>
                                            <Flex justify='start' align='center' gap={16}>
                                                <Button onClick={() => setCustomerFile(null)}>Clear <PiTrashDuotone /></Button>
                                                <Button type='primary' onClick={HandleImportCustomers}>Continue <PiArrowArcRight /></Button>
                                            </Flex>
                                        </div>
                                        <Upload
                                            beforeUpload={beforeUpload}
                                            maxCount={1}
                                            showUploadList={false}
                                            accept=".csv, .xlsx"
                                        >
                                            <Button ><PiSwapDuotone /> Replace File</Button>
                                        </Upload>
                                    </Flex>
                                ) : (
                                    <Flex justify='space-between' align='center'>
                                        <div className='custom-margin'>
                                            <Paragraph style={{ margin: 0, color: '#374040', fontWeight: '500' }}>STEP 2</Paragraph>
                                            <Title level={4} style={{ marginTop: 0 }}>Upload your file here</Title>
                                            <Paragraph style={{ color: '#374040', fontWeight: '500' }}>Before you upload</Paragraph>
                                            <List
                                                bordered={false}
                                                dataSource={listData}
                                                size='small'
                                                renderItem={(item) => (
                                                    <List.Item style={{ border: 'none', padding: '4px 0' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginLeft: 16 }}>
                                                            <span
                                                                style={{
                                                                    width: '7px',
                                                                    height: '7px',
                                                                    backgroundColor: '#697777',
                                                                    borderRadius: '50%',
                                                                    flexShrink: 0,
                                                                }}
                                                            ></span>
                                                            <Typography.Text
                                                                style={{
                                                                    color: '#697777',
                                                                    margin: 0,
                                                                    wordBreak: 'break-word',
                                                                }}
                                                            >
                                                                {item}
                                                            </Typography.Text>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                        <Upload
                                            beforeUpload={beforeUpload}
                                            maxCount={1}
                                            showUploadList={false}
                                        >
                                            <Button type='primary'><PiUploadDuotone /> Upload File</Button>
                                        </Upload>
                                    </Flex>
                                )}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card bordered={false} style={{ backgroundColor: '#f7f8f8', borderRadius: 16 }} hoverable>
                                <Button style={{ width: '100%' }}><PiClockClockwiseDuotone /> Import History</Button>
                                <Title level={4}>Process</Title>
                                <List
                                    bordered={false}
                                    dataSource={listDataOne}
                                    size="small"
                                    renderItem={(item) => (
                                        <List.Item key={item.key} style={itemStyle}>
                                            <div style={itemContainerStyle}>
                                                <span style={{ color: '#3A1A55', display: 'flex', alignItems: 'center' }}>{item.icon}</span>
                                                <Typography.Text style={textStyle}>{item.value}</Typography.Text>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row>
                ) : contentType === 'pos-integrations' ? (
                    <Row gutter={16} className='custom-margin'>
                        <Col span={24}>
                            <Title level={3}>Integrate POS</Title>
                            <Paragraph style={{ color: '#697777' }}>Integrate your POS and automatically add every customer that visits your business to successfully run your loyalty program, send campaigns and unique insights about your customers.</Paragraph>
                            <Search placeholder="Search customers" onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px', maxWidth: 300 }} />
                        </Col>
                        {images.map((image, index) => (
                            <Col span={6} key={index}>
                                <Card className='pos-cards' hoverable>
                                    <div className='pos-cards-inner-div' style={{ backgroundImage: `url(${image})` }} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                ) : contentType === 'ecommerce-integration' ? (
                    <Row gutter={16} className='custom-margin'>
                        <Col span={24}>
                            <Title level={3}>Integrate E-Commerce Platforms</Title>
                            <Paragraph style={{ color: '#697777' }}>Integrate your E- Commerce platform and automatically add every customer that visits your business to successfully run your loyalty program, send campaigns and unique insights about your customers.</Paragraph>
                        </Col>
                        <Col span={6}>
                            <Card className='pos-cards' hoverable>
                                <div className='pos-cards-inner-div' style={{ backgroundImage: `url(${eComImage})` }} />
                            </Card>
                        </Col>
                    </Row>
                ) : contentType === 'import-transactions' && (
                    <Row gutter={16} className='custom-margin'>
                        <Col span={24}>
                            <Flex gap='middle' justify='space-between' align='center'>
                                <Title level={3}>Import Transactions</Title>
                                <Paragraph style={{ margin: 0, fontWeight: '500', color: '#3A1A55', display: 'flex', alignItems: 'center', cursor: 'pointer' }}><PiPlayDuotone size={20} /> &nbsp; How to import?</Paragraph>
                            </Flex>
                        </Col>
                        <Col span={24}>
                            <Row gutter={16} className='custom-margin'>
                                <Col span={16}>
                                    <Card bordered={false} style={{ backgroundColor: '#f5f8ff', paddingBottom: 91, borderRadius: 16 }} hoverable>
                                        <Flex justify='space-between' align='center'>
                                            <div>
                                                <Paragraph style={{ margin: 0, color: '#374040', fontWeight: '500' }}>STEP 1</Paragraph>
                                                <Title level={4} style={{ margin: 0 }}>Download Import Template</Title>
                                                <Paragraph style={{ color: '#697777' }}>Only mobile number is mandatory. All the other fields are optional</Paragraph>
                                            </div>
                                            <Button><PiDownloadDuotone /> Download</Button>
                                        </Flex>
                                        <div className='custom-margin'>
                                            <Paragraph style={{ margin: 0, color: '#374040', fontWeight: '500' }}>STEP 2</Paragraph>
                                            <Title level={4} style={{ marginTop: 0 }}>Upload your file here</Title>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card bordered={false} style={{ backgroundColor: '#f7f8f8', borderRadius: 16 }} hoverable>
                                        <Button style={{ width: '100%' }}><PiClockClockwiseDuotone /> Import History</Button>
                                        <Title level={4}>Before you upload</Title>
                                        <List
                                            bordered={false}
                                            dataSource={listDataTwo}
                                            size='small'
                                            renderItem={(item) => (
                                                <List.Item style={{ border: 'none', padding: '4px 0' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginLeft: 16 }}>
                                                        <span
                                                            style={{
                                                                width: '7px',
                                                                height: '7px',
                                                                backgroundColor: '#697777',
                                                                borderRadius: '50%',
                                                                flexShrink: 0,
                                                            }}
                                                        ></span>
                                                        <Typography.Text
                                                            style={{
                                                                color: '#697777',
                                                                margin: 0,
                                                                wordBreak: 'break-word',
                                                            }}
                                                        >
                                                            {item}
                                                        </Typography.Text>
                                                    </div>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                <Drawer
                    placement='bottom'
                    closable={true}
                    onClose={handleCloseImportDrawer}
                    open={importNewCustomerDrawer}
                    className='templateDrawer'
                    height="100vh"
                >
                    <Layout className='add-customers-layout'>
                        <Row gutter={16} align={'center'}>
                            <Col span={24}>
                                <div className='add-customers-div'>
                                    <Flex align='center' justify='space-between'>
                                        <Title level={3} style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                                            <b>Confirm Import Customer List</b>
                                        </Title>
                                        <Paragraph className='phone-validation-info'><span className='invalid' /> {customerData.filter(customer => { return customer.invalid }).length} Invalid <span className='duplicate' /> {customerData.filter(customer => { return customer.duplicate }).length} Duplicate<span className='alreadyexist' /> {customerData.filter(customer => { return customer.existing }).length} Already exist entries</Paragraph>
                                    </Flex>
                                    {importNewCustomerDrawer && (
                                        <Table className='customerTable import' dataSource={customerData} columns={columns} pagination={{
                                            total: customerData.length,
                                            pageSize: pageSize,
                                            pageNumber: pageNumber,
                                            pageSizeOptions: ['10', '20', '50', '100', '200', '500', '1000'],
                                            onChange: (page, pageSize) => {
                                                setPageNumber(page);
                                                setPageSize(pageSize);
                                            },
                                            showTotal: (total, range) => (
                                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                                            )
                                        }}
                                            scroll={{
                                                y: 'calc(100vh - 330px)',
                                            }}
                                        />
                                    )}
                                    <Flex gap={16} justify='end' align='center'>
                                        <Button onClick={handleCloseImportDrawer}><PiArrowArcLeftDuotone /> Back</Button>
                                        <Button type='primary' onClick={handleUploadData}><PiUploadDuotone /> Upload</Button>
                                    </Flex>
                                </div>
                            </Col>
                        </Row>
                    </Layout>
                </Drawer>
                <Modal open={onUpload} centered footer={false} closable={false}>
                    {submitting ? (
                        <div style={{ textAlign: 'center', }}>
                            <Title level={3}>Hang on!<br />Customers are uploading <FcBinoculars size={24} style={{ position: 'relative', top: 5 }} /></Title>
                            <Paragraph>Please do not close or refresh the page.</Paragraph>
                            <Image src={waitImage} preview={false} width={300} />
                            <div class="loader-line"></div>
                        </div>
                    ) : (
                        <div>
                            <Title level={3}><PiTagDuotone /> It's time to assign tags</Title>
                            <Select showSearch mode="tags" placeholder="Select Tags" style={{ width: '100%' }} onChange={handleTagsSelect} value={selectedTags}>
                                {tags.map(tag => (
                                    <Option key={tag?.tag_id} value={tag.tag_id}>{tag.tag_name}</Option>
                                ))}
                            </Select>
                            <Paragraph>Select existing tags or add new tag by typying</Paragraph>
                            <br />
                            <Flex align='center' justify='space-between' gap={16}>
                                <Button style={{ width: '100%' }} onClick={() => { setOnUpload(false); setSelectedTags(null) }}>Cancel</Button>
                                <Button type='primary' style={{ width: '100%' }} onClick={handleSubmitData}><PiUploadDuotone /> Upload</Button>
                            </Flex>
                        </div>
                    )}
                </Modal>
                <Modal open={loading} centered footer={false} closable={false}>
                    <div style={{ textAlign: 'center', }}>
                        <Title level={3}>Hang on!<br />We are checking existing Customers <FcBinoculars size={24} style={{ position: 'relative', top: 5 }} /></Title>
                        <Paragraph>Please do not close or refresh the page.</Paragraph>
                        <Image src={waitImage} preview={false} width={300} />
                        <div class="loader-line"></div>
                    </div>
                </Modal>
                <Drawer
                    placement='bottom'
                    closable={true}
                    onClose={() => setAddNewCustomerDrawer(false)}
                    open={addNewCustomerDrawer}
                    className='templateDrawer'
                    height="100vh"
                >
                    <CreateCustomers userData={userData} sessionData={sessionData} storeData={storeData} />
                </Drawer>
            </Layout>
        )
    );
};
export default AddCustomersContent;