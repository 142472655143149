import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
const { Paragraph, Title } = Typography;
const CustomAutoReply = () => {
    return (
        <Row gutter={16}>
            <Col span={24}>
                <Title level={5}>CustomAutoReply</Title>
            </Col>
        </Row>
    );
};
export default CustomAutoReply;