import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Layout, Row, Col, Typography, Switch, Flex, Button, Card, Dropdown, Tooltip, Drawer, Modal, message, ColorPicker, Upload, Progress, Image, Input, Select, Checkbox, Space, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, BulbFilled, InboxOutlined, UploadOutlined, LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import dummyImage from '../assets/dymmy.jpg';
import phonevector2 from '../assets/phonevector2.png';
import clockSvgOne from '../assets/loyalty/clock-emoji.svg';
import clockSvgTwo from '../assets/loyalty/clock-emoji-2.svg';
import coinSvg from '../assets/loyalty/coin-emoji.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import blogo from '../assets/Zipler.svg';
import confetti from 'canvas-confetti';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { PiArrowArcLeftDuotone, PiArrowArcRightDuotone, PiArrowLeftDuotone, PiArrowRight, PiArrowRightDuotone, PiBellRingingDuotone, PiChatCenteredDotsDuotone, PiCoinsDuotone, PiEnvelopeDuotone, PiEqualsDuotone, PiFloppyDiskDuotone, PiHandCoinsDuotone, PiImageDuotone, PiLightbulbDuotone, PiStarDuotone, PiStorefrontDuotone, PiTextTDuotone, PiTrashDuotone, PiWhatsappLogoDuotone } from 'react-icons/pi';
import whatsappMobileMokup from '../assets/zipler-mobile-mock-up.png';
import smsMobileMokup from '../assets/sms.png';
import emailMobileMokup from '../assets/email.png';
import emailWelcomeImage from '../assets/email/welcome.jpg';
import emailCashbackEarnImage from '../assets/email/cashbackearned.jpg';
import emailCashbackReduprionImage from '../assets/email/cashbackreduption.jpeg';
import emailCashbackReminderImage from '../assets/email/cashbackreminder.jpeg';
import emailCashbackExpiriengImage from '../assets/email/cashbackexpiring.jpeg';


import ImgCrop from 'antd-img-crop';
import PhoneInput from 'react-phone-input-2';
import html2canvas from 'html2canvas';
const { Link, Paragraph, Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;
Chart.register(...registerables);

const datePickerItems = [
    { key: '1', label: 'Highlights for Today' },
    { key: '2', label: 'Last 7 Days' },
    { key: '3', label: 'Last 30 Days' },
    { key: '4', label: 'This Month' },
    { key: '5', label: 'Last Month' },
    { key: '6', label: 'Last 12 Month' },
    { key: '7', label: 'Custom range' }
];

const generateRandomData = () => {
    let data = [];
    for (let i = 0; i < 30; i++) {
        const date = dayjs().add(i, 'day').format('MMM D');
        data.push({
            name: date,
            uv: Math.floor(Math.random() * 61),
            pv: Math.floor(Math.random() * 61),
        });
    }
    return data;
};

const twoColors = {
    '0%': '#108ee9',
    '50%': '#3A1A55',
    '100%': '#3A1A55',
};

const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};


const messageViewItems = [
    { key: 'welcome', label: 'Welcome' },
    { key: 'cashback_earned', label: 'Cashback earned' },
    { key: 'cashback_redemption', label: 'Cashback redemption' },
    { key: 'cashback_reminder', label: 'Cashback reminder' },
    { key: 'cashback_expiry', label: 'Cashback expiry' },
];

const LoyaltyContent = ({ userData, sessionData, storeData }) => {
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const navigate = useNavigate();
    const [stepLoader, setStepLoader] = useState(false);
    const [sendLoader, setSendLoader] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [steps, setSteps] = useState(1);
    const [file, setFile] = useState(null);
    const [banner, setBanner] = useState(null);
    const [loyaltyId, setLoyaltyId] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [cardTitle, setCardTitle] = useState('');
    const [cardSubTitle, setCardSubTitle] = useState('');
    const [termsText, setTermsText] = useState(`*Minimum purchase required.\n*2 offers cannot be clubbed.`);
    const [themeColor, setThemeColor] = useState('#3A1A55');
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [cashBackType, setCashBackType] = useState('per');
    const [cashback, setCashback] = useState(0);
    const [loyaltyType, setLoyaltyType] = useState('cashback');
    const [spentamt, setSpentAmt] = useState(1);
    const [visitPoint, setVisitPoint] = useState(1);
    const [loading, setLoading] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [visits, setVisits] = useState(1);
    const [loyaltyStatus, setLoyaltyStatus] = useState('active');
    const [spentamtPoint, setSpentamtPoint] = useState(1);
    const [pointName, setPointName] = useState('');
    const [pointItems, setPointItems] = useState([{ points: '', item: '' }]);
    const [isCashBackBranding, setIsCashBackBranding] = useState(false);
    const [isMinPurchaseForEarning, setIsMinPurchaseForEarning] = useState(false);
    const [isMinPurchaseForRedeeming, setIsMinPurchaseForRedmming] = useState(false);
    const [isMaxCashbackCanEarn, setIsMaxCashbackCanEarn] = useState(false);
    const [isSpecialCashBackOnProfileComplete, setIsSpecialCashBackOnProfileComplete] = useState(false);
    const [specialCashBackOnProfileCompletePoint, setSpecialCashBackOnProfileCompletePoint] = useState(0);
    const [specialCashBackOnProfileCompleteValue, setSpecialCashBackOnProfileCompleteValue] = useState(0);
    const [minPurchaseForEarning, setMinPurchaseForEarning] = useState(0);
    const [minPurchaseForRedeeming, setMinPurchaseForRedmming] = useState(0);
    const [maxCashbackCanEarn, setMaxCashbackCanEarn] = useState(0);
    const [brandingName, setBrandingName] = useState('');
    const [fileName, setFileName] = useState('');
    const [logoName, setLogoName] = useState('');
    const [isCashbackReminder, setIsCashbackReminder] = useState(false);
    const [cashbackReminderIn, setCashbackReminderIn] = useState(30);
    const [cashbackReminderInType, setCashBackReminderInType] = useState('Days');
    const [isRemindOnlyEligibleCustomer, setIsRemindOnlyEligibleCustomer] = useState('no');
    const [isCashbackExpiryReminder, setIsCashbackExpiryReminder] = useState(false);
    const [cashbackExpiryReminderIn, setCashbackExpiryReminderIn] = useState(30);
    const [cashbackExpiryReminderInType, setCashBackExpiryReminderInType] = useState('Months');
    const [loyaltyComunicationTypes, setLoyaltyComunicationTypes] = useState({ whatsapp: true, sms: false, email: false });
    const [sendIfFailsWhatsapp, setSendIfFailsWhatsapp] = useState('no');
    const [addWhatsappCustomText, setAddWhatsappCustomText] = useState(false);
    const [whatsappCustomText, setWhatsappCustomText] = useState('');
    const [isMultipleOrderAlerts, setIsMultipleOrderAlerts] = useState('no');
    const [multipleOrderAlertTypes, setMultipleOrderAlertTypes] = useState({ whatsapp: false, sms: false, email: false });
    const [multipleOrderAlertIn, setMultipleOrderAlertIn] = useState({ oneday: true, sevenday: false });
    const [multipleOrderAlertOneDay, setMultipleOrderAlertOneDay] = useState('');
    const [multipleOrderAlertSevenDay, setMultipleOrderAlertSevenDay] = useState('');
    const [reminderpreviewType, setReminderpreviewType] = useState('whatsapp');
    const [reminderpreviewMessage, setReminderpreviewMessage] = useState('welcome');
    const [testPhoneNo, setTestPhoneNo] = useState(storeDetail?.store_number ? storeDetail?.store_number : userData?.user_phone);
    const [testEmailId, setTestEmailId] = useState(storeDetail?.store_email ? storeDetail?.store_email : userData?.user_email);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    const fetchLoyaltyData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/loyalty/?store_id=${localStorage.getItem('storeId')}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                console.log(data);
                if (data?.loyalty) {
                    setLoyaltyId(data?.loyalty?.loyalty_id);
                    setLoyaltyType(data?.loyalty?.reward_type);
                    setCardTitle(data?.loyalty?.loyalty_title);
                    setCardSubTitle(data?.loyalty?.loyalty_subtitle);
                    setCashBackType(data?.loyalty?.cashback_in);
                    setCashback(data?.loyalty?.cashback);
                    setIsCashBackBranding(data?.loyalty?.is_cashback_branding);
                    setBrandingName(data?.loyalty?.cashback_branding_name);
                    setIsMinPurchaseForEarning(data?.loyalty?.is_mimimum_purchase_for_earning);
                    setMinPurchaseForEarning(data?.loyalty?.mimimum_purchase_for_earning);
                    setIsMinPurchaseForRedmming(data?.loyalty?.is_min_purchase_for_redeem);
                    setMinPurchaseForRedmming(data?.loyalty?.min_purchase_for_redeem);
                    setIsMaxCashbackCanEarn(data?.loyalty?.is_maximum_cashback_earn);
                    setMaxCashbackCanEarn(data?.loyalty?.maximum_cashback_earn);
                    setIsSpecialCashBackOnProfileComplete(data?.loyalty?.is_special_cashback);
                    setSpecialCashBackOnProfileCompletePoint(data?.loyalty?.special_cashback_coins);
                    setSpecialCashBackOnProfileCompleteValue(data?.loyalty?.special_cashback_rupees_per_coin);
                    setPointName(data?.loyalty?.point_name);
                    setSpentAmt(data?.loyalty?.customer_spent_or_visits);
                    setVisitPoint(data?.loyalty?.customer_earn);
                    setVisits(data?.loyalty?.redeem_setting);
                    setTermsText(data?.loyalty?.term_conditions);
                    setLogoName(data?.loyalty?.logo);
                    setFileName(data?.loyalty?.banner);
                    setThemeColor(data?.loyalty?.theme_color);
                    setTextColor(data?.loyalty?.text_color);
                    setIsCashbackReminder(data?.loyalty?.is_cashback_reminder);
                    setCashbackReminderIn(data?.loyalty?.cashback_reminder_in);
                    setCashBackReminderInType(data?.loyalty?.cashback_reminder_type);
                    setIsRemindOnlyEligibleCustomer(data?.loyalty?.reminder_for_eligible_only);
                    setIsCashbackExpiryReminder(data?.loyalty?.cashback_expiry_reminder);
                    setCashbackExpiryReminderIn(data?.loyalty?.cashback_exoiry_reminder_in);
                    setCashBackExpiryReminderInType(data?.loyalty?.cashback_expiry_reminder_type);
                    setLoyaltyComunicationTypes(JSON.parse(data?.loyalty?.is_loyalty_comunication_reminder_type));
                    setSendIfFailsWhatsapp(data?.loyalty?.add_via_sms_if_fails);
                    setAddWhatsappCustomText(data?.loyalty?.add_custom_text === '0' ? false : true);
                    setIsMultipleOrderAlerts(data?.loyalty?.is_multiple_order_alerts);
                    setMultipleOrderAlertIn(JSON.parse(data?.loyalty?.alert_when));
                    setMultipleOrderAlertOneDay(data?.loyalty?.multiple_orders_in_one_day);
                    setMultipleOrderAlertSevenDay(data?.loyalty?.multiple_order_in_sevenday);
                    setMultipleOrderAlertTypes(JSON.parse(data?.loyalty?.alert_types));
                    setLoyaltyStatus(data?.loyalty?.status);
                }
            }
        } catch (error) {
            console.error('Error during fetching customer data:', error);
        }
    }, [sessionData]);

    useEffect(() => {
        if (sessionData) {
            fetchLoyaltyData();
        }
    }, [fetchLoyaltyData, sessionData]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(pointItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPointItems(items);
    };

    const handleInputChange = (index, field, value) => {
        const newPointItems = [...pointItems];
        newPointItems[index][field] = value;
        setPointItems(newPointItems);
    };

    const addRow = () => {
        setPointItems([...pointItems, { points: '', item: '' }]);
    };

    const removeRow = (index) => {
        const newPointItems = pointItems.filter((_, i) => i !== index);
        setPointItems(newPointItems);
    };

    const handleChannelClick = (channel) => {
        setLoyaltyComunicationTypes(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const handleAlertChannelClick = (channel) => {
        setMultipleOrderAlertTypes(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    const handleMultipleOrdersInClick = (channel) => {
        setMultipleOrderAlertIn(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };

    useEffect(() => {
        setChartData(generateRandomData());
    }, []);
    const thirtydata = useMemo(() => ({
        labels: chartData.map(item => item.name),
        datasets: [
            {
                label: 'Redemptions',
                data: chartData.map(item => item.pv),
                backgroundColor: '#ef6820',
                borderRadius: 20,
            },
            {
                label: 'Revenue gain',
                data: chartData.map(item => item.uv),
                backgroundColor: '#d5dbe2',
                borderRadius: 20,
            }
        ]
    }), [chartData]);
    const thirtyoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${value}`;
                    }
                }
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
    };

    const handleSaveDraft = async () => {
        await insertUpdateLoyalty('draft');
        Modal.destroyAll();
        resetAll();
        setSteps(1);
    };

    const handleLoyaltyStatus = async () => {
        setLoyaltyStatus(loyaltyStatus === 'active' ? 'inactive' : 'active');
        setSendLoader(true);
        try {
            const response = await fetch(`${window.API_BASE_URL}/loyalty/status/${loyaltyId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${sessionData?.token}` },
                body: JSON.stringify({
                    status : loyaltyStatus === 'active' ? 'inactive' : 'active'
                }),
            });
            const result = await response.json();
            if (response.ok) {
                console.log('success');
            } else {
                console.error('Error saving loyalty program:', result);
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            setSendLoader(false);
        }
    };

    const handleConfetti = () => {
        confetti({
            particleCount: 500,
            spread: 100,
            origin: { y: 0.6 },
        });
    };

    const insertUpdateLoyalty = async (status) => {
        const method = loyaltyId ? 'PUT' : 'POST';
        const url = `${window.API_BASE_URL}/loyalty/${loyaltyId || ''}`;
        const body = {
            loyalty_id: loyaltyId,
            store_id: storeDetail?.store_id,
            reward_type: loyaltyType,
            loyalty_title: cardTitle,
            loyalty_subtitle: cardSubTitle,
            cashback_in: cashBackType,
            cashback: cashback,
            is_cashback_branding: isCashBackBranding,
            cashback_branding_name: brandingName,
            is_mimimum_purchase_for_earning: isMinPurchaseForEarning,
            mimimum_purchase_for_earning: minPurchaseForEarning,
            is_min_purchase_for_redeem: isMinPurchaseForRedeeming,
            min_purchase_for_redeem: minPurchaseForRedeeming,
            is_maximum_cashback_earn: isMaxCashbackCanEarn,
            maximum_cashback_earn: maxCashbackCanEarn,
            is_special_cashback: isSpecialCashBackOnProfileComplete,
            special_cashback_coins: specialCashBackOnProfileCompletePoint,
            special_cashback_rupees_per_coin: specialCashBackOnProfileCompleteValue,
            point_name: pointName,
            customer_spent_or_visits: spentamt,
            customer_earn: visitPoint,
            redeem_setting: visits,
            term_conditions: termsText,
            logo: logoName,
            banner: fileName,
            theme_color: themeColor,
            text_color: textColor,
            is_cashback_reminder: isCashbackReminder,
            cashback_reminder_in: cashbackReminderIn,
            cashback_reminder_type: cashbackReminderInType,
            reminder_for_eligible_only: isRemindOnlyEligibleCustomer,
            cashback_expiry_reminder: isCashbackExpiryReminder,
            cashback_exoiry_reminder_in: cashbackExpiryReminderIn,
            cashback_expiry_reminder_type: cashbackExpiryReminderInType,
            is_loyalty_comunication_reminder_type: loyaltyComunicationTypes,
            add_via_sms_if_fails: sendIfFailsWhatsapp,
            phone_numbers: storeDetail?.store_number ? storeDetail?.store_number : userData?.user_phone,
            email_ids: storeDetail?.store_email ? storeDetail?.store_email : userData?.user_email,
            add_custom_text: addWhatsappCustomText,
            is_multiple_order_alerts: isMultipleOrderAlerts,
            multiple_orders_in_one_day: multipleOrderAlertOneDay,
            multiple_order_in_sevenday: multipleOrderAlertSevenDay,
            alert_when: multipleOrderAlertIn,
            alert_types: multipleOrderAlertTypes,
            status: status,
        };
        try {
            setStepLoader(true);
            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${sessionData?.token}` },
                body: JSON.stringify(body)
            });
            const result = await response.json();
            if (response.ok) {
                if (loyaltyId === 0) {
                    setLoyaltyId(result.loyalty_id);
                }
                console.log('Loyalty program saved successfully', result);
            } else {
                console.error('Error saving loyalty program:', result);
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            if (status === 'active') {
                setLoyaltyStatus('active');
                handleConfetti();
                message.success('Loyalty communication updated successfully.');
            }
            setStepLoader(false);
        }
    };

    const handleAddTerms = (terms) => {
        if (termsText.includes(terms)) {
            setTermsText(termsText.replace(terms, '').trim());
        } else {
            setTermsText(`${termsText}\n${terms}`.trim());
        }
    };

    const handleSaveExit = async () => {
        confirm({
            className: 'confirm-popup',
            title: 'Do you want to save this QR code as a draft?',
            content: 'Saving the QR code as draft will allow you to edit the QR code later and activate it once you\'re ready.',
            icon: null,
            closable: true,
            centered: true,
            footer() {
                return <Flex gap={4} justify='center' align='center'><Button className='dont-save-btn' danger onClick={dontSave}>Don't Save</Button> <Button type='primary' className='confirm-primary-btn' onClick={handleSaveDraft}>Save as draft & exit</Button></Flex>
            },
            onCancel() {
                console.log('QR code not saved');
            },
            okButtonProps: { className: 'confirm-primary-btn' },
            cancelButtonProps: { danger: true },
        });
    };

    const resetAll = () => {
        setOpenDrawer(false);
        navigate(`/loyalty`);
    };

    const handleActive = async () => {
        confirm({
            className: 'confirm-popup',
            title: 'Make sure you tested the loyalty comunication before activating it.',
            content: 'We recommend you to create a test order and see preview of loyalty communication, so you can make any changes before activating it.',
            icon: null,
            closable: true,
            centered: true,
            footer() {
                return <Flex gap={4} justify='center' align='center'><Button className='confirm-primary-btn' onClick={handleActiveNow}>Continue without testing <PiArrowArcRightDuotone /></Button><Button type='primary' className='confirm-primary-btn' onClick={() => { Modal.destroyAll(); setIsModalOpen(true); }}>Send test <PiArrowArcRightDuotone /></Button></Flex>
            },
            onCancel() {
                console.log('QR code not saved');
            },
            okButtonProps: { className: 'confirm-primary-btn' },
            cancelButtonProps: { danger: true },
        });
    };

    const sendTestNow = async () => {
        setSendLoader(true);
        try {
            const response = await fetch(`${window.API_BASE_URL}/loyalty/send-test`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${sessionData?.token}` },
                body: JSON.stringify({
                    loyalty_id: loyaltyId,
                    email: testEmailId,
                    phone: testPhoneNo,
                    var_one: storeDetail?.store_name,
                    var_two: storeDetail?.store_name,
                    var_three: `${storeDetail?.currency_symbol} ${cashback} as ${brandingName === '' ? 'Cashback' : brandingName}`,
                    var_four: `${cashBackType === 'per' ? `${cashback}%` : `${storeDetail?.currency_symbol} ${cashback}`} ${brandingName === '' ? 'Cashback' : brandingName}`,
                    var_five: storeDetail?.store_name,
                })
            });
            const result = await response.json();
            if (response.ok) {
                message.success('message send for test');
                setIsModalOpen(false);
            } else {
                console.error('Error saving loyalty program:', result);
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            setSendLoader(false);
        }
    };

    const handleActiveNow = async () => {
        await insertUpdateLoyalty('active');
        Modal.destroyAll();
        resetAll();
        setSteps(1);
    };
    const uploadContainerRef = useRef(null);

    const handleDownload = () => {
        const downloadImages = (containerRef) => {
            if (!containerRef.current) {
                return Promise.resolve();
            }

            const images = containerRef.current.getElementsByTagName('img');
            const imageLoadPromises = Array.from(images).map((img) => {
                return new Promise((resolve) => {
                    img.crossOrigin = "anonymous";
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = resolve;
                    }
                });
            });
            return Promise.all([...imageLoadPromises]);
        };

        const generateAndDownloadCanvas = (containerRef) => {
            setLoading(true);
            html2canvas(containerRef.current, { allowTaint: true, useCORS: true, backgroundColor: null })
                .then((canvas) => {
                    const link = document.createElement('a');
                    link.href = canvas.toDataURL('image/png');
                    link.download = `loyalty-poster.png`;
                    link.click();
                })
                .catch(err => {
                    console.error("Error generating canvas:", err);
                }).finally(() => {
                    setLoading(false);
                });
        };
        downloadImages(uploadContainerRef).then(() => {
            generateAndDownloadCanvas(uploadContainerRef);
        }).catch(err => {
            console.error("Image loading error:", err);
        });
    };

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            return false;
        },
        onChange(info) {
            const { status } = info.file;

            if (status !== 'uploading') {
                console.log('Selected File:', info.file);
                const file = info.file.originFileObj || info.file;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result;
                    setFile(base64String);
                };
                reader.onerror = (error) => {
                    console.error('Error converting file to Base64:', error);
                };
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };

    const customRequest = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                // Create a FileReader to read the file as a base64 string
                const formData = new FormData();
                formData.append('header_file', file);
                const response = await fetch(action, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData
                });
                // Parse the response
                const responseData = await response.json();

                setFileName(responseData.fileName);
                // Call onSuccess with the response data and the file
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.error(error);
            }
        };
    };

    const customRequestOne = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                // Create a FileReader to read the file as a base64 string
                const formData = new FormData();
                formData.append('logo_file', file);
                const response = await fetch(action, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                    body: formData
                });
                // Parse the response
                const responseData = await response.json();

                setLogoName(responseData.fileName);
                // Call onSuccess with the response data and the file
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.error(error);
            }
        };
    };

    const beforeUpload = (file) => {
        const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJPGOrPNG) {
            message.error('You can only upload JPG/PNG files!');
            return Upload.LIST_IGNORE;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must be smaller than 5MB!');
            return Upload.LIST_IGNORE;
        }
        return isJPGOrPNG && isLt5M;
    };

    const handleChangeBanner = (info) => {
        if (info.file.status === 'done') {
            const reader = new FileReader();
            reader.onload = () => setBanner(reader.result);
            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    const handleRemoveBanner = () => {
        setBanner(null);
    };

    const dontSave = async () => {
        setOpenDrawer(false);
        Modal.destroyAll();
        setSteps(1);
    };

    const handleBack = async () => {
        switch (steps) {
            case 1:
                setOpenDrawer(false);
                break;
            default:
                setSteps(steps - 1);
                break;
        }
    };

    const handleNextAddNewPopup = async () => {
        console.log('test');
        switch (steps) {
            case 1:
                insertUpdateLoyalty('draft');
                setSteps(steps + 1);
                break;
            case 2:
                insertUpdateLoyalty('draft');
                setSteps(steps + 1);
                break;
            case 3:
                insertUpdateLoyalty('draft');
                setSteps(steps + 1);
                break;
            case 4:
                insertUpdateLoyalty('draft');
                setSteps(steps + 1);
                break;
            case 5:
                insertUpdateLoyalty('draft');
                setSteps(steps + 1);
                break;
            default:
                break;
        }
    };

    return (
        <>
            {stepLoader && (
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            )}
            <Layout className='contentLayout'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                    <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Loyalty Performance</b></Title>
                </div>
                <Row gutter={16}>
                    <Col span={24}>
                        <div className='edit-loyalty-card'>
                            {loyaltyId !== 0 && (
                                <Paragraph style={{ fontWeight: 'bold', fontSize: 14, display: 'flex', alignItems: 'center' }}><Switch checked={loyaltyStatus === 'active'} onChange={handleLoyaltyStatus} loading={sendLoader} disabled={loyaltyStatus === 'draft'} />&nbsp;&nbsp;Action</Paragraph>
                            )}
                            <Title level={3}>{cardTitle !== '' ? cardTitle : storeDetail?.store_name}</Title>
                            <Flex gap={16} align='center'>
                                {loyaltyComunicationTypes.whatsapp && (
                                    <Paragraph style={{ backgroundColor: '#FFFFFF', padding: 10, fontSize: 14, fontWeight: 'bold', borderRadius: 28 }}>WhatsApp </Paragraph>
                                )}
                                {loyaltyComunicationTypes.sms && (
                                    <Paragraph style={{ backgroundColor: '#FFFFFF', padding: 10, fontSize: 14, fontWeight: 'bold', borderRadius: 28 }}>SMS </Paragraph>
                                )}
                                {loyaltyComunicationTypes.email && (
                                    <Paragraph style={{ backgroundColor: '#FFFFFF', padding: 10, fontSize: 14, fontWeight: 'bold', borderRadius: 28 }}>Email </Paragraph>
                                )}
                            </Flex>
                            <Flex gap="middle">
                                <Button style={{ height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }} disabled={loyaltyId === 0} onClick={() => setViewModalOpen(true)}>View Creative</Button>
                                <Button type='primary' style={{ border: 'none', height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }} onClick={() => setOpenDrawer(true)}>Edit Loyalty</Button>
                            </Flex>
                            <div className="editLoyaltyPreview">
                                <div className="mobile-view">
                                    <img alt="phone" className="back-mobile" src={phonevector2} />
                                    <div className='pre-email'>
                                        <div className='loyalty-final-creative-card'>
                                            <div className='preview-card'>
                                                <div className='card-theme-step'>
                                                    <div className='card-header'>
                                                        <img alt="banner_image" title="banner image" className="banner_image" src={dummyImage} />
                                                    </div>
                                                    <div className='card-logo-div'>
                                                        <img width="100px" alt="card-logo" title="Brand Logo" src={dummyImage} className="card-logo-image" />
                                                    </div>
                                                </div>
                                                <div className='card-content' style={{ width: '98%' }}>
                                                    <div className="title-content">
                                                        <p className="sb-typography sb-typography--display-lg sb-typography--bold loyalty-title" style={{ color: 'rgb(255, 255, 255)' }}>{storeDetail?.store_name}  Rewards</p>
                                                        <p className="sb-typography sb-typography--heading-lg sb-typography--regular loyalty-description" style={{ color: 'rgb(255, 255, 255)' }}>Get rewarded on every purchase</p>
                                                    </div>
                                                    <Paragraph className='points-earned-text' style={{ color: 'rgb(255, 255, 255)' }}>₹1 Spent = 1 Point</Paragraph>
                                                    <div className="rewards-div split-reward-design">
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>500</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Get a free Dessert Truffle slice</p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>800</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div><div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Get a free 6"pizza , because who does not love pizza .</p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>1000</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Hurrayyy!100₹ off on entire purchase</p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>2000</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Treat yourself with ₹300 off on entire purchase</p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>2500</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Get a free RED VELVET CAKE. CELEBRATIONS TIME </p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>3000</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>You deserve it! Get ₹500 off on entire purchase</p>
                                                            </div>
                                                        </div>
                                                        <div className="reward-row hide-locks" style={{ padding: '12px 0px' }}>
                                                            <div className="left-side">
                                                                <p className="sb-typography sb-typography--display-lg sb-typography--semibold points" style={{ color: 'rgb(255, 255, 255)' }}>5000</p>
                                                                <p className="sb-typography sb-typography--text-xs sb-typography--regular points-branding" style={{ color: 'rgb(255, 255, 255)' }}>PTS</p>
                                                            </div>
                                                            <div className="right-side">
                                                                <p className="sb-typography sb-typography--text-md sb-typography--regular reward_name" style={{ color: 'rgb(255, 255, 255)' }}>Party time! Get ₹1000 off on entire purchase</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div style={{ display: 'none' }}>
                    <Row gutter={16} style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Title level={3} style={{ margin: 0 }}>Overview</Title>
                            <Paragraph style={{ fontSize: 18, fontWeight: 400, color: '#374040', margin: 0 }}>Here's what's happening in your rewards program till today</Paragraph>
                            <Card hoverable style={{ marginTop: 16 }} bordered={false} className='loyaltyGraphCard'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <Title level={3} style={{ margin: 0 }}>Summary</Title>
                                    <Dropdown
                                        menu={{
                                            items: datePickerItems,
                                        }}
                                        className='summarydroptitle'
                                    ><Button >Last 30 Days 20, Sep 24 - 19, Oct 24 <DownOutlined /></Button>
                                    </Dropdown>
                                </Flex>
                                <Flex gap='middle' align='center'>
                                    <div>
                                        <Title level={2} style={{ marginBottom: 0 }}>₹78213</Title>
                                        <Tooltip title='The total value of the bill which is eligible for Loyalty redemption.'>
                                            <h4 className='revtitle' style={{ margin: 0 }}>Total Revenue Generated</h4>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Title level={2} style={{ marginBottom: 0 }}>107</Title>
                                        <Tooltip title='The total number of times rewards are redeemed in the selected time period.'>
                                            <h4 className='revtitle' style={{ margin: 0 }}>Times Rewards Redeemed</h4>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Title level={2} style={{ marginBottom: 0 }}>0</Title>
                                        <Tooltip title='The number of unique customers who redeemed loyalty rewards during the specified time period.'>
                                            <h4 className='revtitle' style={{ margin: 0 }}>Number of Customers</h4>
                                        </Tooltip>
                                    </div>
                                </Flex>
                                <div style={{ width: '100%', height: 500, marginTop: 16 }}>
                                    <Bar data={thirtydata} options={thirtyoptions} />
                                </div>
                                <Row>
                                    <Col span={24} className='loyalty-row'>
                                        <Row gutter={16}>
                                            <Col span={6}>
                                                <div className='loyalty-grid-box'>
                                                    <Title level={2} style={{ marginTop: 0, marginBottom: 30 }}>22.09%</Title>
                                                    <Tooltip title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <p>The total number of loyalty points converted into sales expressed as percentage. (Total points redeemed by customers/ total points issued to customers )*100</p>
                                                        </div>
                                                    }>
                                                        <h4 className='revtitle' style={{ margin: 0 }}>Redemption Rate</h4>
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className='loyalty-grid-box'>
                                                    <Title level={2} style={{ marginTop: 0, marginBottom: 30 }}>197000</Title>
                                                    <Tooltip title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <p>The total points redeemed by your customers.</p>
                                                        </div>
                                                    }>
                                                        <h4 className='revtitle' style={{ margin: 0 }}>Points Redeemed</h4>
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className='loyalty-grid-box'>
                                                    <Title level={2} style={{ marginTop: 0, marginBottom: 30 }}>891982</Title>
                                                    <Tooltip title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <p>The total points issued to your customers.</p>
                                                        </div>
                                                    }>
                                                        <h4 className='revtitle' style={{ margin: 0 }}>Points Issued</h4>
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className='loyalty-grid-box'>
                                                    <Title level={2} style={{ marginTop: 0, marginBottom: 30 }}>22.09%</Title>
                                                    <Tooltip title={
                                                        <div>
                                                            <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                                            <p>The average amount spent by customers after they redeem a loyalty reward.</p>
                                                        </div>
                                                    }>
                                                        <h4 className='revtitle' style={{ margin: 0 }}>Avg. Revenue/ Redemption</h4>
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={16} className="custom-margin">
                        <Col span={12}>
                            <div className='loyalty-custom-box'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <Tooltip title={
                                        <div>
                                            <p>The customers with the highest Loyalty Rewards redemptions are listed here.</p>
                                        </div>
                                    }>
                                        <h3 className='revtitle' style={{ margin: 0 }}>Top Redeeming Customers</h3>
                                    </Tooltip>
                                    <Link className='custom-link'>Discover More</Link>
                                </Flex>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5}>8XXXXXXXX7</Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>15 times</b></Paragraph>
                                    </Flex>
                                </div>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5}>9XXXXXXXX7</Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>15 times</b></Paragraph>
                                    </Flex>
                                </div>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5}>8XXXXXXXX8</Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>15 times</b></Paragraph>
                                    </Flex>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='loyalty-custom-box'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <Tooltip title={
                                        <div>
                                            <p>The customers with the highest Loyalty Rewards redemptions are listed here.</p>
                                        </div>
                                    }>
                                        <h3 className='revtitle' style={{ margin: 0 }}>Reward Scoreboard</h3>
                                    </Tooltip>
                                    <Link className='custom-link'>Discover More</Link>
                                </Flex>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5} className='font-med'>Hurrayyy!100₹ off on entire purchase<br /><b>Pts</b></Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>43 times</b></Paragraph>
                                    </Flex>
                                </div>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5} className='font-med'>Treat yourself with ₹300 off on entire purchase<br /><b>Pts</b></Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>23 times</b></Paragraph>
                                    </Flex>
                                </div>
                                <div className='loyalty-customers'>
                                    <Flex gap='middle' justify='space-between' align='center'>
                                        <Title level={5} className='font-med'>Hurrayyy!100₹ off on entire purchase<br /><b>Pts</b></Title>
                                        <Paragraph className='redmeed'>⚡Redeemed <b>27 times</b></Paragraph>
                                    </Flex>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className="custom-margin">
                        <Col span={12}>
                            <div className='loyalty-custom-box blue'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <img src={coinSvg} alt='coin-svg' />
                                    <Link className='custom-link blue'>Learn more</Link>
                                </Flex>
                                <Title level={3}><span className='blue'>9.9%</span> customers have enough points to redeem a reward</Title>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='loyalty-custom-box pink'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <img src={clockSvgTwo} alt='clock-svg' />
                                    <Link className='custom-link pink'>Learn more</Link>
                                </Flex>
                                <Title level={3}>On avg customer takes <span className='pink'>74.2</span> days to redeem their first reward</Title>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className="custom-margin">
                        <Col span={24}><Title level={3} style={{ marginTop: 0, marginBottom: 16, marginLeft: 6 }}>Redemption Time</Title></Col>
                        <Col span={12}>
                            <div className='loyalty-custom-box #7234a6'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <img src={clockSvgOne} alt='clock-svg' />
                                    <Link className='custom-link #7234a6'>Learn more</Link>
                                </Flex>
                                <Title level={3}>Best time of your reward redemption is <span className='#7234a6'>Sunday Afternoon, Leading</span> with <span className='#7234a6'>112 redemptions.</span></Title>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='loyalty-custom-box yellow'>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <img src={clockSvgOne} alt='clock-svg' />
                                    <Link className='custom-link yellow'>Learn more</Link>
                                </Flex>
                                <Title level={3}>Unpopular time of your reward redemption is <span className='yellow'>Thursday Night,</span> Only <span className='yellow'>1 redemptions.</span></Title>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className="custom-margin">
                        <Col span={24}>
                            <div style={{ backgroundColor: '#f8fbfb', borderRadius: 16, padding: 16 }}>
                                <Flex gap='middle' justify='space-between' align='center'>
                                    <Title level={3} style={{ margin: 0 }}>Recent loyalty Activity</Title>
                                    <Title level={5} style={{ margin: 0 }} className='viewBtn'>View all</Title>
                                </Flex>
                                <table className='loyaltytable'>
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th>Action</th>
                                            <th>Points</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>9XXXXXXXX0</td>
                                            <td><span className="green"><PiArrowArcRightDuotone /> Earn</span></td>
                                            <td><span className="green">+1537</span></td>
                                            <td>Sep 21, 2024 2:26 PM</td>
                                        </tr>
                                        <tr>
                                            <td>8XXXXXXXX5</td>
                                            <td><span className="green"><PiArrowArcRightDuotone /> Earn</span></td>
                                            <td><span className="green">+574</span></td>
                                            <td>Sep 21, 2024 2:23 PM</td>
                                        </tr>
                                        <tr>
                                            <td>9XXXXXXXX4</td>
                                            <td><span className="green"><PiArrowArcRightDuotone /> Earn</span></td>
                                            <td><span className="green">+439</span></td>
                                            <td>Sep 21, 2024 2:15 PM</td>
                                        </tr>
                                        <tr>
                                            <td>8XXXXXXXX2</td>
                                            <td><span className="green"><PiArrowArcRightDuotone /> Earn</span></td>
                                            <td><span className="green">+40</span></td>
                                            <td>Sep 21, 2024 2:06 PM</td>
                                        </tr>
                                        <tr>
                                            <td>8XXXXXXXX2</td>
                                            <td><span className="yellow"><PiArrowArcLeftDuotone /> Redeem</span></td>
                                            <td><span className="yellow">-800</span></td>
                                            <td>Sep 21, 2024 2:06 PM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Drawer placement='bottom' closable={false} onClose={() => setOpenDrawer(false)} open={openDrawer} className='templateDrawer' height="100vh">
                    <Row style={{ height: '100vh', overflow: 'hidden' }}>
                        <Col span={24} style={{ height: '10vh', overflow: 'hidden' }}>
                            <Flex gap='middle' style={{ padding: '16px 24px 10px 24px' }} justify='space-between' align='center'>
                                <Flex gap='small' justify='center' align='center'>
                                    <Button shape='round' className='close-btn' onClick={handleBack}><PiArrowLeftDuotone /></Button>
                                    <PiStorefrontDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>{storeDetail?.store_name}</Title>
                                </Flex>
                                {steps === 1 ? (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button type='primary' onClick={handleNextAddNewPopup}>Next <PiArrowRightDuotone /></Button>
                                    </Flex>
                                ) : steps === 6 ? (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button onClick={handleSaveExit}><PiFloppyDiskDuotone /> Save & Exit</Button>
                                        <Button type='primary' onClick={handleActive}>Active Now <PiArrowRightDuotone /></Button>
                                    </Flex>
                                ) : (
                                    <Flex gap='small' justify='center' align='center'>
                                        <Button onClick={handleSaveExit} ><PiFloppyDiskDuotone /> Save & Exit</Button>
                                        <Button type='primary' onClick={handleNextAddNewPopup}>Next <PiArrowRightDuotone /></Button>
                                    </Flex>
                                )}
                            </Flex>
                            <Progress showInfo={false} percent={steps * 12.5} strokeColor={twoColors} strokeLinecap="butt" />
                        </Col>
                        {(steps === 1 || steps === 2 || steps === 3 || steps === 4 || steps === 6) ? (
                            <Col span={14}>
                                <div className='add-loyalty-left-popup'>
                                    <div className='add-loyalty-popup-left-div'>
                                        <div className='loyalty-edit-creative-card'>
                                            <div className='preview-card' style={{ borderBottom: `${loyaltyType === 'cashback' ? `4px dashed #FFFFFF` : 'unset'}`, backgroundColor: themeColor }}>
                                                <div className='card-theme-step'>
                                                    <div className='card-header'>
                                                        <Image preview={false} src={banner ? banner : fileName !== '' ? `${window.BASE_URL}uploads/loyalty_media/${fileName}` : dummyImage} />
                                                    </div>
                                                    <div className='card-logo-div'>
                                                        {storeDetail?.store_logo || file ? (
                                                            <img alt='card-logo' className='card-logo-image' src={file ? file : logoName !== '' ? `${window.BASE_URL}uploads/loyalty_media/${logoName}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                                        ) : (
                                                            <Paragraph className='card-logo-name'>{storeDetail?.store_name}</Paragraph>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='card-content'>
                                                    <div className='title-content'>
                                                        <Title level={3} style={{ color: textColor, fontSize: 27, fontWeight: 'bold', margin: 0 }}>{cardTitle === '' ? 'Write title' : cardTitle}</Title>
                                                        <Paragraph style={{ color: textColor, fontSize: 18, margin: 0 }}>{cardSubTitle === '' ? 'Write subtitle' : cardSubTitle}</Paragraph>
                                                    </div>
                                                    {loyaltyType === 'cashback' ? (
                                                        <div className='cashback-content'>
                                                            <Paragraph style={{ color: textColor, fontSize: 12, margin: 0 }}>Earn</Paragraph>
                                                            <Title level={2} style={{ color: textColor, fontWeight: 'bold', margin: 0 }}>{cashBackType === 'per' ? `${cashback}%` : `${storeDetail?.currency_symbol} ${cashback}`}</Title>
                                                            <Paragraph style={{ color: textColor, fontSize: 16, margin: 0 }}>{brandingName === '' ? 'Cashback' : brandingName} {isMinPurchaseForEarning ? `On min purchase of ${storeDetail?.currency_symbol} ${minPurchaseForEarning}` : 'on every purchase'}</Paragraph>
                                                        </div>
                                                    ) : loyaltyType === 'onspent' ? (
                                                        <div className='cashback-content'>
                                                            <Paragraph style={{ color: textColor, fontSize: 22, margin: 0, fontWeight: 'bold' }}>{storeDetail?.currency_symbol} {spentamt} = {`${spentamtPoint} ${pointName} ${spentamtPoint === 1 ? 'Point' : 'Points'}`} </Paragraph>
                                                        </div>
                                                    ) : loyaltyType === 'onvisit' && (
                                                        <div className='cashback-content'>
                                                            <Paragraph style={{ color: textColor, fontSize: 22, margin: 0, fontWeight: 'bold' }}>{`${visits} ${visits === 1 ? 'Visit' : 'Visits'}`} = {`${visitPoint} ${pointName} ${visitPoint === 1 ? 'Point' : 'Points'}`}</Paragraph>
                                                        </div>
                                                    )}
                                                    <div className='spent-visit-items'>
                                                        {pointItems.map((pointItem, index) => (
                                                            (pointItem?.points !== '' || pointItem?.item !== '') && (
                                                                <div className='spent-visit-item' style={{ borderColor: textColor }}>
                                                                    <Flex gap={16} justify='space-between' align='center'>
                                                                        <div><Paragraph style={{ color: textColor, margin: 0 }}><b>{pointItem?.points}</b></Paragraph><Paragraph style={{ color: textColor, margin: 0 }}>{pointName} {pointItem?.points === '1' ? 'Point' : 'Points'}</Paragraph></div>
                                                                        <div><Paragraph style={{ color: textColor, margin: 0 }}>{pointItem?.item}</Paragraph></div>
                                                                    </Flex>
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>
                                                    {loyaltyType !== 'cashback' && (
                                                        <div>
                                                            <div className='preview-footer' style={{ border: `1px dashed ${textColor}`, cursor: 'pointer', position: 'relative', borderRadius: 8, padding: 16, whiteSpace: 'pre-line', color: textColor }}>
                                                                <Paragraph style={{ color: textColor }}><b>Terms & Conditions</b></Paragraph>
                                                                {termsText}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {loyaltyType === 'cashback' && (
                                                <div className='cashback-terms'>
                                                    <Paragraph style={{ textAlign: 'center' }}>Use your {isCashBackBranding ? brandingName === '' ? 'cashback' : brandingName : 'cashback'} to pay your next bill.</Paragraph>
                                                    <div className='preview-footer' style={{ whiteSpace: 'pre-line' }}>
                                                        <Paragraph><b>Terms & Conditions</b></Paragraph>
                                                        {isMaxCashbackCanEarn && `*Earn upto ${storeDetail?.currency_symbol} ${maxCashbackCanEarn} on every purchase.\n`}
                                                        {isMinPurchaseForRedeeming && `*Minimum purchase of ${storeDetail?.currency_symbol} ${minPurchaseForRedeeming} required to redeem.\n`}
                                                        {termsText}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ) : steps === 5 && (
                            <Col span={14}>
                                <div className='add-loyalty-left-popup'>
                                    <div className='add-loyalty-popup-left-div' style={{ overflow: 'hidden' }}>
                                        <Flex justify='center' gap={16} align='center'>
                                            <Button shape='round' type={reminderpreviewType === 'whatsapp' ? 'primary' : 'default'} onClick={() => setReminderpreviewType('whatsapp')}>WhatsApp</Button>
                                            <Button shape='round' type={reminderpreviewType === 'sms' ? 'primary' : 'default'} onClick={() => setReminderpreviewType('sms')}>SMS</Button>
                                            <Button shape='round' type={reminderpreviewType === 'email' ? 'primary' : 'default'} onClick={() => setReminderpreviewType('email')}>Email</Button>
                                        </Flex>
                                        <Flex justify='center' gap={16} align='center' style={{ marginTop: 16 }}>
                                            <Dropdown menu={{ items: messageViewItems, onClick: (e) => setReminderpreviewMessage(e.key) }} >
                                                <Button type='link' style={{ color: '#3A1A55' }} onClick={(e) => e.preventDefault()} alt='dropdownmens'>
                                                    <Space>{messageViewItems.find((item) => item.key === reminderpreviewMessage)?.label} <DownOutlined /></Space>
                                                </Button>
                                            </Dropdown>
                                        </Flex>
                                        <div className='preview-content'>
                                            <div className='parent-container'>
                                                {reminderpreviewType === 'whatsapp' ? (
                                                    <div className='mobile-view'>
                                                        <Image src={whatsappMobileMokup} className='back-mobile' preview={false} />
                                                        {reminderpreviewMessage === 'welcome' ? (
                                                            <div className='preview-whatsapp-card'>
                                                                <div className='whatsapp'>
                                                                    <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}Welcome to {storeDetail?.store_name} Rewards Program 😀{"\n\n"}You've earned <b>{storeDetail?.currency_symbol} 10 as cashback</b> on your purchase 🥳{"\n\n"}Now earn  <b>10% Cashback</b> on every purchase!{"\n\n"}See you soon!✨</Paragraph>
                                                                    <Paragraph className='text'>{addWhatsappCustomText && whatsappCustomText !== '' ? whatsappCustomText : storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_earned' ? (
                                                            <div className='preview-whatsapp-card'>
                                                                <div className='whatsapp'>
                                                                    <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}Thank you for your purchase. You've earned <b>{storeDetail?.currency_symbol} 10 as cashback</b> on your purchase 🥳{"\n\n"}Current balance:  <b>{storeDetail?.currency_symbol} 1000</b> to spend 💪{"\n\n"}See you soon!✨</Paragraph>
                                                                    <Paragraph className='text'>{addWhatsappCustomText && whatsappCustomText !== '' ? whatsappCustomText : storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_redemption' ? (
                                                            <div className='preview-whatsapp-card'>
                                                                <div className='whatsapp'>
                                                                    <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}Awesome news! 🎉 You've successfully redeemed <b>{storeDetail?.currency_symbol} 10</b> as cashback. 🥳 {"\n\n"}Your updated balance is now <b>{storeDetail?.currency_symbol} 1000</b>. 💪{"\n\n"}See you soon!✨</Paragraph>
                                                                    <Paragraph className='text'>{addWhatsappCustomText && whatsappCustomText !== '' ? whatsappCustomText : storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_reminder' ? (
                                                            <div className='preview-whatsapp-card'>
                                                                <div className='whatsapp'>
                                                                    <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}You've got <b>{storeDetail?.currency_symbol} 100</b> just waiting to be redeemed. 🎁💫{"\n\n"}Simply share your number at {storeDetail?.store_name} during your next visit to redeem. 🎉{"\n\n"}See you soon!✨</Paragraph>
                                                                    <Paragraph className='text'>{addWhatsappCustomText && whatsappCustomText !== '' ? whatsappCustomText : storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_expiry' && (
                                                            <div className='preview-whatsapp-card'>
                                                                <div className='whatsapp'>
                                                                    <Paragraph className='text'><b>{storeDetail?.store_name}</b>{"\n\n"}Dear customer,{"\n\n"}⚠️Your <b>{storeDetail?.currency_symbol} 100</b> are about to <b>expire on 1/1/25.</b>⏰{"\n\n"}Simply share your number at {storeDetail?.store_name} during your next visit to redeem. 🎉{"\n\n"}See you soon!✨</Paragraph>
                                                                    <Paragraph className='text'>{addWhatsappCustomText && whatsappCustomText !== '' ? whatsappCustomText : storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : reminderpreviewType === 'sms' ? (
                                                    <div className='mobile-view'>
                                                        <Image src={smsMobileMokup} className='back-mobile' preview={false} />
                                                        {reminderpreviewMessage === 'welcome' ? (
                                                            <div className="preview-sms-card">
                                                                <div className="sms">
                                                                    <Paragraph className="text">Welcome to {storeDetail?.store_name} Rewards. Earn 20% Cashback on every bill. You earned Rs.100 as cashback.</Paragraph>
                                                                    <Paragraph className="foot">via Zipler.io</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_earned' ? (
                                                            <div className="preview-sms-card">
                                                                <div className="sms">
                                                                    <Paragraph className="text">{storeDetail?.store_name}: Yay! You earned Rs.100 as cashback. Your bal: Rs.150.</Paragraph>
                                                                    <Paragraph className="foot">via Zipler.io</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_redemption' ? (
                                                            <div className="preview-sms-card">
                                                                <div className="sms">
                                                                    <Paragraph className="text">{storeDetail?.store_name}: Congrats! You redeemed Rs.150 on your bill. Your Bal: Rs.2500.</Paragraph>
                                                                    <Paragraph className="foot">via Zipler.io</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_reminder' ? (
                                                            <div className="preview-sms-card">
                                                                <div className="sms">
                                                                    <Paragraph className="text">{storeDetail?.store_name}: Hey, you've Rs.150 cashback to spend on your next purchase! Visit us before it expires.</Paragraph>
                                                                    <Paragraph className="foot">via Zipler.io</Paragraph>
                                                                </div>
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_expiry' && (
                                                            <div className="preview-sms-card">
                                                                <div className="sms">
                                                                    <Paragraph className="text">Alert! Don't let your Rs.150 cashback at {storeDetail?.store_name}. EXPIRE on 26 JAN 25. Visit us to renew them.</Paragraph>
                                                                    <Paragraph className="foot">via Zipler.io</Paragraph>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : reminderpreviewType === 'email' && (
                                                    <div className='mobile-view'>
                                                        <Image src={emailMobileMokup} className='back-mobile' preview={false} />
                                                        {reminderpreviewMessage === 'welcome' ? (
                                                            <div className="loyalty-email-card">
                                                                <div className="logo">
                                                                    <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                                <Image preview={false} src={emailWelcomeImage} class="mid-image" alt="mid_image" />
                                                                <h1>Welcome to<br />{storeDetail?.store_name}<br /><b>You've earned 250 cashback!</b></h1>
                                                                <h3>Nice work! Now earn cashback everytime you make a purchase and redeem them on next purchase.</h3>
                                                                <Button>See rewards <PiArrowRight size={24} /> </Button>
                                                                <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                                <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                                <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_earned' ? (
                                                            <div className="loyalty-email-card">
                                                                <div className="logo">
                                                                    <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                                <Image preview={false} src={emailCashbackEarnImage} class="mid-image" alt="mid_image" />
                                                                <h1>Yay! You've earned <br /><b>250 cashback</b> at <b>{storeDetail?.store_name}!</b></h1>
                                                                <h3>Good to see you again! Your cashback balance is 600. Redeem your cash for exciting rewards.</h3>
                                                                <Button>See rewards <PiArrowRight size={24} /> </Button>
                                                                <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                                <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                                <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_redemption' ? (
                                                            <div className="loyalty-email-card">
                                                                <div className="logo">
                                                                    <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                                <Image preview={false} src={emailCashbackReduprionImage} class="mid-image" alt="mid_image" />
                                                                <h1>You've redeemed<br />a reward at <b>{storeDetail?.store_name}!</b></h1>
                                                                <h3>Congratulations! {storeDetail?.currency_symbol} 150 on your bill at {storeDetail?.store_name}. Your cashback bal is 250.</h3>
                                                                <Button>See rewards <PiArrowRight size={24} /> </Button>
                                                                <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                                <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                                <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_reminder' ? (
                                                            <div className="loyalty-email-card">
                                                                <div className="logo">
                                                                    <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                                <Image preview={false} src={emailCashbackReminderImage} class="mid-image" alt="mid_image" />
                                                                <h1>Knock knock! You've 600 cashback <br />unused at <b>{storeDetail?.store_name}!</b></h1>
                                                                <h3>Just a friendly reminder to redeem your cashback before they expire.</h3>
                                                                <Button>See rewards <PiArrowRight size={24} /> </Button>
                                                                <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                                <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                                <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                            </div>
                                                        ) : reminderpreviewMessage === 'cashback_expiry' && (
                                                            <div className="loyalty-email-card">
                                                                <div className="logo">
                                                                    <Paragraph className="logo-name">{storeDetail?.store_name}</Paragraph>
                                                                </div>
                                                                <Image preview={false} src={emailCashbackExpiriengImage} class="mid-image" alt="mid_image" />
                                                                <h1>Hurry! Your cashback at <br /><b>{storeDetail?.store_name}</b> are expiring soon!</h1>
                                                                <h3>Earn cashback everytime you make a purchase and redeem them on your next purchase.</h3>
                                                                <Button>See rewards <PiArrowRight size={24} /> </Button>
                                                                <p class="sentBy">Sent by <a href="https://zipler.io" target="_blank" rel="noreferrer">zipler.io</a> on behalf of <span>{storeDetail?.store_name}</span></p>
                                                                <p class="unsub">To unsubscribe from these rewarding emails, you may opt-out<span>here</span></p>
                                                                <Image src={blogo} width="58px" alt="zipler.io" style={{ margin: '20px auto', display: 'block' }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )}
                        {steps === 1 ? (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Title</Title>
                                                <Input value={cardTitle} onChange={(e) => setCardTitle(e.target.value)} maxLength={120} showCount placeholder='Eg. Coffee shop rewards' />
                                                <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Subtitle</Title>
                                                <Input value={cardSubTitle} onChange={(e) => setCardSubTitle(e.target.value)} maxLength={120} showCount placeholder='Eg. Get reward on every purchase' />
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Title level={5} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Reward type</Title>
                                                <Select value={loyaltyType} style={{ width: '100%' }} onChange={(value) => setLoyaltyType(value)}>
                                                    <Option key='cashback' value='cashback'>Cashback</Option>
                                                    <Option key='onspent' value='onspent'>On Purchase</Option>
                                                    <Option key='onvisit' value='onvisit'>On Visits</Option>
                                                </Select>
                                                {loyaltyType === 'cashback' ? (
                                                    <>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiCoinsDuotone size={24} />Cashback in</Title>
                                                        <Select value={cashBackType} style={{ width: '100%' }} onChange={(value) => setCashBackType(value)}>
                                                            <Option key='per' value='per'>Percentage</Option>
                                                            <Option key='cash' value='cash'>Cash</Option>
                                                        </Select>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiCoinsDuotone size={24} />Customer earns</Title>
                                                        <Input type='number' min={0} max={100} value={cashback} onChange={(e) => { e.target.value <= 100 && setCashback(e.target.value) }} placeholder={`Eg. ${cashBackType === 'per' ? `10%` : `${storeDetail?.currency_symbol} 10`} Cashback`} />
                                                    </>
                                                ) : loyaltyType === 'onspent' ? (
                                                    <>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Point Name</Title>
                                                        <Input value={pointName} onChange={(e) => setPointName(e.target.value)} placeholder={`Eg. Zipler Points `} />
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiCoinsDuotone size={24} />Customer Spent</Title>
                                                        <Input type='number' value={spentamt} onChange={(e) => setSpentAmt(e.target.value)} placeholder={`Eg. Spent ${storeDetail?.currency_symbol} 1 `} />
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiEqualsDuotone size={24} />Customer earns Points</Title>
                                                        <Input type='number' value={spentamtPoint} onChange={(e) => setSpentamtPoint(e.target.value)} placeholder={`Eg. 1 Point`} />
                                                    </>
                                                ) : loyaltyType === 'onvisit' && (
                                                    <>
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiTextTDuotone size={24} />Point Name</Title>
                                                        <Input value={pointName} onChange={(e) => setPointName(e.target.value)} placeholder={`Eg. Zipler Points `} />
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiCoinsDuotone size={24} />Customer Visits</Title>
                                                        <Input type='number' value={visits} onChange={(e) => setVisits(e.target.value)} placeholder={`Eg. 1 Visit `} />
                                                        <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiEqualsDuotone size={24} />Customer earns Points</Title>
                                                        <Input type='number' value={visitPoint} onChange={(e) => setVisitPoint(e.target.value)} placeholder={`Eg. 1 Point`} />
                                                    </>
                                                )}
                                            </Card>
                                            {loyaltyType === 'cashback' ? (
                                                <>
                                                    <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>Cashback Settings</Title>
                                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                        <div style={{ marginBottom: 16 }}>
                                                            <Flex gap={16} justify='space-between' align='center'>
                                                                <Title level={5} style={{ margin: 0 }}>Cashback Branding</Title>
                                                                <Switch checked={isCashBackBranding} onChange={() => setIsCashBackBranding(!isCashBackBranding)} />
                                                            </Flex>
                                                            {isCashBackBranding && (
                                                                <>
                                                                    <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}><PiHandCoinsDuotone size={24} /> Add branding name</Title>
                                                                    <Input value={brandingName} onChange={(e) => setBrandingName(e.target.value)} placeholder={`Eg. Points`} maxLength={25} showCount />
                                                                    <Flex gap={8} style={{ paddingTop: 16, paddingBottom: 16 }}>
                                                                        <Flex justify='center' align='center' style={{ backgroundColor: '#ddd', borderRadius: 8, padding: 8, cursor: 'pointer' }} onClick={() => setBrandingName('Chilly')}>🌶️ Chilly</Flex>
                                                                        <Flex justify='center' align='center' style={{ backgroundColor: '#ddd', borderRadius: 8, padding: 8, cursor: 'pointer' }} onClick={() => setBrandingName('Cookie')}>🍪 Cookie</Flex>
                                                                        <Flex justify='center' align='center' style={{ backgroundColor: '#ddd', borderRadius: 8, padding: 8, cursor: 'pointer' }} onClick={() => setBrandingName('Star')}>⭐ Star</Flex>
                                                                        <Flex justify='center' align='center' style={{ backgroundColor: '#ddd', borderRadius: 8, padding: 8, cursor: 'pointer' }} onClick={() => setBrandingName('Coin')}>🪙 Coin</Flex>
                                                                    </Flex>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div style={{ marginBottom: 16 }}>
                                                            <Flex gap={16} justify='space-between' align='center'>
                                                                <Tooltip title='Set a minimum amount that your customers must spend to get cashback.'>
                                                                    <Title level={5} style={{ margin: 0 }}>Minimum purchase for earning cashback</Title>
                                                                </Tooltip>
                                                                <Switch checked={isMinPurchaseForEarning} onChange={() => setIsMinPurchaseForEarning(!isMinPurchaseForEarning)} />
                                                            </Flex>
                                                            {isMinPurchaseForEarning && (
                                                                <Input style={{ marginTop: 16 }} type='number' value={minPurchaseForEarning} onChange={(e) => setMinPurchaseForEarning(e.target.value)} placeholder='0' />
                                                            )}
                                                        </div>
                                                        <div style={{ marginBottom: 16 }}>
                                                            <Flex gap={16} justify='space-between' align='center'>
                                                                <Tooltip title='Set a minimum amount that your customers must spend to be able to redeem their cashback.'>
                                                                    <Title level={5} style={{ margin: 0 }}>Minimum purchase for redeeming cashback</Title>
                                                                </Tooltip>
                                                                <Switch checked={isMinPurchaseForRedeeming} onChange={() => setIsMinPurchaseForRedmming(!isMinPurchaseForRedeeming)} />
                                                            </Flex>
                                                            {isMinPurchaseForRedeeming && (
                                                                <Input style={{ marginTop: 16 }} type='number' value={minPurchaseForRedeeming} onChange={(e) => setMinPurchaseForRedmming(e.target.value)} placeholder='0' />
                                                            )}
                                                        </div>
                                                        <div style={{ marginBottom: 16 }}>
                                                            <Flex gap={16} justify='space-between' align='center'>
                                                                <Tooltip title='This is usually set if you have party orders and want to cap maximum cashback one can earn on a single purchase'>
                                                                    <Title level={5} style={{ margin: 0 }}>Maximum cashback a customer can earn</Title>
                                                                </Tooltip>
                                                                <Switch checked={isMaxCashbackCanEarn} onChange={() => setIsMaxCashbackCanEarn(!isMaxCashbackCanEarn)} />
                                                            </Flex>
                                                            {isMaxCashbackCanEarn && (
                                                                <>
                                                                    <Input style={{ marginTop: 16 }} type='number' value={maxCashbackCanEarn} onChange={(e) => setMaxCashbackCanEarn(e.target.value)} placeholder='0' />
                                                                    <div className='info-div' style={{ backgroundColor: '#e9e2f8', marginTop: 16, padding: 8, borderRadius: 8 }}>
                                                                        <Flex align='center' gap={8} justify='space-between'><PiLightbulbDuotone size={24} color='#7234a6' /> <Paragraph style={{ margin: 0 }}>A customer will earn maximum {storeDetail?.currency_symbol} {maxCashbackCanEarn === '' ? 0 : maxCashbackCanEarn} on a single purchase even if their spend is higher.</Paragraph></Flex>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div style={{ marginBottom: 16 }}>
                                                            <Flex gap={16} justify='space-between' align='center'>
                                                                <Tooltip title='Customers can receive special cashbacks for providing extra details like their email, birthday, anniversary, gender, and more. This information can enhance your engagement with them.'>
                                                                    <Title level={5} style={{ margin: 0 }}>Special cashback</Title>
                                                                </Tooltip>
                                                                <Switch checked={isSpecialCashBackOnProfileComplete} onChange={() => setIsSpecialCashBackOnProfileComplete(!isSpecialCashBackOnProfileComplete)} />
                                                            </Flex>
                                                            <Paragraph style={{ margin: 0, color: '#697777', fontSize: 16 }}>Motivate customers to share their <span style={{ color: '#000000' }}>birthday, email, gender, and anniversary,</span> and receive special cashback.</Paragraph>
                                                            {isSpecialCashBackOnProfileComplete && (
                                                                <>
                                                                    <Flex gap={16}>
                                                                        <Input style={{ marginTop: 16 }} type='number' value={specialCashBackOnProfileCompletePoint} onChange={(e) => setSpecialCashBackOnProfileCompletePoint(e.target.value)} placeholder='0' />
                                                                        <Input style={{ marginTop: 16 }} type='number' value={specialCashBackOnProfileCompleteValue} onChange={(e) => setSpecialCashBackOnProfileCompleteValue(e.target.value)} placeholder='0' />
                                                                    </Flex>
                                                                    <div className='info-div' style={{ backgroundColor: '#e9e2f8', marginTop: 16, padding: 8, borderRadius: 8 }}>
                                                                        <Title level={3}>{specialCashBackOnProfileCompletePoint} <PiCoinsDuotone /> = {storeDetail?.currency_symbol} {specialCashBackOnProfileCompleteValue}</Title>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Card>
                                                </>
                                            ) : (loyaltyType === 'onspent' || loyaltyType === 'onvisit') && (
                                                <>
                                                    <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>Redeem Settings</Title>
                                                    <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                                            <Droppable droppableId="pointItems">
                                                                {(provided) => (
                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                        {pointItems.map((pointItem, index) => (
                                                                            <Draggable key={index} draggableId={String(index)} index={index}>
                                                                                {(provided) => (
                                                                                    <Row
                                                                                        gutter={16}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        key={index}
                                                                                    >
                                                                                        <Col span={8}>
                                                                                            <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>
                                                                                                <PiHandCoinsDuotone size={24} /> Use Points
                                                                                            </Title>
                                                                                            <Input
                                                                                                type='number'
                                                                                                value={pointItem.points}
                                                                                                onChange={(e) => handleInputChange(index, 'points', e.target.value)}
                                                                                                placeholder={`Eg. 100 Points`}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col span={12}>
                                                                                            <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>
                                                                                                <PiCoinsDuotone size={24} /> Get Item
                                                                                            </Title>
                                                                                            <Input
                                                                                                value={pointItem.item}
                                                                                                onChange={(e) => handleInputChange(index, 'item', e.target.value)}
                                                                                                placeholder={`Eg. 1 Burger / Pizza`}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col span={4}>
                                                                                            <Title level={5} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>
                                                                                                &nbsp;
                                                                                            </Title>
                                                                                            <Button
                                                                                                style={{ width: '100%' }}
                                                                                                danger
                                                                                                onClick={() => removeRow(index)}
                                                                                                disabled={pointItems.length === 1}
                                                                                            >
                                                                                                <PiTrashDuotone />
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                        <Button type="dashed" onClick={addRow} style={{ marginTop: 16 }}>
                                                            Add More
                                                        </Button>
                                                    </Card>
                                                </>
                                            )}
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        ) : steps === 2 ? (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}>Terms & Conditions</Title>
                                            <Card bordered={false} className='left-div'>
                                                <TextArea style={{ marginTop: 16 }} value={termsText} onChange={(e) => setTermsText(e.target.value)} rows={4} placeholder='Enter terms & conditions' />
                                                <div className={`template-channle-checkbox-div ${termsText.includes('Minimum purchase required.') && 'active'}`} onClick={() => handleAddTerms('*Minimum purchase required.')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Minimum purchase required.</b></Typography>
                                                        <Checkbox checked={termsText.includes('Minimum purchase required.')} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${termsText.includes('2 offers cannot be clubbed.') && 'active'}`} onClick={() => handleAddTerms('*2 offers cannot be clubbed.')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>2 offers cannot be clubbed.</b></Typography>
                                                        <Checkbox checked={termsText.includes('2 offers cannot be clubbed.')} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${termsText.includes('Not redeemable on public holidays.') && 'active'}`} onClick={() => handleAddTerms('*Not redeemable on public holidays.')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Not redeemable on public holidays.</b></Typography>
                                                        <Checkbox checked={termsText.includes('Not redeemable on public holidays.')} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${termsText.includes('Reward redeemable in-store only.') && 'active'}`} onClick={() => handleAddTerms('*Reward redeemable in-store only.')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Reward redeemable in-store only.</b></Typography>
                                                        <Checkbox checked={termsText.includes('Reward redeemable in-store only.')} />
                                                    </Flex>
                                                </div>
                                                <div className={`template-channle-checkbox-div ${termsText.includes('Merchant reserves the right to final say.') && 'active'}`} onClick={() => handleAddTerms('*Merchant reserves the right to final say.')}>
                                                    <Flex justify='space-between' align='center'><Typography><b>Merchant reserves the right to final say.</b></Typography>
                                                        <Checkbox checked={termsText.includes('Merchant reserves the right to final say.')} />
                                                    </Flex>
                                                </div>
                                            </Card>
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        ) : steps === 3 ? (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>Theme Settings</Title>
                                            <Card bordered={false} className='left-div'>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Logo</Title>
                                                <ImgCrop shape="round" aspect={1} quality={1}>
                                                    <Upload {...props} maxCount={1} style={{ width: '100%' }} className='qr-upload-logo' accept="image/jpeg, image/png" customRequest={(fileInfo) => customRequestOne()(fileInfo)} action={`${window.API_BASE_URL}/loyalty/upload-logo`} beforeUpload={beforeUpload}>
                                                        {file ? (
                                                            <Button style={{ width: '100%' }}>Upload another file</Button>
                                                        ) : (
                                                            <Button style={{ width: '100%' }} icon={<UploadOutlined />}>Upload your logo</Button>
                                                        )}

                                                    </Upload>
                                                </ImgCrop>
                                                <Paragraph style={{ color: '#697777', fontSize: 12, marginTop: 8 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                            </Card>
                                            <Flex gap={16} style={{ marginTop: 24 }}>
                                                <div className='add-new-qr-input-div'>
                                                    <Flex gap={16} align='center'>
                                                        <ColorPicker value={themeColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setThemeColor(hexColor); }} />
                                                        <Title level={4} style={{ margin: 0 }}>Theme Color</Title>
                                                    </Flex>
                                                </div>
                                                <div className='add-new-qr-input-div'>
                                                    <Flex gap={16} align='center'>
                                                        <ColorPicker value={textColor} onChange={(color) => { const hexColor = rgbToHex(color.metaColor.r, color.metaColor.g, color.metaColor.b); setTextColor(hexColor); }} />
                                                        <Title level={4} style={{ margin: 0 }}>Text Color</Title>
                                                    </Flex>
                                                </div>
                                            </Flex>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 24 }}>
                                                <Title level={4} style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: 4 }}><PiImageDuotone size={24} />Banner Image</Title>
                                                <Upload.Dragger
                                                    name="banner"
                                                    listType="picture"
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleChangeBanner}
                                                    maxCount={1}
                                                    accept="image/jpeg, image/png"
                                                    customRequest={(fileInfo) => customRequest()(fileInfo)}
                                                    action={`${window.API_BASE_URL}/loyalty/upload-banner`}
                                                    onRemove={handleRemoveBanner}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-hint">Support for a single upload only.</p>
                                                </Upload.Dragger>
                                                <Paragraph style={{ color: '#697777', fontSize: 12, marginTop: 8 }}>Recommended size 512 x 512 px in JPG, GIF or PNG format up-to max size of 10MB</Paragraph>
                                            </Card>
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        ) : steps === 4 ? (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>Set Reminder & Expiry</Title>
                                            <Card bordered={false} className='left-div'>
                                                <div style={{ marginBottom: 16 }}>
                                                    <Flex gap={16} justify='space-between' align='center'>
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiBellRingingDuotone /> Cashbacks Reminder</Title>
                                                        <Switch checked={isCashbackReminder} onChange={() => setIsCashbackReminder(!isCashbackReminder)} />
                                                    </Flex>
                                                    <Paragraph style={{ marginBottom: 16, color: '#374040' }}>Remind customers about their available points balance when they have not made a purchase in.</Paragraph>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={8}>
                                                            <Select style={{ width: '100%' }} value={cashbackReminderIn} onChange={(value) => setCashbackReminderIn(value)} disabled={!isCashbackReminder}>
                                                                {days.map(day => (
                                                                    <Option key={day} value={day}>
                                                                        {day}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col span={16}>
                                                            <Select style={{ width: '100%' }} value={cashbackReminderInType} onChange={(value) => setCashBackReminderInType(value)} disabled={!isCashbackReminder}>
                                                                <Option key='days' value='days'>Days</Option>
                                                                <Option key='weeks' value='weeks'>Weeks</Option>
                                                                <Option key='months' value='months'>Months</Option>
                                                            </Select>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Flex justify='start' align='center' gap={8}>
                                                                <Checkbox checked={isRemindOnlyEligibleCustomer === 'yes'} onChange={() => setIsRemindOnlyEligibleCustomer(isRemindOnlyEligibleCustomer === 'yes' ? 'no' : 'yes')} disabled={!isCashbackReminder} />
                                                                <Paragraph style={{ marginBottom: 16, color: '#000000', fontSize: 16 }}>Only remind those customers who are eligible to redeem a cashback</Paragraph>
                                                            </Flex>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <div style={{ marginBottom: 16 }}>
                                                    <Flex gap={16} justify='space-between' align='center'>
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiTrashDuotone /> Cashbacks Expiry</Title>
                                                        <Switch checked={isCashbackExpiryReminder} onChange={() => setIsCashbackExpiryReminder(!isCashbackExpiryReminder)} />
                                                    </Flex>
                                                    <Paragraph style={{ marginBottom: 16, color: '#374040' }}>Set when customers points expire. This is an effective way to re-engage customers.</Paragraph>
                                                    <Row gutter={16}>
                                                        <Col span={8}>
                                                            <Select style={{ width: '100%' }} value={cashbackExpiryReminderIn} onChange={(value) => setCashbackExpiryReminderIn(value)} disabled={!isCashbackExpiryReminder}>
                                                                {days.map(day => (
                                                                    <Option key={day} value={day}>
                                                                        {day}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col span={16}>
                                                            <Select style={{ width: '100%' }} value={cashbackExpiryReminderInType} onChange={(value) => setCashBackExpiryReminderInType(value)} disabled={!isCashbackExpiryReminder}>
                                                                <Option key='days' value='days'>Days</Option>
                                                                <Option key='weeks' value='weeks'>Weeks</Option>
                                                                <Option key='months' value='months'>Months</Option>
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        ) : steps === 5 ? (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>How do your customers receive loyalty<br />communications?</Title>
                                            <Card bordered={false} className='left-div'>
                                                <div style={{ marginBottom: 16 }}>
                                                    <Flex gap={16} justify='space-between' align='center' onClick={() => handleChannelClick('whatsapp')} style={{ cursor: 'pointer' }}>
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiWhatsappLogoDuotone /> Whatsapp Utility</Title>
                                                        <Checkbox checked={loyaltyComunicationTypes.whatsapp} />
                                                    </Flex>
                                                    {loyaltyComunicationTypes.whatsapp && (
                                                        <>
                                                            <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                                <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center', color: '#697777' }}>Send via SMS if WhatsApp fails to deliver</Title>
                                                                <Switch checked={sendIfFailsWhatsapp === 'yes'} onChange={() => setSendIfFailsWhatsapp(sendIfFailsWhatsapp === 'yes' ? 'no' : 'yes')} />
                                                            </Flex>
                                                            <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                                <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center', color: '#697777' }}>Add custom text</Title>
                                                                <Switch checked={addWhatsappCustomText} onChange={() => setAddWhatsappCustomText(!addWhatsappCustomText)} />
                                                            </Flex>
                                                        </>
                                                    )}
                                                    {loyaltyComunicationTypes.whatsapp && addWhatsappCustomText && (
                                                        <>
                                                            <Title level={5}>Use this to drive engagement with your brand</Title>
                                                            <Input showCount maxLength={149} placeholder='Write somethign awsome...' value={whatsappCustomText} onChange={(e) => setWhatsappCustomText(e.target.value)} />
                                                        </>
                                                    )}
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <div>
                                                    <Flex gap={16} justify='space-between' align='center' style={{ cursor: 'pointer' }} onClick={() => handleChannelClick('sms')} >
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiChatCenteredDotsDuotone /> SMS</Title>
                                                        <Checkbox checked={loyaltyComunicationTypes.sms} />
                                                    </Flex>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <div>
                                                    <Flex gap={16} justify='space-between' align='center' style={{ cursor: 'pointer' }} onClick={() => handleChannelClick('email')} >
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiEnvelopeDuotone /> Email</Title>
                                                        <Checkbox checked={loyaltyComunicationTypes.email} />
                                                    </Flex>
                                                </div>
                                            </Card>
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        ) : steps === 6 && (
                            <Col span={10}>
                                <div className='tempdrawerRightSide'>
                                    <Flex justify='center' align='center'>
                                        <div>
                                            <Title level={4} style={{ marginTop: 16, display: 'flex', alignItems: 'center', gap: 4 }}>Receive alerts for multiple orders <br />placed using the same phone number.</Title>
                                            <Card bordered={false} className='left-div'>
                                                <div>
                                                    <Flex gap={16} justify='space-between' align='center' style={{ cursor: 'pointer' }}>
                                                        <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}>Multiple orders alert</Title>
                                                        <Switch checked={isMultipleOrderAlerts === 'yes'} onChange={() => setIsMultipleOrderAlerts(isMultipleOrderAlerts === 'yes' ? 'no' : 'yes')} />
                                                    </Flex>
                                                    <div className='info-div' style={{ backgroundColor: '#E9E2F8', marginTop: 16, marginBottom: 16, padding: 8, borderRadius: 8 }}>
                                                        <Flex align='center' gap={8}>
                                                            <PiStarDuotone size={48} color='#7234A6' />
                                                            <Paragraph style={{ margin: 0, fontSize: 14 }}>You will receive a <b style={{ color: '#7234a6' }}>daily alert on your mobile number at 11 PM</b> based on the channels you have selected.</Paragraph>
                                                        </Flex>
                                                    </div>
                                                    <Title level={5}>Alert me when minimum of</Title>
                                                    <Flex gap={16} justify='space-between' align='center'>
                                                        <Input type='number' placeholder='No. orders' value={multipleOrderAlertOneDay} onChange={(e) => setMultipleOrderAlertOneDay(e.target.value)} disabled={!isMultipleOrderAlerts || !multipleOrderAlertIn.oneday} />
                                                        <Input readOnly value='In 1 Day' disabled={!isMultipleOrderAlerts || !multipleOrderAlertIn.oneday} />
                                                        <Checkbox checked={multipleOrderAlertIn.oneday} onChange={() => handleMultipleOrdersInClick('oneday')} disabled={!isMultipleOrderAlerts} />
                                                    </Flex>
                                                    <Flex gap={16} justify='space-between' align='center' style={{ marginTop: 16 }}>
                                                        <Input type='number' placeholder='No. orders' value={multipleOrderAlertSevenDay} onChange={(e) => setMultipleOrderAlertSevenDay(e.target.value)} disabled={!isMultipleOrderAlerts || !multipleOrderAlertIn.sevenday} />
                                                        <Input readOnly value='In 7 Days' disabled={!isMultipleOrderAlerts || !multipleOrderAlertIn.sevenday} />
                                                        <Checkbox checked={multipleOrderAlertIn.sevenday} onChange={() => handleMultipleOrdersInClick('sevenday')} disabled={!isMultipleOrderAlerts} />
                                                    </Flex>
                                                </div>
                                            </Card>
                                            <Card bordered={false} className='left-div' style={{ marginTop: 16 }}>
                                                <Flex className={`template-channle-checkbox-div ${multipleOrderAlertTypes.whatsapp ? 'active' : 'not-active'}`} gap={16} justify='space-between' align='center' onClick={() => handleAlertChannelClick('whatsapp')} style={{ cursor: 'pointer' }}>
                                                    <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiWhatsappLogoDuotone /> Whatsapp Utility</Title>
                                                    <Checkbox checked={multipleOrderAlertTypes.whatsapp} />
                                                </Flex>
                                                <Flex className={`template-channle-checkbox-div ${multipleOrderAlertTypes.sms ? 'active' : 'not-active'}`} gap={16} justify='space-between' align='center' style={{ cursor: 'pointer' }} onClick={() => handleAlertChannelClick('sms')} >
                                                    <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiChatCenteredDotsDuotone /> SMS</Title>
                                                    <Checkbox checked={multipleOrderAlertTypes.sms} />
                                                </Flex>
                                                <Flex className={`template-channle-checkbox-div ${multipleOrderAlertTypes.email ? 'active' : 'not-active'}`} gap={16} justify='space-between' align='center' style={{ cursor: 'pointer' }} onClick={() => handleAlertChannelClick('email')} >
                                                    <Title level={5} style={{ margin: 0, display: 'flex', gap: 8, alignItems: 'center' }}><PiEnvelopeDuotone /> Email</Title>
                                                    <Checkbox checked={multipleOrderAlertTypes.email} />
                                                </Flex>
                                            </Card>
                                        </div>
                                    </Flex>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Drawer>
                <Modal
                    title="Test Email"
                    centered
                    open={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    footer={[
                        <Button key="send" type="primary" onClick={sendTestNow} loading={sendLoader}>
                            Send
                        </Button>,
                    ]}
                >
                    <div style={{ marginBottom: 16 }}>
                        <label>Phone no.</label>
                        <PhoneInput value={testPhoneNo} onChange={(value) => setTestPhoneNo(value)} enableSearch={true} inputStyle={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Email</label>
                        <Input
                            value={testEmailId}
                            onChange={(e) => setTestEmailId(e.target.value)}
                            placeholder="Enter email for test"
                        />
                    </div>
                </Modal>
                <Modal
                    title="Creative"
                    centered
                    open={viewModalOpen}
                    onCancel={() => setViewModalOpen(false)}
                    footer={[
                        <Button key="send" type="primary" onClick={handleDownload} loading={loading}x1>
                            <DownloadOutlined /> Download
                        </Button>
                    ]}
                >
                    <div className='add-loyalty-left-popup'>
                        <div className='add-loyalty-popup-left-div' ref={uploadContainerRef}>
                            <div className='loyalty-edit-creative-card'>
                                <div className='preview-card' style={{ borderBottom: `${loyaltyType === 'cashback' ? `4px dashed #FFFFFF` : 'unset'}`, backgroundColor: themeColor }}>
                                    <div className='card-theme-step'>
                                        <div className='card-header'>
                                            <Image preview={false} src={banner ? banner : fileName !== '' ? `${window.BASE_URL}uploads/loyalty_media/${fileName}` : dummyImage} />
                                        </div>
                                        <div className='card-logo-div'>
                                            {storeDetail?.store_logo || file ? (
                                                <img alt='card-logo' className='card-logo-image' src={file ? file : logoName !== '' ? `${window.BASE_URL}uploads/loyalty_media/${logoName}` : `${window.BASE_URL}${storeDetail?.store_logo}`} />
                                            ) : (
                                                <Paragraph className='card-logo-name'>{storeDetail?.store_name}</Paragraph>
                                            )}
                                        </div>
                                    </div>
                                    <div className='card-content'>
                                        <div className='title-content'>
                                            <Title level={3} style={{ color: textColor, fontSize: 27, fontWeight: 'bold', margin: 0 }}>{cardTitle === '' ? 'Write title' : cardTitle}</Title>
                                            <Paragraph style={{ color: textColor, fontSize: 18, margin: 0 }}>{cardSubTitle === '' ? 'Write subtitle' : cardSubTitle}</Paragraph>
                                        </div>
                                        {loyaltyType === 'cashback' ? (
                                            <div className='cashback-content'>
                                                <Paragraph style={{ color: textColor, fontSize: 12, margin: 0 }}>Earn</Paragraph>
                                                <Title level={2} style={{ color: textColor, fontWeight: 'bold', margin: 0 }}>{cashBackType === 'per' ? `${cashback}%` : `${storeDetail?.currency_symbol} ${cashback}`}</Title>
                                                <Paragraph style={{ color: textColor, fontSize: 16, margin: 0 }}>{brandingName === '' ? 'Cashback' : brandingName} {isMinPurchaseForEarning ? `On min purchase of ${storeDetail?.currency_symbol} ${minPurchaseForEarning}` : 'on every purchase'}</Paragraph>
                                            </div>
                                        ) : loyaltyType === 'onspent' ? (
                                            <div className='cashback-content'>
                                                <Paragraph style={{ color: textColor, fontSize: 22, margin: 0, fontWeight: 'bold' }}>{storeDetail?.currency_symbol} {spentamt} = {`${spentamtPoint} ${pointName} ${spentamtPoint === 1 ? 'Point' : 'Points'}`} </Paragraph>
                                            </div>
                                        ) : loyaltyType === 'onvisit' && (
                                            <div className='cashback-content'>
                                                <Paragraph style={{ color: textColor, fontSize: 22, margin: 0, fontWeight: 'bold' }}>{`${visits} ${visits === 1 ? 'Visit' : 'Visits'}`} = {`${visitPoint} ${pointName} ${visitPoint === 1 ? 'Point' : 'Points'}`}</Paragraph>
                                            </div>
                                        )}
                                        <div className='spent-visit-items'>
                                            {pointItems.map((pointItem, index) => (
                                                (pointItem?.points !== '' || pointItem?.item !== '') && (
                                                    <div className='spent-visit-item' style={{ borderColor: textColor }}>
                                                        <Flex gap={16} justify='space-between' align='center'>
                                                            <div><Paragraph style={{ color: textColor, margin: 0 }}><b>{pointItem?.points}</b></Paragraph><Paragraph style={{ color: textColor, margin: 0 }}>{pointName} {pointItem?.points === '1' ? 'Point' : 'Points'}</Paragraph></div>
                                                            <div><Paragraph style={{ color: textColor, margin: 0 }}>{pointItem?.item}</Paragraph></div>
                                                        </Flex>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                        {loyaltyType !== 'cashback' && (
                                            <div>
                                                <div className='preview-footer' style={{ border: `1px dashed ${textColor}`, cursor: 'pointer', position: 'relative', borderRadius: 8, padding: 16, whiteSpace: 'pre-line', color: textColor }}>
                                                    <Paragraph style={{ color: textColor }}><b>Terms & Conditions</b></Paragraph>
                                                    {termsText}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {loyaltyType === 'cashback' && (
                                    <div className='cashback-terms'>
                                        <Paragraph style={{ textAlign: 'center' }}>Use your {isCashBackBranding ? brandingName === '' ? 'cashback' : brandingName : 'cashback'} to pay your next bill.</Paragraph>
                                        <div className='preview-footer' style={{ whiteSpace: 'pre-line' }}>
                                            <Paragraph><b>Terms & Conditions</b></Paragraph>
                                            {isMaxCashbackCanEarn && `*Earn upto ${storeDetail?.currency_symbol} ${maxCashbackCanEarn} on every purchase.\n`}
                                            {isMinPurchaseForRedeeming && `*Minimum purchase of ${storeDetail?.currency_symbol} ${minPurchaseForRedeeming} required to redeem.\n`}
                                            {termsText}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </Layout>
        </>
    );
};
export default LoyaltyContent;

