import React, { useMemo } from 'react';
import blogo from '../assets/Zipler.svg';
import blogoOne from '../assets/Zipler-t.svg';
import { Image, Layout, Menu } from 'antd';
import { IoBarChart, IoBarChartOutline, IoChatboxEllipses, IoChatboxEllipsesOutline, IoMegaphone, IoMegaphoneOutline, IoQrCode, IoQrCodeOutline } from "react-icons/io5";
import { HiOutlineUsers, HiUsers, HiFaceSmile, HiOutlineFaceSmile, HiOutlineUserPlus, HiUserPlus, HiUserCircle, HiOutlineUserCircle, HiOutlineBuildingStorefront, HiBuildingStorefront, HiCog, HiOutlineCog, HiNewspaper, HiOutlineNewspaper, HiQuestionMarkCircle, HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { BsBalloonHeart, BsBalloonHeartFill, BsRobot } from "react-icons/bs";
import { RiRefreshLine, RiRefreshFill } from "react-icons/ri";
const { Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  position: 'fixed',
  height: '100vh',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

const sideBarItems = (selectedKey, userData) => {
  const allItems = [
    {
      key: 'home',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'home' ? 'active' : ''}`}>
          {selectedKey === 'home' ? <IoBarChart size={18} /> : <IoBarChartOutline size={18} />}
        </div>
      ),
      label: 'Dashboard',
    },
    {
      key: 'campaigns',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'campaigns' ? 'active' : ''}`}>
          {selectedKey === 'campaigns' ? <IoMegaphone size={18} /> : <IoMegaphoneOutline size={18} />}
        </div>
      ),
      label: 'Campaigns',
    },
    {
      key: 'customer-insights',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'customer-insights' ? 'active' : ''}`}>
          {selectedKey === 'customer-insights' ? <HiUsers size={18} /> : <HiOutlineUsers size={18} />}
        </div>
      ),
      label: 'Customer insights',
    },
    {
      key: 'loyalty',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'loyalty' ? 'active' : ''}`}>
          {selectedKey === 'loyalty' ? <BsBalloonHeartFill size={18} /> : <BsBalloonHeart size={18} />}
        </div>
      ),
      label: 'Loyalty',
    },
    {
      key: 'auto-campaigns',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'auto-campaigns' ? 'active' : ''}`}>
          {selectedKey === 'auto-campaigns' ? <RiRefreshFill size={18} /> : <RiRefreshLine size={18} />}
        </div>
      ),
      label: 'Auto Campaigns',
    },
    {
      key: 'feedback',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'feedback' ? 'active' : ''}`}>
          {selectedKey === 'feedback' ? <HiFaceSmile size={18} /> : <HiOutlineFaceSmile size={18} />}
        </div>
      ),
      label: 'Feedback',
    },
    {
      key: 'qr-code',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'qr-code' ? 'active' : ''}`}>
          {selectedKey === 'qr-code' ? <IoQrCode size={18} /> : <IoQrCodeOutline size={18} />}
        </div>
      ),
      label: 'Qr Code',
    },
    {
      key: userData?.whatsappAccessToken !== '' ? 'whatsapp' : 'whatsapp-signup',
      icon: (
        <div className={`icon-wrapper ${selectedKey === (userData?.whatsappAccessToken !== '' ? 'whatsapp' : 'whatsapp-signup') ? 'active' : ''}`}>
          {selectedKey === (userData?.whatsappAccessToken !== '' ? 'whatsapp' : 'whatsapp-signup') 
            ? <IoChatboxEllipses size={18} /> 
            : <IoChatboxEllipsesOutline size={18} />}
        </div>
      ),
      label: 'Inbox',
    },
    // {
    //   key: 'automation',
    //   icon: (
    //     <div className={`icon-wrapper ${selectedKey === 'automation' ? 'active' : ''}`}>
    //       {selectedKey === 'automation' ? <BsRobot size={18} /> : <BsRobot size={18} />}
    //     </div>
    //   ),
    //   label: 'Automation',
    // },
    {
      key: 'add-customers',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'add-customers' ? 'active' : ''}`}>
          {selectedKey === 'add-customers' ? <HiUserPlus size={18} /> : <HiOutlineUserPlus size={18} />}
        </div>
      ),
      label: 'Add Customers',
    },
    {
      key: 'account',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'account' ? 'active' : ''}`}>
          {selectedKey === 'account' ? <HiUserCircle size={18} /> : <HiOutlineUserCircle size={18} />}
        </div>
      ),
      label: 'Your Account',
    },
    {
      key: 'stores',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'stores' ? 'active' : ''}`}>
          {selectedKey === 'stores' ? <HiBuildingStorefront size={18} /> : <HiOutlineBuildingStorefront size={18} />}
        </div>
      ),
      label: 'Store Details',
    },
    {
      key: 'invite-team',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'invite-team' ? 'active' : ''}`}>
          {selectedKey === 'invite-team' ? <HiUsers size={18} /> : <HiOutlineUsers size={18} />}
        </div>
      ),
      label: 'Invite Team',
    },
    {
      key: 'channels',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'channels' || selectedKey === 'channel-settings' ? 'active' : ''}`}>
          {selectedKey === 'channels' || selectedKey === 'channel-settings' ? <HiCog size={18} /> : <HiOutlineCog size={18} />}
        </div>
      ),
      label: 'Channels',
    },
    {
      key: 'plans',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'plans' ? 'active' : ''}`}>
          {selectedKey === 'plans' ? <HiNewspaper size={18} /> : <HiOutlineNewspaper size={18} />}
        </div>
      ),
      label: 'Plans',
    },
    {
      key: 'help',
      icon: (
        <div className={`icon-wrapper ${selectedKey === 'help' ? 'active' : ''}`}>
          {selectedKey === 'help' ? <HiQuestionMarkCircle size={18} /> : <HiOutlineQuestionMarkCircle size={18} />}
        </div>
      ),
      label: 'Help Center',
    },
  ];

  const restrictedKeys = ['channel-settings', 'account', 'stores', 'invite-team', 'channels', 'plans', 'help'];

  if (restrictedKeys.includes(selectedKey)) {
    return allItems.filter(item => item.key === 'home' || restrictedKeys.includes(item.key));
  }

  return allItems.filter(item => item.key === 'home' || !restrictedKeys.includes(item.key));
};

const SidebarContent = ({ collapsed, selectedKey, handleMenuClick, userData }) => {
  const memoizedMenuItems = useMemo(() => sideBarItems(selectedKey === 'channel-settings' ? 'channels' : selectedKey, userData), [selectedKey, userData]);
  return (
    <Sider style={siderStyle} trigger={null} collapsible collapsed={collapsed} theme="light">
      <div className='mainLogo'>
        {collapsed ? (
          <Image src={blogoOne} preview={false} style={{ width: '100%' }} />
        ) : (
          <Image src={blogo} preview={false} style={{ width: '100%' }} />
        )}
      </div>
      <Menu theme="light" mode="inline" className='sideBarMenu' selectedKeys={[selectedKey === 'channel-settings' ? 'channels' : selectedKey]} onClick={handleMenuClick} items={memoizedMenuItems} />
    </Sider>
  );

};

export default SidebarContent;