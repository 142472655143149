import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import _, { values } from 'lodash';
import { Typography, Flex, Avatar, Tooltip, Button, Skeleton, Tabs, Input, Empty, Image, List, Card, message, Spin, Space, Drawer, Row, Col, Select, Upload } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DownloadOutlined, LeftOutlined, LinkOutlined, LoadingOutlined, PhoneOutlined, PlusOutlined, RightOutlined, RotateLeftOutlined, RotateRightOutlined, SendOutlined, SwapOutlined, UndoOutlined, UserOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { IoCheckmark, IoCheckmarkDone, IoTimeOutline } from 'react-icons/io5';
import { PiArrowLeftDuotone, PiMicrosoftPowerpointLogoDuotone, PiPaperclipDuotone, PiSmileyDuotone } from 'react-icons/pi';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { TbTemplate } from "react-icons/tb";
import { FaBold, FaItalic, FaStrikethrough } from 'react-icons/fa';
import { GrMonospace } from 'react-icons/gr';
import textIcon from '../assets/attachmenticons/text.png';
import fileIcon from '../assets/attachmenticons/file.png';
import excelIcon from '../assets/attachmenticons/excel.png';
import pdfIcon from '../assets/attachmenticons/pdf.png';
import pptIcon from '../assets/attachmenticons/ppt.png';
import wordIcon from '../assets/attachmenticons/word.png';
import whatsappMobileMokup from '../assets/zipler-mobile-mock-up.png';

const { Title } = Typography;
const { TextArea } = Input;

const getBase64One = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};

function getTimeFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12;

    const hours12 = twelveHourFormat.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');

    return `${hours12}:${minutesStr} ${amPM}`;
}

const getExtensionFromMimeType = (mimeType) => {
    const mimeTypeToExtensionMap = {
        'image/jpeg': 'jpeg',
        'image/png': 'png',
        'image/gif': 'gif',
        'video/mp4': 'mp4',
        'video/3gp': '3gp',
        'application/pdf': 'pdf',
        'text/plain': 'txt',
        'audio/mp3': 'mp3',
        'audio/ogg': 'ogg',
        'audio/mp4': 'm4a',
        'application/vnd.ms-powerpoint': 'pptx',
        'application/msword': 'doc',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
    };
    return mimeTypeToExtensionMap[mimeType] || 'txt';
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date >= today) {
        if (date >= tomorrow) {
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        } else if (date >= today) {
            return getTimeFromDate(date);
        }
    } else if (date >= yesterday) {
        return 'Yesterday';
    } else if (date >= today - 6 * 24 * 60 * 60 * 1000) {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    }

    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
};

const handleReplaceText = (text) => {
    if (text) {
        text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
        text = text.replace(/_([^_]+)_/g, "<i>$1</i>");
    }
    return text;
};

const formatText = (text) => {
    // Replace ** with <b> for bold
    text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');

    // Replace _ _ with <i> for italic
    text = text.replace(/_(.*?)_/g, '<i>$1</i>');

    // Replace \n with <br> for new line
    text = text.replace(/\\n/g, '<br>');

    return text;
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        if (file instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        } else {
            reject(new Error('Invalid file type. Expected a Blob.'));
        }
    });

const ChatModule = ({ selectedUser, sessionData, socketData, userData }) => {
    console.log(userData);
    const navigate = useNavigate();
    const [chats, setChats] = useState([]);
    const [chatPage, setChatPage] = useState(1);
    const [hasMoreChats, setHasMoreChats] = useState(true);
    const [loading, setLoading] = useState(false);
    const [templatedata, setTemplatedata] = useState([]);
    const [msgBtnLoading, setMsgBtnLoading] = useState(false);
    const [textAreaMessage, setTextAreaMessage] = useState('');
    const [textAreaDisabled, setTextAreaDisabled] = useState(true);
    const [showPicker, setShowPicker] = useState(false);
    const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false);
    const [activeTemplateItem, setActiveTemplateItem] = useState([]);
    const [templatePreview, setTemplatePreview] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [filterOption, setFilterOption] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [templateImageId, setTemplateImageId] = useState('');
    const [templateImageExtension, setTemplateImageExtension] = useState('');
    const [fileName, setFileName] = useState('');
    const [templateImage, setTemplateImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const pickerRef = useRef(null);
    const textAreaRef = useRef(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imageList, setImageList] = useState([]);
    const [activeImageUid, setActiveImageUid] = useState(null);
    const [tmpBtnLoading, setTmpBtnLoading] = useState(false);

    const loadMoreChats = async (page) => {
        if (loading) {
            return;
        }
        setLoading(true);
        let url;
        if (page === 1) {
            url = `${window.API_BASE_URL}/chats/getchats?page=${page}&number=${selectedUser?.customer_number}&phoneid=${userData?.phonenumberid}`;
        } else {
            url = `${window.API_BASE_URL}/chats/getchats?page=${chatPage}&number=${selectedUser?.customer_number}&phoneid=${userData?.phonenumberid}`;
        }
        console.log(url);
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = response.data;
            if (response.status === 200) {
                console.log(data?.chats);
                setChats(prevData => [...prevData, ...data?.chats]);
                if (page === 1) {
                    const fromMessages = data?.chats.filter(chat => chat.is_message === 'from');
                    if (fromMessages.length === 0) {
                        setTextAreaDisabled(true);
                    } else {
                        const messageDate = new Date(fromMessages[0].created * 1000);
                        const currentDate = new Date();
                        const hoursDifference = (currentDate - messageDate) / (1000 * 60 * 60);
                        setTextAreaDisabled(hoursDifference > 24)
                    }
                    setChatPage(1 + 1);
                } else {
                    setChatPage(prevPage => prevPage + 1);
                }
                setHasMoreChats(data?.chats.length > 19);
                if (page === 1 && data?.chats?.[0]) {
                    if (data?.chats?.[0]?.msgstatus !== 'read') {
                        updateReceivedMessage(data?.chats?.[0]);
                    }
                }
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching chats:', data.message);
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                console.error('Error during fetching chats:', error.response.data.message);
            } else {
                console.error('Error during fetching chats:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const onVarChange = (element, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [element]: value,
        }));
    };

    const onVaueChange = (element, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [element]: value,
        }));
    };

    const handleTemplateSend = async () => {
        const activerecept = selectedUser;
        const template = _.cloneDeep(activeTemplateItem);
        const templateName = template.name;
        const templateLanguage = template.language;
        const wanumber = activerecept.customer_number;
        const variables = selectedValues;
        const imageId = templateImageId;
        console.log(templateImageId);
        console.log(imageId);
        let datacomponent = [];
        // const headerComponent = template?.components?.find(component => component.type === "HEADER");
        // if (headerComponent) {
        //     if (headerComponent.format === 'IMAGE') {
        //         if (imageId === '') {
        //             message.error('Please Upload the image');
        //             return;
        //         }
        //     }
        // }
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component?.text?.match(/{{\d+}}/g)?.forEach(element => {
                        const replacement = variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    component.example.header_handle[0] = `https://api.zipler.io/uploads/whatsapp_media/${imageId}.${fileName.split('.').pop()}`;
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component?.text?.match(/{{\d+}}/g)?.forEach(element => {
                        const replacement = variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });

        console.log(datacomponent);
        const body = {
            accessToken: userData?.whatsappAccessToken,
            phoneNoId: userData?.phonenumberid,
            to: wanumber,
            template_name: templateName,
            language: templateLanguage,
            template: template,
            components: datacomponent
        };
        console.log('body', body);
        try {
            setTmpBtnLoading(true);
            const response = await fetch(`${window.API_BASE_URL}/chats/send-template-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify(body),
            });
            const responseData = await response.json();
            if (response.ok) {
                message.success('Template sent');
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'template',
                    message: templateName,
                    status: 'sending',
                    template: JSON.stringify(template)
                };
                setChats(prevData => [sentMessage, ...prevData]);
                console.log('WhatsApp template sent successfully:', responseData);
                message.success('Message sent');
            } else {
                message.error(responseData.error || 'Unknown error');
                console.error('Failed to send WhatsApp template:', responseData.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error sending WhatsApp template:', error);
            message.error('Internal Server Error');
        } finally {
            setTmpBtnLoading(false);
            setOpenTemplateDrawer(false);
        }
    };

    const onVarSearch = (value) => {
        console.log('search:', value);
    };

    const inputfilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const updateReceivedMessage = async (newReceivedMessage) => {
        console.log('newReceivedMessage', newReceivedMessage);
        try {
            const response = await fetch(`${window.API_BASE_URL}/chats/update-received-message-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    accessToken: userData?.whatsappAccessToken,
                    phoneNoId: userData?.phonenumberid,
                    message_id: newReceivedMessage.mainid,
                    wamid: newReceivedMessage.wamid,
                    wanumber: newReceivedMessage.wanumber
                }),
            });
            if (!response.ok) {
                throw new Error(`Update Status failed with status ${response.status}`);
            }
            const data = await response.json();
            console.log('Update Status API response:', data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const checkFile = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPEG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt5M;
    };

    const handlePreviewTemplate = (template) => {
        setActiveTemplateItem(template);
        setSelectedValues({});
        if (template && Array.isArray(template.components)) {
            setTemplatePreview(template.components);
        }
    };
    const customRequest = ({ file, onSuccess, onError, onProgress }) => {
        setFilesToUpload(prevFiles => [...prevFiles, file]);
        if (!previewVisible) {
            setPreviewVisible(true);
        }
        setImageUploadLoading(true);
        const maxRetries = 3;
        let retryCount = 0;
        const uploadWithRetry = () => {
            setTimeout(() => {
                const isUploaded = Math.random() > 0.5;

                if (isUploaded) {
                    onSuccess();
                    handlePreview(file);
                    setImageUploadLoading(false);
                } else {
                    retryCount++;
                    if (retryCount <= maxRetries) {
                        uploadWithRetry();
                    } else {
                        onError();
                        message.error(`${file.name} file upload failed after ${maxRetries} retries`);
                        setImageUploadLoading(false);
                    }
                }
            }, 1000);
        };

        uploadWithRetry();
    };

    const handlePreview = async (file) => {
        console.log(file.type);
        if (!file.url && !file.preview) {
            try {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isText = file.type === 'text/plain';
                const isPDF = file.type === 'application/pdf';
                const isPPT = file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (isImage) {
                    // Generate a preview URL using FileReader
                    const preview = await getBase64(file);
                    setPreviewImage(preview);
                    // setUploadFileList((prevImageList) => [
                    //     ...prevImageList,
                    //     { uid: file.uid, url: preview },
                    // ]);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview },
                    ]);
                } else if (isVideo) {
                    const preview = await getBase64(file);
                    setPreviewImage(preview);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview, filetype: 'image' },
                    ]);
                } else if (isText) {
                    setPreviewImage(textIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: textIcon, filetype: 'icon' },
                    ]);
                } else if (isPDF) {
                    setPreviewImage(pdfIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pdfIcon, filetype: 'icon' },
                    ]);
                } else if (isPPT) {
                    setPreviewImage(pptIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pptIcon, filetype: 'icon' },
                    ]);
                } else if (isWord) {
                    setPreviewImage(wordIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: wordIcon, filetype: 'icon' },
                    ]);
                } else if (isExcel) {
                    setPreviewImage(excelIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: excelIcon, filetype: 'icon' },
                    ]);
                } else {
                    setPreviewImage(fileIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: fileIcon, filetype: 'icon' },
                    ]);
                }
                setActiveImageUid(file.uid);
            } catch (error) {
                console.error('Error getting base64:', error);
                setPreviewImage('');
            }
        }

        setPreviewVisible(true);
    };

    const handleCancel = () => setPreviewVisible(false);

    useEffect(() => {
        if (activeTemplateItem && Array.isArray(activeTemplateItem.components)) {
            setTemplatePreview(activeTemplateItem.components);

            let options = [
                {
                    value: selectedUser.customer_name,
                    label: 'Name',
                },
                {
                    value: selectedUser.customer_email,
                    label: 'Email',
                },
                {
                    value: selectedUser.customer_number,
                    label: 'Phone Number',
                },
            ];
            setFilterOption(options);
        } else {
            console.error('Invalid item format:', activeTemplateItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTemplateItem, templatePreview, openTemplateDrawer]);

    const handleTextStyle = (type) => {
        const textareaRef = textAreaRef.current.resizableTextArea.textArea;
        const textareaValue = textAreaMessage || '';
        if (!textareaRef) return;

        const start = textareaRef.selectionStart;
        const end = textareaRef.selectionEnd;
        const selectedText = textareaValue.substring(start, end);
        let newText
        switch (type) {
            case 'bold':
                newText = textareaValue.slice(0, start) + `*${selectedText}*` + textareaValue.slice(end);
                break;
            case 'italic':
                newText = textareaValue.slice(0, start) + `_${selectedText}_` + textareaValue.slice(end);
                break;
            case 'strike':
                newText = textareaValue.slice(0, start) + `~${selectedText}~` + textareaValue.slice(end);
                break;
            case 'mono':
                newText = textareaValue.slice(0, start) + `\`\`\`${selectedText}\`\`\`` + textareaValue.slice(end);
                break;
            default:
                newText = textareaValue;
                break;
        }
        setTextAreaMessage(newText);
        setTimeout(() => {
            if (textAreaRef) {
                const newCursorPosition = type === 'mono' ? start + (selectedText ? selectedText.length + 3 : 3) : start + (selectedText ? selectedText.length + 2 : 1);
                textareaRef.focus();
                textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    }

    const customRequestImage = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('whatsappaccesstoken', userData?.whatsappAccessToken);
                formData.append('phonenumberid', userData?.phonenumberid);
                const fileType = file.type;
                console.log(fileType);
                const response = await fetch(action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        ...headers,
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const responseData = await response.json();
                console.log(responseData);
                setTemplateImageId(responseData?.mediaData?.id);
                setTemplateImageExtension(fileType);
                setFileName(responseData?.fileName);

                onSuccess(responseData?.mediaData, file);
            } catch (error) {
                onError(error);
                console.log(error);
            }
        };
    };

    const fetchTemplateData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/chats/get-template`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    whatsappaccesstoken: userData?.whatsappAccessToken,
                    wabaid: userData?.wabaid
                }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                setTemplatedata(data?.templates?.data);
            }
        } catch (error) {
            console.error('Error during fetching template data:', error);
        } finally {
            setIsLoading(false);
        };
    }, [sessionData, userData]);

    useEffect(() => {
        if (sessionData) {
            fetchTemplateData();
        }
    }, [fetchTemplateData, sessionData, userData]);

    useEffect(() => {
        if (socketData) {
            console.log(socketData);
            switch (socketData?.is_message) {
                case 'from':
                    if (socketData?.phone_number_id === userData?.phonenumberid) {
                        if (socketData?.wanumber === selectedUser?.customer_number) {
                            const existingChat = chats.find(chat => chat.mainid === socketData.mainid);
                            if (!existingChat) {
                                updateReceivedMessage(socketData);
                                setChats(prevData => [socketData, ...prevData]);
                                setTextAreaDisabled(false);
                            }
                        }
                    }
                    break;
                case 'to':
                    setChats(prevData => prevData.map(chat => chat.mainid === socketData.id ? { ...chat, status: socketData.status } : chat));
                    break;
                default:

                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketData]);

    const handleChange = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        console.log(info.file.status);
        if (info.file.status === 'done') {
            getBase64One(info.file.originFileObj, (url) => {
                setTemplateImage(url);
                setLoading(false);
            });
        }
    };

    const handleUpdate = (values) => {
        console.log('Form submitted with values:', values);
    };

    const handleSendMessage = async () => {
        console.log(sessionData?.token);
        if (msgBtnLoading) return;
        const activerecept = selectedUser;
        const wanumber = activerecept.customer_number;
        const textmessage = textAreaMessage;
        try {
            setMsgBtnLoading(true);
            const response = await fetch(`${window.API_BASE_URL}/chats/send-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    accessToken: userData?.whatsappAccessToken,
                    phoneNoId: userData?.phonenumberid,
                    to: wanumber,
                    message: textmessage,
                }),
            });
            const responseData = await response.json();
            if (response.ok) {
                message.success('Message sent');
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'text',
                    message: textmessage,
                    status: 'sending',
                };
                console.log(sentMessage);
                setChats(prevData => [sentMessage, ...prevData]);
            } else {
                message.error(responseData.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error sending WhatsApp Message:', error);
        } finally {
            setMsgBtnLoading(false);
            setTextAreaMessage('');
        }
    };

    const handleEmojiSelect = (emoji) => {
        const textareaRef = textAreaRef.current.resizableTextArea.textArea;
        const cursorPosition = textareaRef.selectionStart;
        const updatedMessage =
            textAreaMessage.slice(0, cursorPosition) +
            emoji.native +
            textAreaMessage.slice(cursorPosition);
        setTextAreaMessage(updatedMessage);
        setShowPicker(false);
        setTimeout(() => {
            if (textAreaRef) {
                const newCursorPosition = cursorPosition + emoji.native.length;
                textareaRef.focus();
                textareaRef.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    const onDownload = (url) => {
        console.log(url);
        const suffix = url.slice(url.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const blobUrl = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(blobUrl);
                link.remove();
            });
    };

    useEffect(() => {
        if (sessionData?.token && selectedUser?.customer_number) {
            setChats([]);
            setChatPage(1);
            setHasMoreChats(true);
            loadMoreChats(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser, sessionData?.token]);
    let previousDate = null;
    return (
        <>
            {selectedUser?.customer_id ? (
                <div>
                    <Flex gap={16} justify='space-between' align='center'>
                        <Flex gap={16} align='center' justify='flex-start'>
                            <Avatar>{selectedUser?.customer_name ? selectedUser?.customer_name.charAt(0).toUpperCase() : <UserOutlined />}</Avatar>
                            <div>
                                <Tooltip title={selectedUser.customer_name}>
                                    <Title level={5} style={{ margin: 0 }}>{selectedUser.customer_name && selectedUser.customer_name.length > 20
                                        ? `${selectedUser.customer_name.substring(0, 20)}...`
                                        : selectedUser.customer_name}</Title>
                                </Tooltip>
                                <Button type='link' style={{ margin: 0, padding: 0, fontSize: 10 }} href={`tel:${selectedUser.customer_number}`}>{selectedUser.customer_number}</Button>
                            </div>
                        </Flex>
                        {userData?.whatsappAccessToken === '' && (
                            <Button type='primary' onClick={() => navigate('/whatsapp-signup')} >Connect your number</Button>
                        )}
                    </Flex>
                    <div className='chat-area-container' id='chat-area-container'>
                        <InfiniteScroll
                            dataLength={chats.length}
                            next={loadMoreChats}
                            hasMore={hasMoreChats}
                            style={{ display: 'flex', flexDirection: 'column-reverse', overflowX: 'hidden' }}
                            inverse={true}
                            loader={<div><div className="chat-item left"><div className='chat-bubble left'><Skeleton paragraph={{ rows: 1 }} active /></div></div><div className="chat-item right"><div className='chat-bubble right'><Skeleton paragraph={{ rows: 1 }} active /></div></div></div>}
                            scrollableTarget="chat-area-container"
                        >
                            {chats.map((chat, index) => {
                                const currentDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(chat.created * 1000));
                                const displayDate = currentDate !== previousDate ? (
                                    <div key={`date-${index}`} className="showdate">
                                        <span style={{ color: '#ddd' }}>{formatDate(chat.created)}</span>
                                    </div>
                                ) : null;
                                previousDate = currentDate;
                                return (
                                    <div key={`message-${index}`}>
                                        {displayDate}
                                        <div className={`chat-item ${chat.is_message === 'to' ? 'right' : 'left'}`}>
                                            <div
                                                key={chat.wid}
                                                className={`chat-bubble ${chat.is_message === 'to' ? 'right' : 'left'}`}
                                            >
                                                {chat.msgtype === 'text' ? (
                                                    <div className='message-box-area'>
                                                        <div className='message-box'>
                                                            {(() => {
                                                                try {
                                                                    const parsedMessage = JSON.parse(chat.message);
                                                                    if (parsedMessage.body) {
                                                                        return <span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage.body.toString()) }}></span>;
                                                                    } else {
                                                                        return <span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage.body.toString()) }}></span>;
                                                                    }
                                                                } catch (e) {
                                                                    return <span dangerouslySetInnerHTML={{ __html: formatText(chat.message.toString()) }}></span>;
                                                                }
                                                            })()}
                                                            <span className='more'></span>
                                                        </div>
                                                    </div>
                                                ) : chat.msgtype === 'interactive' ? (
                                                    <div className='message-box-area'>
                                                        <div className='message-box'>
                                                            <div className='intractivemessage'>
                                                                {chat.media !== '' ? (
                                                                    (() => {
                                                                        try {
                                                                            const parsedMessage = JSON.parse(chat.media);
                                                                            if (parsedMessage[parsedMessage.type].title) {
                                                                                return parsedMessage[parsedMessage.type].title;
                                                                            } else if (parsedMessage[parsedMessage.type].body) {
                                                                                return parsedMessage[parsedMessage.type].body;
                                                                            }
                                                                        } catch (e) {
                                                                            const parsedMessage = JSON.parse(chat.media);
                                                                            if (parsedMessage.type === 'button') {
                                                                                return (
                                                                                    <>
                                                                                        <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                        <div className='prvbodytext'><span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></span></div>
                                                                                        <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                        {parsedMessage.action.buttons.map((button, index) => (
                                                                                            <div className='quickreply' key={index}>
                                                                                                {button.reply.title}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                );
                                                                            } else if (parsedMessage.type === 'list') {
                                                                                return (
                                                                                    <>
                                                                                        <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                        <div className='prvbodytext'><span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></span></div>
                                                                                        <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                        {parsedMessage.action.sections.map((section, index) => (
                                                                                            <div className='list-section' key={index}>
                                                                                                <div className='section-title'>{section.title}</div>
                                                                                                {section.rows.map((row, idx) => (
                                                                                                    <div className='list-row' key={idx}>
                                                                                                        <div className='row-title'>{row.title}</div>
                                                                                                        <div className='row-description'>{row.description}</div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ))}
                                                                                        <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                                                    </>
                                                                                );
                                                                            } else if (parsedMessage.type === 'nfm_reply') {
                                                                                return parsedMessage?.type
                                                                            } else {
                                                                                return parsedMessage?.type;
                                                                            }
                                                                        }
                                                                    })()
                                                                ) : (
                                                                    (() => {
                                                                        const parsedMessage = JSON.parse(chat.message);
                                                                        if (parsedMessage.type === 'order_status') {
                                                                            return <span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text.toString()) }}></span>;
                                                                        } else if (parsedMessage.type === 'order_details') {
                                                                            return <span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text.toString()) }}></span>;
                                                                        } else if (parsedMessage.type === 'button') {
                                                                            return (
                                                                                <>
                                                                                    <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                    <div className='prvbodytext'><span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></span></div>
                                                                                    <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                    {parsedMessage.action.buttons.map((button, index) => (
                                                                                        <div className='quickreply' key={index}>{button.reply.title}
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            );
                                                                        } else if (parsedMessage.type === 'list') {
                                                                            return (
                                                                                <>
                                                                                    <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                    <div className='prvbodytext'><span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></span></div>
                                                                                    <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                    {parsedMessage.action.sections.map((section, index) => (
                                                                                        <div className='list-section' key={index}>
                                                                                            <div className='section-title'>{section.title}</div>
                                                                                            {section.rows.map((row, idx) => (
                                                                                                <div className='list-row' key={idx}>
                                                                                                    <div className='row-title'>{row.title}</div>
                                                                                                    <div className='row-description'>{row.description}</div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                                                </>
                                                                            );
                                                                        } else if (parsedMessage.type === 'request_welcome') {
                                                                            return <div className='message-box-area'>
                                                                                <div className='message-box'>
                                                                                    {JSON.parse(chat?.media)?.message !== '' ? JSON.parse(chat?.media)?.message : 'Request Welcome'}
                                                                                    <span className='more'></span>
                                                                                </div>
                                                                            </div>;
                                                                        } else if (parsedMessage.type === 'address_message') {
                                                                            return (
                                                                                <>
                                                                                    <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                    <div className='prvbodytext'><span dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></span></div>
                                                                                    <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                    <hr className='buttonhr' />
                                                                                    <div className='moreBtn'>Provide address</div>
                                                                                </>
                                                                            )
                                                                        } else if (parsedMessage.type === 'nfm_reply') {
                                                                            return parsedMessage?.nfm_reply?.body;
                                                                        } else {
                                                                            return '';
                                                                        }
                                                                    })()
                                                                )}
                                                            </div>
                                                            <span className='more'></span>
                                                        </div>
                                                    </div>
                                                ) : chat.msgtype === 'image' ? (
                                                    chat.is_message === 'to' ? (
                                                        <div className='message-box-area'>
                                                            <div className='message-box'>
                                                                <div className='messagePreviewImages'>
                                                                    <Image preview={{
                                                                        toolbarRender: (_, { transform: { scale }, actions: { onActive, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset } }) => (
                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                <RightOutlined onClick={() => onActive?.(1)} />
                                                                                <DownloadOutlined onClick={() => onDownload(chat.message)} />
                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                <UndoOutlined onClick={onReset} />
                                                                            </Space>
                                                                        )
                                                                    }} alt="Preview" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} src={chat.message} onError={() => console.error(`Failed to load image: ${chat.message}`)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        chat.is_message === 'from' && (
                                                            <div className='message-box-area'>
                                                                <div className='message-box'>
                                                                    {!chat.image ? (
                                                                        (chat.media ? (
                                                                            <div className='messagePreviewImages' key={JSON.parse(chat.media).id}>
                                                                                <Image preview={{
                                                                                    toolbarRender: (_, { transform: { scale }, actions: { onActive, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset } }) => (
                                                                                        <Space size={12} className="toolbar-wrapper">
                                                                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                            <RightOutlined onClick={() => onActive?.(1)} />
                                                                                            <DownloadOutlined onClick={() => onDownload(`https://api.zipler.io/upload/${JSON.parse(chat.media).id}.${getExtensionFromMimeType(JSON.parse(chat.media).mime_type)}`)} />
                                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                            <SwapOutlined onClick={onFlipX} />
                                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                            <UndoOutlined onClick={onReset} />
                                                                                        </Space>
                                                                                    )
                                                                                }} alt="Preview" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} src={`https://api.zipler.io/upload/${JSON.parse(chat.media).id}.${getExtensionFromMimeType(JSON.parse(chat.media).mime_type)}`} onError={() => console.log('error')} />
                                                                            </div>
                                                                        ) : (<div className='messagePreviewImages' key={chat.message}>
                                                                            <div>
                                                                                <Spin indicator={<LoadingOutlined spin />} />
                                                                            </div>
                                                                        </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className='messagePreviewImages' key={chat.image.id}>
                                                                            <Image preview={{
                                                                                toolbarRender: (_, { transform: { scale }, actions: { onActive, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset } }) => (
                                                                                    <Space size={12} className="toolbar-wrapper">
                                                                                        <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                        <RightOutlined onClick={() => onActive?.(1)} />
                                                                                        <DownloadOutlined onClick={() => onDownload(chat.image.url)} />
                                                                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                        <SwapOutlined onClick={onFlipX} />
                                                                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                        <RotateRightOutlined onClick={onRotateRight} />
                                                                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                        <UndoOutlined onClick={onReset} />
                                                                                    </Space>
                                                                                )
                                                                            }} alt="Preview" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} src={chat.image.url} onError={() => console.log('error')} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                ) : chat.msgtype === 'video' ? (
                                                    chat.is_message === 'to' ? (
                                                        <div>
                                                            <div className='messagePreviewVideos'>
                                                                <video
                                                                    src={chat.message}
                                                                    controls
                                                                    style={{ width: '100%' }}
                                                                    onError={(e) => console.log(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        chat.is_message === 'from' && (
                                                            <div>
                                                                {!chat.video ? (
                                                                    (chat.media ? (
                                                                        <div className='messagePreviewVideos' key={JSON.parse(chat.media).id}>
                                                                            <video
                                                                                src={`https://api.zipler.io/upload/${JSON.parse(chat.media).id}.${getExtensionFromMimeType(JSON.parse(chat.media).mime_type)}`}
                                                                                controls
                                                                                style={{ width: '100%' }}
                                                                                onError={(e) => console.log(e)}
                                                                            />
                                                                        </div>
                                                                    ) : (<div className='messagePreviewVideos' key={chat.message}>
                                                                        <div>
                                                                            <Spin indicator={<LoadingOutlined spin />} />
                                                                        </div>
                                                                    </div>))
                                                                ) : (
                                                                    <div className='messagePreviewVideos' key={chat.video.id}>
                                                                        <video
                                                                            src={chat.video.url}
                                                                            controls
                                                                            style={{ width: '100%' }} onError={(e) => console.log(e)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                ) : chat.msgtype === 'audio' ? (
                                                    chat.is_message === 'to' ? (
                                                        <div>
                                                            <div>
                                                                <audio controls onError={(e) => console.log(e)}>
                                                                    <source src={chat.message} type="audio/ogg" />
                                                                    <source src={chat.message} type="audio/mpeg" />
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        chat.is_message === 'from' && (
                                                            <div>
                                                                {!chat.audio ? (
                                                                    (chat.media ? (
                                                                        <div key={JSON.parse(chat.media).id}>
                                                                            <audio controls onError={(e) => console.log(e)}>
                                                                                <source src={`https://api.zipler.io/upload/${JSON.parse(chat.media).id}.${getExtensionFromMimeType(JSON.parse(chat.media).mime_type)}`} type="audio/ogg" />
                                                                                <source src={`https://api.zipler.io/upload/${JSON.parse(chat.media).id}.${getExtensionFromMimeType(JSON.parse(chat.media).mime_type)}`} type="audio/mpeg" />
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                        </div>
                                                                    ) : (<div className='messagePreviewVideos' key={chat.message}>
                                                                        <div>
                                                                            <Spin indicator={<LoadingOutlined spin />} />
                                                                        </div>
                                                                    </div>))
                                                                ) : (
                                                                    <div key={chat.audio.id}>
                                                                        <audio controls onError={(e) => console.log(e)}>
                                                                            <source src={chat.audio.url} type="audio/ogg" />
                                                                            <source src={chat.audio.url} type="audio/mpeg" />
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))

                                                ) : chat.msgtype === 'request_welcome' ? (
                                                    <div className='message-box-area'>
                                                        <div className='message-box'>
                                                            {JSON.parse(chat?.media)?.message !== '' ? JSON.parse(chat?.media)?.message : 'Request Welcome'}
                                                            <span className='more'></span>
                                                        </div>
                                                    </div>
                                                ) : chat.msgtype === 'template' ? (
                                                    <div className='message-box-area'>
                                                        <div className='message-box'>
                                                            {JSON.parse(chat?.template)?.components && JSON.parse(chat?.template)?.components.length > 0 ? (
                                                                JSON.parse(chat?.template)?.components.map((template) => (
                                                                    <div>
                                                                        {template.type === "HEADER" && (
                                                                            template.format === 'TEXT' ? (
                                                                                <p className='headeretxt'>{template.text}</p>
                                                                            ) : template.format === 'IMAGE' ? (
                                                                                <div className='previewContent'><Image preview={{
                                                                                    toolbarRender: (_, { transform: { scale }, actions: { onActive, onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset } }) => (
                                                                                        <Space size={12} className="toolbar-wrapper">
                                                                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                            <RightOutlined onClick={() => onActive?.(1)} />
                                                                                            <DownloadOutlined onClick={() => onDownload(template.example.header_handle[0])} />
                                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                            <SwapOutlined onClick={onFlipX} />
                                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                            <UndoOutlined onClick={onReset} />
                                                                                        </Space>
                                                                                    )
                                                                                }} alt="Header"
                                                                                    src={template.example.header_handle[0]} style={{ height: '100%', width: 'auto', objectFit: 'cover' }} /></div>
                                                                            ) : template.format === 'VIDEO' ? (
                                                                                <div className='previewContent'><video src={template.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                                            ) : template.format === 'DOCUMENT' ? (
                                                                                <div className='previewContent'><embed src={template.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                                            ) : (
                                                                                <p>Unsupported format</p>
                                                                            )
                                                                        )}
                                                                        {template.type === "BODY" && (
                                                                            <div className='prvbodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(template.text) }}></div>
                                                                        )}
                                                                        {template.type === "FOOTER" && (
                                                                            <div className='footertext'>{template.text}</div>
                                                                        )}{template.type === "BUTTONS" && (
                                                                            <div>
                                                                                <hr className='buttonhr' />
                                                                                {template.buttons.map((button) => (
                                                                                    <div key={button.id}>
                                                                                        {
                                                                                            button.type === 'QUICK_REPLY' ? (
                                                                                                <>
                                                                                                    <div className='quickreply'>
                                                                                                        {button.text}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                        {
                                                                                            button.type === 'URL' ? (
                                                                                                <>
                                                                                                    <div className='urlbtn'>
                                                                                                        <a target="_blank" href={button.url} rel="noreferrer">
                                                                                                            <LinkOutlined />
                                                                                                            {button.text}</a>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                        {
                                                                                            button.type === 'PHONE_NUMBER' ? (
                                                                                                <>
                                                                                                    <div className='phonebtn'>
                                                                                                        <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                                            <PhoneOutlined />
                                                                                                            {button.text}</a>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                            <span className='more'></span>
                                                        </div>
                                                    </div>
                                                ) : chat.msgtype === 'contacts' ? (
                                                    <div className='message-box-area'>
                                                        <div className='message-box'>
                                                            {!chat?.contacts && (
                                                                (chat.media && (
                                                                    <div key={JSON.parse(chat.media)?.id}>
                                                                        {console.log(JSON.parse(JSON.parse(chat.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts)}
                                                                        <List
                                                                            grid={{ gutter: 16, column: 1 }}
                                                                            dataSource={JSON.parse(JSON.parse(chat.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts}
                                                                            renderItem={(contact) => (
                                                                                <List.Item>
                                                                                    <Card className='contact-card'>
                                                                                        <p style={{ display: 'flex', justifyContent: 'flex-end' }}><strong>{contact?.name?.formatted_name}</strong></p>
                                                                                        {contact?.phones.map((phone) => (
                                                                                            <p style={{ display: 'flex', justifyContent: 'flex-end' }}><a href={`tel:${phone?.phone}`}>{phone?.phone}</a></p>
                                                                                        ))}
                                                                                    </Card>
                                                                                </List.Item>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                ))
                                                            )}
                                                            <span className='more'></span>
                                                        </div>
                                                    </div>
                                                ) : chat.msgtype === 'order' ? (
                                                    chat.is_message === 'from' && (
                                                        <div className='message-box-area'>
                                                            <div className='message-box'>
                                                                Order
                                                                <span className='more'></span>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : chat.msgtype === 'payment' ? (
                                                    chat.is_message === 'from' && (
                                                        <div className='message-box-area'>
                                                            <div className='message-box'>
                                                                Payment
                                                                <span className='more'></span>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    chat.is_message === 'from' && (
                                                        <div className='message-box-area'>
                                                            <div className='message-box'>
                                                                {JSON.parse(chat.media)['receivedMessage.type']?.button?.text}
                                                                <span className='more'></span>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div className='chat-time'><span className='time'>{convertTimestampToTime(chat.created)}</span> {chat.is_message === 'to' && (<span className={`status ${chat.status === 'sending' ? 'message_sending' : chat.status === 'sent' ? 'message_sent' : chat.status === 'delivered' ? 'message_delivered' : chat.status === 'read' && 'message_read'}`}>{chat.status === 'sending' ? <IoTimeOutline size={12} color='#FFFFFF' /> : chat.status === 'sent' ? <IoCheckmark /> : chat.status === 'delivered' ? <IoCheckmarkDone size={12} color='#FFFFFF' /> : chat.status === 'read' && <IoCheckmarkDone size={12} color='#1bf0ff' />}</span>)}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                    <div className='chat-aria-bottom-container'>
                        <div className='other-btns'>
                            <Flex gap={16} justify='space-between' style={{ width: '100%' }} align='center'>
                                <Flex gap={16} >
                                    <Button disabled={textAreaDisabled} type='link' style={{ color: '#000000', padding: 0, margin: 0, height: 'auto' }}><PiPaperclipDuotone size={24} /></Button>
                                    <Button disabled={textAreaDisabled} onClick={() => setShowPicker(!showPicker)} type='link' style={{ color: '#000000', padding: 0, margin: 0, height: 'auto' }}><PiSmileyDuotone size={24} /></Button>
                                    <Button disabled={textAreaDisabled} style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('bold')}><FaBold size={18} /></Button>
                                    <Button disabled={textAreaDisabled} style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('italic')}><FaItalic size={18} /></Button>
                                    <Button disabled={textAreaDisabled} style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('strike')}><FaStrikethrough size={18} /></Button>
                                    <Button disabled={textAreaDisabled} style={{ paddingLeft: 0, paddingRight: 6, color: '#000' }} type='link' onClick={() => handleTextStyle('mono')}><GrMonospace size={24} /></Button>
                                </Flex>
                                <Button type='link' style={{ color: '#000000', padding: 0, margin: 0, height: 'auto' }}><Flex justify='center' gap={8} align='center' onClick={() => setOpenTemplateDrawer(true)}><TbTemplate size={24} /> Choose Template</Flex></Button>
                            </Flex>
                        </div>
                        {showPicker && (
                            <div ref={pickerRef}>
                                <Picker data={data} onEmojiSelect={handleEmojiSelect} onClickOutside={() => setShowPicker(showPicker)} />
                            </div>
                        )}
                        <Tabs
                            defaultActiveKey="1"
                            items={['Reply', 'Notes'].map((item, i) => {
                                const id = String(i + 1);
                                return {
                                    key: id,
                                    label: item,
                                    children: id === "1" ? (
                                        <div>
                                            <TextArea className='chat-text-area' rows={4} value={textAreaMessage} onChange={(e) => setTextAreaMessage(e.target.value)} placeholder="Type your message" onPressEnter={handleSendMessage} maxLength={1024} disabled={textAreaDisabled} ref={textAreaRef} />
                                            <Button shape='round' className='sendButton' type='primary' onClick={handleSendMessage} loading={msgBtnLoading} icon={<SendOutlined />} disabled={textAreaDisabled} />
                                            {textAreaDisabled && (
                                                <div className="old-message-overlay">
                                                    <p><b>The customer has not spoken to you in the last 24 hours.</b></p>
                                                    <p>You can only send pre-approved templates.</p>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            <TextArea rows={4} placeholder="Notes" maxLength={1024} />
                                        </div>
                                    ),
                                };
                            })}
                        />
                    </div>
                </div>
            ) : (
                <Flex gap={16} justify='center' align='center' style={{ height: 'calc(100vh - 152px)' }}>
                    <div>
                        <Empty description={<span>Start Conversation</span>} />
                        <br />
                        <br />
                        {userData?.whatsappAccessToken === '' && (
                            <Button type='primary' onClick={() => navigate('/whatsapp-signup')} >Connect your number</Button>
                        )}
                    </div>
                </Flex>
            )}
            <Drawer
                placement='bottom'
                closable={false}
                onClose={() => setOpenTemplateDrawer(false)}
                open={openTemplateDrawer}
                className='templateDrawer'
                height="100vh"
            >
                <Row style={{ height: '10vh', overflow: 'hidden' }}>
                    <Col span={24}>
                        <Flex gap='middle' style={{ padding: '16px 24px 10px 24px' }} justify='space-between' align='center'>
                            <Flex gap='small' justify='center' align='center'>
                                <Button shape='round' className='close-btn' onClick={() => setOpenTemplateDrawer(false)}><PiArrowLeftDuotone /></Button>
                                <PiMicrosoftPowerpointLogoDuotone size={20} /> <Title level={5} style={{ margin: 0 }}>Templates</Title>
                            </Flex>
                            <Flex gap='small' justify='center' align='center'>
                                <Button onClick={() => setOpenTemplateDrawer(false)}>Exit</Button>
                                <Button type='primary' loading={tmpBtnLoading} onClick={handleTemplateSend}><PiMicrosoftPowerpointLogoDuotone /> Send Template</Button>
                            </Flex>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <div className='' style={{ height: 'calc(100vh - 84px)', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                            <List>
                                {templatedata.map((item, index) => (
                                    <List.Item
                                        key={item.id || index}
                                        onClick={() => handlePreviewTemplate(item)}
                                        className={
                                            activeTemplateItem.id === item.id ? 'templateList active' : 'templateList'
                                        }
                                    >
                                        {(isLoading || !item.id) ? (
                                            <Skeleton avatar active>
                                                <List.Item.Meta
                                                    avatar={<Avatar />}
                                                    title={<Skeleton.Input style={{ width: '40px' }} />}
                                                    description={<Skeleton.Input style={{ width: '80px' }} />}
                                                />
                                            </Skeleton>
                                        ) : (

                                            <List.Item.Meta title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}`} description={<Flex gap={16}><span><b>Status: </b>{item?.status}</span><span><b>Language: </b>{item?.language}</span><span><b>Category: </b>{item?.category}</span></Flex>} />

                                        )}
                                    </List.Item>
                                ))}
                            </List>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='variableData'>
                            <Title level={3} style={{ marginTop: 0, marginBlock: 24, textAlign: 'center' }}>Change variable data</Title>
                            {templatePreview && templatePreview.length > 0 ? (
                                templatePreview.map((component) => (
                                    <React.Fragment key={component.type}>
                                        {component.type === "HEADER" && (
                                            component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                <div>
                                                    <h4>Header</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                            <Col span={12} style={{ paddingRight: '6px' }}>
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Select Predefined Options"
                                                                    optionFilterProp="children"
                                                                    onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                    onSearch={onVarSearch}
                                                                    filterOption={inputfilterOption}
                                                                    options={filterOption}
                                                                    style={{ width: '100%' }}
                                                                    value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                />
                                                            </Col>
                                                            <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                <Input
                                                                    placeholder={`Enter Text for variable ${element}`}
                                                                    prefix={element}
                                                                    onChange={(e) => onVaueChange(`${component.type}-${element}`, e.target.value)}
                                                                    value={selectedValues[`${component.type}-${element}`]}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ) : component.format === 'IMAGE' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                    </Col>
                                                    <Col span={18} style={{ paddingLeft: '6px' }}>
                                                        <Upload
                                                            accept=".jpeg,.png"
                                                            listType="picture-card"
                                                            className="avatar-uploader"
                                                            customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                            showUploadList={false}
                                                            action={`${window.API_BASE_URL}/templates/upload-temp-media/`}
                                                            beforeUpload={checkFile}
                                                            onChange={(info) => handleChange(info)}
                                                        >
                                                            {uploadButton}
                                                        </Upload>
                                                    </Col>

                                                </Row>
                                            ) : component.format === 'VIDEO' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Upload
                                                            fileList={fileList}
                                                            customRequest={customRequest}
                                                            showUploadList={false}
                                                            beforeUpload={checkFile}
                                                            accept=".mp4"
                                                            onChange={({ fileList }) => setFileList(fileList)}
                                                        >
                                                            <Button className='addMoreFile'>
                                                                <PlusOutlined />
                                                            </Button>
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            ) : component.format === 'DOCUMENT' ? (
                                                <Row>
                                                    <Col span={24}>
                                                        <h4>Header</h4>
                                                    </Col>
                                                    <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                        <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Upload
                                                            fileList={fileList}
                                                            customRequest={customRequest}
                                                            showUploadList={false}
                                                            beforeUpload={checkFile}
                                                            accept=".pdf"
                                                            onChange={({ fileList }) => setFileList(fileList)}
                                                        >
                                                            <Button className='addMoreFile'>
                                                                <PlusOutlined />
                                                            </Button>
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ''
                                            )
                                        )}
                                        {component.type === "BODY" && (
                                            component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 && (
                                                <div>
                                                    <h4>Body</h4>
                                                    {component.text.match(/{{\d+}}/g).map(element => (
                                                        <>
                                                            <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                <Col span={12} style={{ paddingRight: '6px' }}>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Select Predefined Options"
                                                                        optionFilterProp="children"
                                                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                        onSearch={onVarSearch}
                                                                        filterOption={inputfilterOption}
                                                                        options={filterOption}
                                                                        style={{ width: '100%' }}
                                                                        value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                    />
                                                                </Col>
                                                                <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                    <Input
                                                                        placeholder={`Enter Text for variable ${element}`}
                                                                        prefix={element}
                                                                        onChange={(e) => onVaueChange(`${component.type}-${element}`, e.target.value)}
                                                                        value={selectedValues[`${component.type}-${element}`]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                ''
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='templatemessage'>
                            <Title level={3} style={{ marginTop: 0, marginBlock: 24, textAlign: 'center' }}>Review WhatsApp Message</Title>
                            <div className='template-preview-card'>
                                <img src={whatsappMobileMokup} className='back-mobile' alt='phone' />
                                <div className="message-box preview-message">
                                    <p>
                                        {templatePreview && templatePreview.length > 0 ? (
                                            templatePreview.map((component) => (

                                                <React.Fragment key={component.type}>
                                                    {component.type === "HEADER" && (
                                                        component.format === 'TEXT' ? (
                                                            <p className='headeretxt'>{component.text}</p>
                                                        ) : component.format === 'IMAGE' ? (
                                                            <div className='previewContent'><img alt="Header" src={component.example.header_handle[0]} style={{ height: '100%', width: 'auto', objectFit: 'cover' }} /></div>
                                                        ) : component.format === 'VIDEO' ? (
                                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                        ) : component.format === 'DOCUMENT' ? (
                                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                        ) : (
                                                            <p>Unsupported format</p>
                                                        )
                                                    )}
                                                    {component.type === "BODY" && (
                                                        <div className='bodytext'>{component.text}</div>
                                                    )}
                                                    {component.type === "FOOTER" && (
                                                        <div className='footertext'>{component.text}</div>
                                                    )}{component.type === "BUTTONS" && (
                                                        <React.Fragment>
                                                            <hr className='buttonhr' />
                                                            {component.buttons.map((button) => (
                                                                <div key={button.id}>
                                                                    {
                                                                        button.type === 'QUICK_REPLY' ? (
                                                                            <>
                                                                                <div className='quickreply'>
                                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                        {button.text}
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    {
                                                                        button.type === 'URL' ? (
                                                                            <>
                                                                                <div className='urlbtn'>
                                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                                        <LinkOutlined />
                                                                                        {button.text}</a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    {
                                                                        button.type === 'PHONE_NUMBER' ? (
                                                                            <>
                                                                                <div className='phonebtn'>
                                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                        <PhoneOutlined />
                                                                                        {button.text}</a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                    )}

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <p>Please Select the Template.</p>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Drawer >
        </>
    );
};
export default ChatModule;