import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    useReactFlow,
    Background
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Row, Col, Typography, Input, Switch, Button, Flex } from 'antd';
import { FcFlowChart } from "react-icons/fc";
import AutoSide from '../AutoSide';
import InitialFlow from './InitialFlow';
import TextButtonNode from './TextButtonNode';
import ButtonEdge from './ButtonEdge';


let id = 0;
const getId = () => `dndnode_${id++}`;

const { Title } = Typography;

const edgeTypes = {
    button: ButtonEdge,
};

const nodeClassName = (node) => node.type;

const nodeTypes = { initialFlow: InitialFlow, textButton: TextButtonNode, };

const DnDFlow = ({ userData, sessionData, storeData }) => {
    const reactFlowWrapper = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    const [inputBoxes, setInputBoxes] = useState([{ id: 1, value: '' }]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [nodeData, setNodeData] = useState([]);
    const [flowName, setFlowName] = useState('');
    const [flowStatus, setFlowStatus] = useState(false);
    const [loadingDraft, setLoadingDraft] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [inFlow, setInflow] = useState('');

    const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

    const onCloseIconClick = useCallback((nodeId) => {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const item = JSON.parse(event.dataTransfer.getData('application/reactflow'));
            if (typeof item === 'undefined' || !item) {
                return;
            }
            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            const id = getId();
            const isFirstNode = nodes.length === 0;
            const newNodeType = isFirstNode ? 'input' : 'default';
            let nodetype = 'textButton';

            const newNode = {
                id,
                type: nodetype,
                position,
                data: {
                    label: `${item.label}`,
                    onAddButton: handleAddButton,
                    onClose: onCloseIconClick,
                    id,
                    nodeData: nodeData
                }
            };
            setNodes((nds) => nds.concat(newNode));
            
        }, [screenToFlowPosition, nodes.length]);

    const handleAddButton = useCallback((nodeId) => {
        setNodes((prevNodes) => {
            const updatedNodes = prevNodes.map((node) => {
                if (node.id === nodeId) {
                    const buttons = node.data.buttons || [];

                    if (buttons.length >= 3) {
                        return node;
                    }

                    const newButton = {
                        id: getId(),
                        label: 'Button Label',
                    };

                    const updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            buttons: [...buttons, newButton],
                        },
                    };

                    return updatedNode;
                }
                return node;
            });

            return updatedNodes;
        });
    }, []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const handleInputChange = useCallback((e, id) => {

    }, []);

    const handleAddInputBox = useCallback(() => {
        const lastId = inputBoxes[inputBoxes.length - 1].id;
        setInputBoxes((prevInputBoxes) => [
            ...prevInputBoxes,
            { id: lastId + 1, value: '' }
        ]);
    }, [inputBoxes]);

    const handleDelete = useCallback((id) => {
        setInputBoxes((prevInputBoxes) => prevInputBoxes.filter((box) => box.id !== id));
    }, []);

    useEffect(() => {
        const initialNode = {
            id: getId(),
            type: 'initialFlow',
            position: { x: 100, y: 100 },
            data: {
                label: 'Flow Start',
                handleInputChange,
                handleAddInputBox,
                handleDelete,
                inputBoxes,
                inFlow,
                id
            },
        };
        setNodes((nds) => nds.concat(initialNode));
    }, []);

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ borderBottom: '1px solid #ddd', padding: 16 }}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={16}>
                            <Flex align='center' justify='flex-start' gap={8}>
                                <FcFlowChart size={24} /> <Title level={3} style={{ margin: 0 }}>Flow builder</Title>
                            </Flex>
                            <Flex gap={16} align='center' justify='flex-start'>
                                <Input value={flowName} onChange={(e) => setFlowName(e.target.value)} style={{ maxWidth: 200 }} placeholder='Enter flow name' />
                                <Switch checkedChildren="Active" unCheckedChildren="Inactive" checked={flowStatus} onChange={() => setFlowStatus(!flowStatus)} style={{ width: 100 }} />
                                <Button disabled={loadingSave} loading={loadingDraft}>Save changes</Button>
                                <Button disabled={loadingDraft} loading={loadingSave} type='primary'>Save and exit</Button>
                                <Button disabled={loadingDraft} loading={loadingSave} type='primary' danger>Exit</Button>
                            </Flex>
                        </Flex>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={5}>
                    <AutoSide />
                </Col>
                <Col span={19}>
                    <div ref={reactFlowWrapper} style={{ height: '99%' }}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            attributionPosition="top-right"
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            fitView={false}
                            snapToGrid={true}
                            className="overview"
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                        >
                            <Controls />
                            <Background />
                        </ReactFlow>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const FlowBuilder = ({ userData, sessionData, storeData }) => {
    return (
        <ReactFlowProvider>
            <DnDFlow userData={userData} sessionData={sessionData} storeData={storeData} />
        </ReactFlowProvider>
    );
}

export default FlowBuilder;