import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
const { Paragraph, Title } = Typography;
const Team = () => {
    return (
        <Layout className='contentLayout'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Team</b></Title>
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <Paragraph></Paragraph>
                </Col>
            </Row>
        </Layout>
    );
};
export default Team;