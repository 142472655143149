import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import wlogo from '../assets/Zipler-logo.png';
import blogo from '../assets/zblogo.png';
import { Form, Input, Button, Typography, Layout, Row, Col, Image, Flex, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { parsePhoneNumber } from 'libphonenumber-js';
import { v4 as uuidv4 } from 'uuid';
import '../login.css';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2';
import welcomeImg from '../assets/welcome.png';
import { GiLaptop } from "react-icons/gi";
import { PiArrowLeftDuotone } from 'react-icons/pi';
import { BsBalloonHeart, BsChatLeftHeart, BsRobot, BsWhatsapp } from 'react-icons/bs';
const { Link, Title, Text, Paragraph } = Typography;

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    let deviceType, deviceOS;

    if (/android/i.test(userAgent)) {
        deviceType = 'Android';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/Android\s([0-9\.]+)/);
        deviceOS = match ? `Android ${match[1]}` : 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        deviceType = 'iOS';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/OS\s([0-9\._]+)/);
        deviceOS = match ? `iOS ${match[1].replace(/_/g, '.')}` : 'iOS';
    } else if (/Windows NT/.test(userAgent)) {
        deviceType = 'Windows';
        // eslint-disable-next-line no-useless-escape
        const match = userAgent.match(/Windows NT\s([0-9\.]+)/);
        deviceOS = match ? `Windows ${match[1].replace(/_/g, '.')}` : 'Windows';
    } else {
        deviceType = 'Web';
        deviceOS = 'N/A';
    }
    return { deviceType, deviceOS };
};

const RegisterForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [regSteps, setRegStaps] = useState(1);
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60);
    const [resendEnabled, setResendEnabled] = useState(false);
    const [fullPhone, setFullPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [name, setName] = useState('');

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (values) => {
        // console.log(values.phone_no);
        // const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        //     'size': 'invisible',
        //     'callback': (response) => {
        //         console.log(response);
        //     },
        //     'expired-callback': {

        //     }
        // });
        const phoneNumber = parsePhoneNumber(`+${values.phone_no}`);
        console.log(phoneNumber);
        if (!phoneNumber) {
            message.error('Phone number is invalid');
            console.error('Invalid phone number');
            return;
        }
        setLoading(true);
        setFullPhone(`+${phoneNumber?.countryCallingCode}-${phoneNumber?.nationalNumber}`);
        const url = `${window.API_BASE_URL}/auth/register`;
        const requestBody = {
            user_name: values.fullname,
            user_email: values.email,
            user_phone: phoneNumber?.nationalNumber,
            user_country_code: `+${phoneNumber?.countryCallingCode}`,
            user_password: values.password
        };

        console.log(requestBody);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('Response:', data);

            if (response.ok) {
                if (data.success === true) {
                    // const confirmationResult = await signInWithPhoneNumber(auth, `+${values.phone_no}`, recaptchaVerifier);
                    // setConfirmationResult(confirmationResult);
                    setEmail(values.email);
                    setName(values.fullname);
                    setPassword(values.password);
                    setRegStaps(2);
                    setTimer(60);
                    setResendEnabled(false);
                } else {
                    message.success('Registration Failed');
                    console.error('Registration failed:', data.message);
                }

            } else {
                console.error('Registration failed:', data.message);
                message.success('Registration Failed');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        setLoading(true);
        const url = `${window.API_BASE_URL}/auth/resend-otp`;
        const requestBody = {
            user_name: name,
            user_email: email,
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('Response:', data);
            if (response.ok) {
                if (data.success === true) {
                    setTimer(60);
                    setResendEnabled(false);
                    message.success('OTP resent successfully!');
                }
            } else {
                message.error('Error Resending OTP. Please try again.');
            }

        } catch (error) {
            message.error('Error Resending OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let interval = null;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setResendEnabled(true);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleOtpSubmit = async () => {
        console.log(fullPhone);
        setLoading(true);
        if (!otp) {
            message.error('Please enter the OTP.');
            return;
        }
        const url = `${window.API_BASE_URL}/auth/verify-otp`;
        const requestBody = {
            user_name: name,
            user_email: email,
            user_otp: otp
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('Response:', data);
            if (response.ok) {
                if (data.success === true) {
                    message.success('Otp Verified successful!');
                    await doLogin();
                }
            } else {
                message.error('Invalid OTP. Please try again.');
            }

        } catch (error) {
            message.error('Invalid OTP. Please try again.');
        }
    };

    const handleSubmitFailed = () => {

    };

    const doLogin = async () => {
        console.log('email', email);
        console.log('password', password);
        const url = `${window.API_BASE_URL}/auth/login`;
        const deviceId = uuidv4();
        const { deviceType, deviceOS } = getDeviceInfo();

        const requestBody = {
            identifier: email,
            user_password: password,
            device_id: deviceId,
            device_type: deviceType,
            device_os: deviceOS
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            console.log('Response:', data);

            if (response.ok) {
                if (data.success === true) {
                    message.success('Login Successfull');
                    setTimeout(() => {
                        localStorage.setItem('loginToken', data.token);
                        localStorage.setItem('deviceId', deviceId);
                        navigate('/');
                    }, 300);
                } else {
                    message.success('Login Failed');
                    console.error('Login failed:', data.message);
                }

            } else {
                console.error('Login failed:', data.message);
                message.success('Login Failed');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const onChange = (text) => {
        console.log('onChange:', text);
        setOtp(text);
    };

    const sharedProps = {
        onChange,
    };

    return (
        <Layout>
            <Row>
                <Col sm={24} md={12} lg={12}>
                    <div className='register-right-side phonereg'>
                        {regSteps === 1 && (
                            <div className='reg-right-main-div'>
                                <Flex justify='center' align='center' style={{ marginTop: 24 }} className='on-phone'>
                                    <div className='main-reg-logo'>
                                        <Image src={blogo} preview={false} className='reg-logo' />
                                    </div>
                                </Flex>
                                <div className='reg-right-main-title-div'>
                                    <Title className='reg-right-main-title' level={2}><span>Get Started Now!</span></Title>
                                    <Paragraph className='reg-right-sub-title'>Take the first step towards growing your business</Paragraph>
                                </div>
                                <Form name="register_form" onFinish={handleSubmit} onFinishFailed={handleSubmitFailed} layout="vertical">
                                    <Form.Item
                                        name="fullname"
                                        label="Full Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your Name',
                                            },
                                        ]}
                                    >
                                        <Input autoComplete='username' placeholder="First & last name" />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your Email.',
                                            },
                                        ]}
                                    >
                                        <Input autoComplete='email' placeholder="Enter your email" />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone_no"
                                        label="Mobile Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Mobile Number is required.',
                                            },
                                        ]}
                                    >
                                        <PhoneInput type='text' country={'in'} enableSearch={true} inputStyle={{ width: '100%' }} placeholder='Enter mobile number' />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Password is required',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            autoComplete="current-password"
                                            placeholder="Create a safe password"
                                            iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                            onClick={togglePasswordVisibility}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ textAlign: 'center' }}>
                                        <Button type="primary" htmlType="submit" block loading={loading} style={{ height: 44, fontWeight: 500, marginTop: 24 }}>
                                            {!loading && 'Sign up today for free'}
                                        </Button>
                                        <Paragraph style={{ fontSize: 12, marginTop: 12, marginBottom: 12 }}>By signing up, You agree to our <Link href='#'>Privacy Policy</Link> and <Link href='#'>Terms of Service.</Link></Paragraph>
                                        <Text>Already have an account? <Link href="/login" style={{ fontWeight: 'bold' }}>Sign in</Link></Text>
                                    </Form.Item>
                                </Form>
                                <div id="recaptcha-container"></div>
                            </div>
                        )}
                        {regSteps === 2 && (
                            <div className='reg-right-main-div'>
                                <Flex gap='small' align='center' onClick={() => setRegStaps(1)} className='reg-back-btn'>
                                    <Button shape="round" className='close-btn'><PiArrowLeftDuotone /></Button>
                                    <Paragraph style={{ margin: 0 }}>Back</Paragraph>
                                </Flex>
                                <div className='reg-right-main-title-div'>
                                    <Title className='reg-right-main-title' level={2}>Just a step away,<br />Verify account</Title>
                                    <Paragraph className='reg-right-sub-title'>Please enter a 6 digit OPT, sent to<br /><sapn style={{ fontSize: 18, color: '#000' }}>{email} <Button type='link' style={{ margin: 0, padding: 0, color: '#3A1A55', fontSize: 18 }} onClick={() => setRegStaps(1)}>edit</Button></sapn></Paragraph>
                                </div>
                                <Form layout="vertical">
                                    <Form.Item label="OTP" className='otp-input'>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            renderSeparator={<span></span>}
                                            renderInput={(props) => <input {...props} type='tel' inputMode="numeric" className='ant-input css-dev-only-do-not-override-3a2mmf ant-input-outlined ant-otp-input' />}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ textAlign: 'center' }}>
                                        <Button type="primary" onClick={handleOtpSubmit} block loading={loading}>
                                            Verify OTP
                                        </Button>
                                        <Text>Didn't receive OTP on email? <Button type='link' loading={loading} style={{ margin: 0, padding: 0, color: '#3A1A55' }} disabled={!resendEnabled} onClick={handleResendOtp}>{timer === 0 ? 'Resend' : `Resend in ${timer}`}</Button></Text>
                                    </Form.Item>
                                </Form>
                                <div id="recaptcha-container"></div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col span={12} className='hidden-on-phone'>
                    <div className='register-left-side'>
                        <Flex justify='center' align='center' style={{ marginTop: 24 }}>
                            <div className='main-reg-logo'>
                                <Image src={wlogo} preview={false} className='reg-logo' />
                            </div>
                        </Flex>
                        <Flex gap={16} align='center'>
                            <Image src={welcomeImg} preview={false} className='reg-welcome-image' />
                            <Title level={4} className='reg-main-title'>Grow your business with best Customer Retention Platform</Title>
                        </Flex>
                        <div className='reg-content'>
                            <ul className='reg-content-ul'>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <GiLaptop size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Integrate POS</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>Seamlessly plug and play with any POS</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsBalloonHeart size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Activate loyalty program</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>What if Zipler.io tell you that you can turn your one-time buyers into loyal customers? Yes! With Zipler.io you can launch loyalty programs</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsWhatsapp size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Send Campaigns</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>What if Zipler.io tell you: “You have 3,177 high-spending customers who visited 3+ times every month but haven’t returned in last 3 months. Send them a campaign now to win them back!</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsRobot size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Autopilot Campaigns</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>An option to send a series of marketing messages based on key events like their first purchase date, Point Earned, Referrals, their birthday, or if they’re at risk of not coming back.</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                                <li>
                                    <Flex justify='start' align='center' gap={16}>
                                        <div>
                                            <BsChatLeftHeart size={24} />
                                        </div>
                                        <div className='reg-sub-list-content'>
                                            <Title level={4} style={{ margin: 0, color: '#fff' }}>Activate Feedback</Title>
                                            <Paragraph style={{ margin: 0, color: '#fff' }}>See your customer's compliments and suggestion easily to boost sales and satisfaction. Get instant alerts for negative feedback on WhatsApp to resolve issues promptly.</Paragraph>
                                        </div>
                                    </Flex>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default RegisterForm;