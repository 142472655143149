import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Radio, Typography, Divider, Switch, message, Avatar, Image, Upload, Tooltip, Flex, Spin, Input, Select, Button } from 'antd';
import { WhatsAppOutlined, ShopOutlined, PlusOutlined, InfoCircleFilled, LoadingOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const WhatsappSettings = ({ userData, sessionData, storeData, socketData }) => {
    const [profileDetails, setProfileDetails] = useState([]);
    const [complianceDetails, setComplianceDetails] = useState([]);
    const [accountDetails, setAccountDetails] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [file, setFile] = useState([]);
    const [profilePicId, setProfilePicId] = useState('');
    const [loading, setLoading] = useState(true);
    const [profileLoading, setProfileLoading] = useState(false);
    const [complaintsLoading, setComplaintsLoading] = useState(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => setFile(newFileList);

    const handleUpdateProfile = async () => {
        setProfileLoading(true);
        const url = `https://graph.facebook.com/v21.0/${userData?.phonenumberid}/whatsapp_business_profile`;
        const data = {
            messaging_product: "whatsapp",
            about: profileDetails?.about || '',
            address: profileDetails?.address || '',
            email: profileDetails?.email || '',
            description: profileDetails?.description || '',
            vertical: profileDetails?.vertical || '',
            websites: profileDetails?.websites || [],
        };
        if (profilePicId !== '') {
            data.profile_picture_handle = profilePicId;
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userData?.whatsappAccessToken}`,
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Success:', result);
            message.success('Updated Successfully.');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setProfileLoading(false);
        }
    };


    const handleSubmitComplineInfo = async () => {
        setComplaintsLoading(true);
        const url = `https://graph.facebook.com/v21.0/${userData?.phonenumberid}/business_compliance_info`;
        const data = {
            messaging_product: "whatsapp",
            entity_name: complianceDetails?.entity_name || '',
            entity_type: complianceDetails?.entity_type || '',
            is_registered: complianceDetails?.is_registered || false,
            grievance_officer_details: {
                name: complianceDetails?.grievance_officer_details?.name || '',
                email: complianceDetails?.grievance_officer_details?.email || '',
                landline_number: complianceDetails?.grievance_officer_details?.landline_number || '',
                mobile_number: complianceDetails?.grievance_officer_details?.mobile_number || ''
            },
            customer_care_details: {
                email: complianceDetails?.customer_care_details?.email || '',
                landline_number: complianceDetails?.customer_care_details?.landline_number || '',
                mobile_number: complianceDetails?.customer_care_details?.mobile_number || ''
            }
        };
        if (complianceDetails?.entity_type === 'OTHER') {
            data.entity_type_custom = complianceDetails?.entity_type_custom || '';
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userData?.whatsappAccessToken}`,
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Success:', result);
            message.success('Updated Successfully.');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setComplaintsLoading(false);
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        fetchData(userData);
    }, [userData]);

    const fetchData = async (userData) => {
        const url = `${window.API_BASE_URL}/chats/get-whatsapp-business-profile`;
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: JSON.stringify({
                    'whatsappaccesstoken': userData?.whatsappAccessToken,
                    'phonenumberid': userData?.phonenumberid
                })
            };
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if (data.success) {
                console.log(data);
                setProfileDetails(data?.businessProfile?.data?.[0]);
                setComplianceDetails(data?.complianceInfo?.data[0]);
                setAccountDetails(data?.accountInfo);
                if (data?.businessProfile?.data?.[0]?.profile_picture_url) {
                    setFile([{
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: data?.businessProfile?.data?.[0]?.profile_picture_url,
                    }])
                }
            } else {
                console.error('Failed to fetch data:', data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const customRequest = async (fileInfo) => {
        try {
            const formData = new FormData();
            formData.append('header_file', fileInfo.file);
            formData.append('accessToken', userData?.whatsappAccessToken);
            formData.append('wappid', userData?.waappid);
            const fileType = fileInfo.file.type;
            console.log(fileType);
            const response = await fetch(fileInfo.action, {
                method: 'POST',
                body: formData,
                headers: {
                    ...fileInfo.headers,
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const responseData = await response.json();
            console.log(responseData);
            if (fileType === 'image/jpeg' || fileType === 'image/png') {
                setProfilePicId(responseData.filePath.h);
            }
            fileInfo.onSuccess(responseData, fileInfo.file);
        } catch (error) {
            console.log(error);
            fileInfo.onError(error);
        }
    };

    const beforeUpload = (file) => {
        console.log(file);
        const isFileSizeValid = file.size / 1024 / 1024 < 5;
        const isFileTypeValid = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';

        if (!isFileSizeValid) {
            message.error('File must be smaller than 5MB!');
        }
        if (!isFileTypeValid) {
            message.error('You can only upload JPG/PNG file!');
        }
        return isFileSizeValid && isFileTypeValid;
    };

    return (
        <Row>
            <Col span={24}>
                {loading ? (
                    <Flex align="center"
                        justify="center"
                        style={{ minHeight: '100vh' }}>
                        <Spin size="large" />
                    </Flex>
                ) : (
                    <Typography>
                        <Title level={4}> <WhatsAppOutlined /> Your Business Account Appearance</Title>
                        <Text>Easily configure your WhatsApp Business Profile appearance. (Note: WhatsApp Business number and display name can not be changed once approved)</Text>
                        <p>
                            <b>Display Name : </b> {accountDetails?.verified_name}<br />
                            <b>Display Phone No. : </b> {accountDetails?.display_phone_number}
                        </p>
                        <Col span={24}>
                            <div style={{ paddingTop: 20, textAlign: 'center' }}>
                                <Flex justify="center" style={{ paddingTop: 20 }}>
                                    <Upload
                                        accept=".jpeg,.png"
                                        action={`${window.API_BASE_URL}/templates/upload-media`}
                                        listType="picture-circle"
                                        fileList={file}
                                        customRequest={(fileInfo) => customRequest(fileInfo)}
                                        beforeUpload={beforeUpload}
                                        onPreview={handlePreview}
                                        onChange={handleChange}

                                        maxCount={1}
                                    >
                                        {file.length === 0 ? uploadButton : null}
                                    </Upload>
                                </Flex>
                                <Title level={5}>Business Logo <Tooltip title="Upload your logo in Square image with max edge of 640px and max size 5mb is recommended. Images with a height or width less than 192px may cause issues when resizing occurs."><InfoCircleFilled style={{ top: '2px' }} /></Tooltip></Title>
                                <p>Logo will appear as the profile picture for your business</p>
                            </div>
                            <Row gutter={6}>
                                <Col span={8}>
                                    <Typography.Title level={5}>About (Optional)</Typography.Title>
                                    <TextArea
                                        value={profileDetails?.about}
                                        onChange={(e) => setProfileDetails(prev => ({
                                            ...prev,
                                            about: e.target.value
                                        }))}
                                        placeholder="Enter About your business."
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                        showCount maxLength={139}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Description (Optional)</Typography.Title>
                                    <TextArea
                                        value={profileDetails?.description}
                                        onChange={(e) => setProfileDetails(prev => ({
                                            ...prev,
                                            description: e.target.value
                                        }))}
                                        placeholder="Enter Description of your business."
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                        showCount maxLength={512}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Address (optional)</Typography.Title>
                                    <TextArea
                                        value={profileDetails?.address}
                                        onChange={(e) => setProfileDetails(prev => ({
                                            ...prev,
                                            address: e.target.value
                                        }))}
                                        placeholder="Enter your business address."
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                        showCount maxLength={256}
                                    />
                                </Col>
                                <Col span={4}>
                                    <Typography.Title level={5}>Category <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Select
                                        showSearch
                                        placeholder="Select Category"
                                        optionFilterProp="label"
                                        onChange={(value) => setProfileDetails(prev => ({
                                            ...prev,
                                            vertical: value
                                        }))}
                                        value={profileDetails?.vertical}
                                        style={{ width: '100%' }}
                                        options={[
                                            {
                                                value: 'UNDEFINED',
                                                label: 'Undefined',
                                            },
                                            {
                                                value: 'OTHER',
                                                label: 'Other',
                                            },
                                            {
                                                value: 'AUTO',
                                                label: 'Auto',
                                            },
                                            {
                                                value: 'BEAUTY',
                                                label: 'Beauty',
                                            },
                                            {
                                                value: 'APPAREL',
                                                label: 'Apparel',
                                            },
                                            {
                                                value: 'EDU',
                                                label: 'Edu',
                                            },
                                            {
                                                value: 'ENTERTAIN',
                                                label: 'Entertain',
                                            },
                                            {
                                                value: 'EVENT_PLAN',
                                                label: 'Event Plan',
                                            },
                                            {
                                                value: 'FINANCE',
                                                label: 'Finance',
                                            },
                                            {
                                                value: 'GROCERY',
                                                label: 'Grocery',
                                            },
                                            {
                                                value: 'GOVT',
                                                label: 'Govt.',
                                            },
                                            {
                                                value: 'HOTEL',
                                                label: 'Hotel',
                                            },
                                            {
                                                value: 'HEALTH',
                                                label: 'Health',
                                            },
                                            {
                                                value: 'NONPROFIT',
                                                label: 'Non Profit',
                                            },
                                            {
                                                value: 'PROF_SERVICES',
                                                label: 'Prof Services',
                                            },
                                            {
                                                value: 'RETAIL',
                                                label: 'Retail',
                                            },
                                            {
                                                value: 'TRAVEL',
                                                label: 'Travel',
                                            },
                                            {
                                                value: 'RESTAURANT',
                                                label: 'Restaurant',
                                            },
                                            {
                                                value: 'NOT_A_BIZ',
                                                label: 'Not a Business',
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}>Email (Optional)</Typography.Title>
                                    <Input
                                        onChange={(e) => setProfileDetails(prev => ({
                                            ...prev,
                                            email: e.target.value
                                        }))}
                                        placeholder="Enter Email address"
                                        value={profileDetails?.email} />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}>Website 1 (Optional)</Typography.Title>
                                    <Input
                                        showCount
                                        maxLength={256}
                                        onChange={(e) => setProfileDetails(prev => {
                                            const updatedWebsites = [...(prev.websites || [])];
                                            updatedWebsites[0] = e.target.value;
                                            return { ...prev, websites: updatedWebsites };
                                        })}
                                        value={profileDetails?.websites?.[0]} />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}>Website 2 (Optional)</Typography.Title>
                                    <Input
                                        showCount
                                        maxLength={256}
                                        onChange={(e) => setProfileDetails(prev => {
                                            const updatedWebsites = [...(prev.websites || [])];
                                            updatedWebsites[1] = e.target.value;
                                            return { ...prev, websites: updatedWebsites };
                                        })}
                                        value={profileDetails?.websites?.[1]} />
                                </Col>

                                <Col span={2}>
                                    <Typography.Title level={5}>&nbsp;</Typography.Title>
                                    {profileLoading ? (
                                        <Button type="primary" style={{ width: '100%' }}><Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} /></Button>
                                    ) : (
                                        <Button type="primary" style={{ width: '100%' }} onClick={handleUpdateProfile}>Update</Button>
                                    )}

                                </Col>
                            </Row>
                            <Divider className='darkDevider' />
                            <Row gutter={6}>
                                <Col span={24}><Title level={4}>Compliance Info</Title><p>Mandatory for sending catalog messages. Please fill all fields</p></Col>
                                <Col span={6}>
                                    <Typography.Title level={5}>Legal name of business  <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            entity_name: e.target.value
                                        }))}
                                        placeholder="Enter name of business"
                                        value={complianceDetails?.entity_name} />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}>Business Type <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Select
                                        showSearch
                                        placeholder="Select Business type"
                                        optionFilterProp="label"
                                        onChange={(value) => setComplianceDetails(prev => ({
                                            ...prev,
                                            entity_type: value
                                        }))}
                                        value={complianceDetails?.entity_type}
                                        style={{ width: '100%' }}
                                        options={[
                                            {
                                                value: 'LIMITED_LIABILITY_PARTNERSHIP',
                                                label: 'Limited Libility Partnership',
                                            },
                                            {
                                                value: 'SOLE_PROPRIETORSHIP',
                                                label: 'Sole Proprietorship',
                                            },
                                            {
                                                value: 'PARTNERSHIP',
                                                label: 'Partnership',
                                            },
                                            {
                                                value: 'PUBLIC_COMPANY',
                                                label: 'Public Company',
                                            },
                                            {
                                                value: 'PRIVATE_COMPANY',
                                                label: 'Private Company',
                                            },
                                            {
                                                value: 'OTHER',
                                                label: 'Other',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}> Describe Business Type  <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            entity_type_custom: e.target.value
                                        }))}
                                        placeholder="Describe Business type"
                                        value={complianceDetails?.entity_type_custom} />
                                </Col>
                                <Col span={6}>
                                    <Typography.Title level={5}> Is your business registered?  <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Radio.Group onChange={(e) => setComplianceDetails(prev => ({
                                        ...prev,
                                        is_registered: e.target.value
                                    }))} value={complianceDetails?.is_registered}>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={24}><Title level={4}>Customer Care Information</Title><p>Enter the contact information of the individual who will respond to and resolve the inquiries from your customers, such as returns or refunds</p></Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Email <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            customer_care_details: {
                                                ...prev?.customer_care_details,
                                                email: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.customer_care_details?.email} />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Mobile No. <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            customer_care_details: {
                                                ...prev?.customer_care_details,
                                                mobile_number: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.customer_care_details?.mobile_number ? complianceDetails?.customer_care_details?.mobile_number : accountDetails?.display_phone_number} />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Landline No. <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            customer_care_details: {
                                                ...prev?.customer_care_details,
                                                landline_number: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.customer_care_details?.landline_number} />
                                </Col>
                                <Col span={24}><Title level={4}>Grievance Officer Information</Title><p>Enter the contact information of the individual who will respond to and resolve any complaints from your customers</p></Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Name <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            grievance_officer_details: {
                                                ...prev?.grievance_officer_details,
                                                name: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.grievance_officer_details?.name} />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Email <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            grievance_officer_details: {
                                                ...prev?.grievance_officer_details,
                                                email: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.grievance_officer_details?.email} />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Mobile No. <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            grievance_officer_details: {
                                                ...prev?.grievance_officer_details,
                                                mobile_number: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.grievance_officer_details?.mobile_number} />
                                </Col>
                                <Col span={8}>
                                    <Typography.Title level={5}>Landline No. <span style={{ color: 'red' }}>*</span></Typography.Title>
                                    <Input
                                        onChange={(e) => setComplianceDetails(prev => ({
                                            ...prev,
                                            grievance_officer_details: {
                                                ...prev?.grievance_officer_details,
                                                landline_number: e.target.value
                                            }
                                        }))}
                                        value={complianceDetails?.grievance_officer_details?.landline_number} />
                                </Col>
                                <Col span={2}>
                                    <Typography.Title level={5}>&nbsp;</Typography.Title>
                                    {complaintsLoading ? (
                                        <Button type="primary" style={{ width: '100%' }}><Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} /></Button>
                                    ) : (
                                        <Button type="primary" style={{ width: '100%' }} onClick={handleSubmitComplineInfo}>Update</Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Typography>
                )}
                {previewImage && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </Col>
        </Row >
    );
};

export default WhatsappSettings;