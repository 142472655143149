import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBl6fNdjQ0M-dv9lZwsBnLNSpgaufe54Dg",
    authDomain: "zipler-865dd.firebaseapp.com",
    projectId: "zipler-865dd",
    storageBucket: "zipler-865dd.appspot.com",
    messagingSenderId: "286984142063",
    appId: "1:286984142063:web:1f0fe8e807adfffe666f1a",
    measurementId: "G-YTCXDF7VKS"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);

export { auth, messaging, getToken, onMessage };