import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.zipler.io/api';
window.API_BASE_URL = API_BASE_URL;

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api.zipler.io/';
window.BASE_URL = BASE_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
