import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Button, Result, Flex } from 'antd';
import { PiLaptopDuotone } from 'react-icons/pi';
const PhonePage = ({ messageApi }) => {
    const navigate = useNavigate();
    const key = 'updatable';

    const handleMenuClick = async () => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        const url = `${window.API_BASE_URL}/auth/logout`;
        const token = localStorage.getItem('loginToken');
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log(response);
            if (response.ok) {
                setTimeout(() => {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: 'Logout Successful!',
                        duration: 2,
                    });
                    setTimeout(() => {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        navigate('/login');
                    }, 300);
                }, 1000);
            } else {
                const data = await response.json();
                console.error('Logout failed:', data.message);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <Layout className='contentLayout'>
            <Flex justify='center' align='center' style={{ height: '100dvh', width: '100dvw' }}>
                <Result
                    icon={<PiLaptopDuotone size={200} color='#3a1a55'/>}
                    title="Great! Next, log in to your laptop or desktop."
                    subTitle="Please proceed by logging in to your laptop or desktop and complete the set up process."
                    extra={<Button type="primary" onClick={handleMenuClick}>Logout</Button>}
                />
            </Flex>
        </Layout>
    );
};
export default PhonePage;