import React, { useState } from 'react';
import { Layout, Row, Col, Typography, Button, Flex, Drawer } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import InboxSettings from './InboxSettings';
import CustomAutoReply from './CustomAutoReply';
import FlowBuilder from './FlowBuilder';
import Flow from './Flow';
import { PiXCircleDuotone } from 'react-icons/pi';
const { Paragraph, Title } = Typography;
const Automation = ({ userData, sessionData, storeData }) => {
    const { segmentType, subsegmentType } = useParams();
    const [openFlowBuilder, setOpenFlowBuilder] = useState(subsegmentType === 'builder');
    const navigate = useNavigate();

    const handleSegmentClick = (segmentType) => {
        navigate(`/automation/${segmentType}`);
    };

    const handleCloseFlowBuilder = () => {
        navigate(`automation/flow`);
        setOpenFlowBuilder(false);
    };

    return (
        <Layout className='contentLayout'>
            <div style={{ marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Automation</b></Title>
                <Paragraph style={{ color: '#697777' }}>Effortlessly automate your communication and streamline customer interactions.</Paragraph>
            </div>
            <Row gutter={16}>
                <Col span={24}>
                    <Flex gap='middle'>
                        <Button type={!segmentType ? 'primary' : segmentType === 'inbox-settings' && 'primary'} onClick={() => handleSegmentClick('inbox-settings')} shape="round" size='large'>Inbox settings</Button>
                        <Button type={segmentType === 'custom-auto-reply' && 'primary'} onClick={() => handleSegmentClick('custom-auto-reply')} shape="round" size='large'>Custom auto reply</Button>
                        <Button type={segmentType === 'flow' && 'primary'} onClick={() => handleSegmentClick('flow')} shape="round" size='large'>Flow builder</Button>
                    </Flex>
                </Col>
            </Row>
            {!segmentType || segmentType === 'inbox-settings' ? (
                <InboxSettings userData={userData} sessionData={sessionData} storeData={storeData} />
            ) : segmentType === 'custom-auto-reply' ? (
                <CustomAutoReply userData={userData} sessionData={sessionData} storeData={storeData} />
            ) : segmentType === 'flow' && (
                <Flow userData={userData} sessionData={sessionData} storeData={storeData} />
            )}
            <Drawer className='flow-drawer' placement='bottom' height={'100vh'} closable={false} onClose={handleCloseFlowBuilder} open={openFlowBuilder}>
                <FlowBuilder userData={userData} sessionData={sessionData} storeData={storeData} />
            </Drawer>
        </Layout>
    );
};
export default Automation;