import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Spin, Result, Button, Flex } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import confety from '../assets/e4d2c1d0da356797359acd9270bcdd77.gif';
const PaymentStatus = ({ userData, sessionData }) => {
    const navigate = useNavigate();
    const { txnId } = useParams();
    const [transactinData, setTransactionData] = useState();
    const [loading, setLoading] = useState(true);

    const fetchTransactionData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/payment/status/${txnId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setLoading(false);
                setTransactionData(data?.transactionData);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                    // navigate('/login');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching store drops:', error);
        }
    }, [sessionData?.token, txnId]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await fetchTransactionData();
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, [fetchTransactionData, sessionData]);

    return (
        loading ? (
            <Layout className='contentLayout' >
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Layout >
        ) : (
            <Layout>
                {transactinData?.code === 'PAYMENT_SUCCESS' ? (
                    <Flex gap='middle' justify='center' align='center' style={{ width: '100vw', height: '100vh', backgroundColor: '#fcfefc' }}>
                        <div className='confety'>
                            <img src={confety} alt='success'/>
                        </div>
                        <Result
                            status="success"
                            title="Payment Successfully"
                            subTitle={<>Transaction id : <strong style={{ color: '#000' }}>{txnId}</strong>, Thank you, Credits transferred to your account.</>}
                            extra={[
                                <Button type="primary" onClick={() => navigate('/', { replace: true })}>
                                    Go to Home
                                </Button>,
                                <Button onClick={() => navigate('/channels', { replace: true })}>Buy More Credit</Button>,
                            ]}
                        />
                    </Flex>
                ) : transactinData?.code === 'PAYMENT_ERROR' && (
                    <Flex gap='middle' justify='center' align='center' style={{ width: '100vw', height: '100vh' }}>
                        <Result
                            status="error"
                            title="Payment Failed"
                            subTitle={<>Transaction id : <strong style={{ color: '#000' }}>{txnId}</strong>, Oops!, Don't worry your money is safe. If money was debited from your account, it will be refunded automatically in 5-7 working days.</>}
                            extra={[
                                <Button type="primary" onClick={() => navigate('/', { replace: true })}>
                                    Go to Home
                                </Button>,
                                <Button onClick={() => navigate('/channels', { replace: true })}>Retry again</Button>,
                            ]}
                        />
                    </Flex>
                )}
            </Layout>
        )
    );
};
export default PaymentStatus;