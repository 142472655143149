import React from 'react';
import { Button, Flex, Tooltip, Typography } from 'antd';
import {
    SnippetsOutlined,
    UserAddOutlined,
    TagOutlined,
    SmileOutlined,
    FormOutlined,
    ShoppingOutlined,
    ShopOutlined,
    PictureOutlined,
    ApiOutlined,
    EnvironmentOutlined,
    QuestionCircleOutlined,
    VideoCameraOutlined,
    FileTextOutlined,
    AppstoreAddOutlined,
    LinkOutlined
} from '@ant-design/icons';
import { PiFileTextDuotone, PiImageDuotone, PiLinkDuotone, PiListBulletsDuotone, PiMapPinAreaDuotone, PiMapPinSimpleAreaDuotone, PiNotePencilDuotone, PiPlugsConnectedDuotone, PiQuestionDuotone, PiShoppingBagDuotone, PiShoppingCartDuotone, PiSmileyDuotone, PiStorefront, PiTagDuotone, PiUserPlusDuotone, PiVideoCameraDuotone } from 'react-icons/pi';
const { Title } = Typography;
const iconMap = {
    'text-btns': <PiNotePencilDuotone size={18}  />,
    'media-btns': <PiImageDuotone size={18} />,
    'list': <PiListBulletsDuotone size={18} />,
    'catalogue-message': <PiShoppingBagDuotone size={18} />,
    'single-product': <PiStorefront size={18} />,
    'multi-product': <PiShoppingCartDuotone size={18} />,
    'template': <PiFileTextDuotone size={18} />,
    'request-intervention': <PiUserPlusDuotone size={18} />,
    'condition': <PiTagDuotone size={18} />,
    'content-flow': <PiSmileyDuotone size={18} />,
    'ask-address': <PiMapPinAreaDuotone size={18} />,
    'ask-location': <PiMapPinSimpleAreaDuotone size={18} />,
    'ask-question': <PiQuestionDuotone size={18} />,
    'ask-media': <PiVideoCameraDuotone size={18} />,
    'set-attribute': <PiLinkDuotone size={18} />,
    'add-tag': <PiTagDuotone size={18} />,
    'api-request': <PiPlugsConnectedDuotone size={18} />
};

const triggers = [
    { label: 'Text buttons', key:'text-btns' },
    { label: 'Media buttons', key:'media-btns' },
    { label: 'List', key:'list' },
    { label: 'Catalogue message', key:'catalogue-message' },
    { label: 'Single product', key:'single-product' },
    { label: 'Multi product', key:'multi-product' },
    { label: 'Template', key:'template' }
];

const actions = [
    { label: 'Request intervention', key: 'request-intervention' },
    { label: 'Condition', key: 'condition' },
    { label: 'Content flow', key: 'content-flow' },
    { label: 'Ask address', key: 'ask-address' },
    { label: 'Ask location', key: 'ask-location' },
    { label: 'Ask question', key: 'ask-question' },
    { label: 'Ask media', key: 'ask-media' },
    { label: 'Set attribute', key: 'set-attribute' },
    { label: 'Add tag', key: 'add-tag' },
    { label: 'API request', key: 'api-request' }
];

const AutoSide = () => {
    const onDragStart = (event, item) => {
        event.dataTransfer.setData('application/reactflow', item);
        event.dataTransfer.effectAllowed = 'move';
    };

    const renderNode = (item, type, menutype) => (
        <div style={{ width: 'calc(50% - 4px)' }} key={item.key}>
            <Tooltip title={item.label}>
                <Button type='dashed' className={`dndnode ${type} ${menutype}`} onDragStart={(event) => onDragStart(event, JSON.stringify(item))} draggable>
                    <div className='iconSpan'>{iconMap[item.key]}</div>
                    <div className='itemLabel'>{item.label}</div>
                </Button>
            </Tooltip>
        </div>
    );

    return (
        <aside className='node-sidebar'>
            <Title level={4} style={{ marginTop: 0, marginBottom:16 }}>Message types</Title>
            <Flex wrap justify='space-between' gap={8}>
                {triggers.map((trigger) => renderNode(trigger, 'default', 'itemtrigger'))}
            </Flex>
            <Title level={4} style={{ marginTop: 16, marginBottom:16 }}>Actions</Title>
            <Flex wrap justify='space-between' gap={8}>
                {actions.map((action) => renderNode(action, 'default', 'itemaction'))}
            </Flex>
            <Title level={4} style={{ marginTop: 16, marginBottom:16 }}>Integrations</Title>
        </aside>
    );
};

export default AutoSide;