import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Row, Col, Typography, Flex, Image, Input, Button, Progress, Select, Checkbox, Skeleton, message } from 'antd';
import namesteImg from '../assets/6516694.webp';
import blogo from '../assets/Zipler.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { PiArrowLeftDuotone, PiSignOut } from 'react-icons/pi';
import { CitySelect, CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import CurrencyList from 'currency-list';
const { Paragraph, Title } = Typography;
const { Option } = Select;

const twoColors = {
    '0%': '#108ee9',
    '50%': '#3A1A55',
    '100%': '#3A1A55',
};


const Onboarding = ({ userData, sessionData, messageApi }) => {
    const { step } = useParams();
    const navigate = useNavigate();
    const [brandName, setBrandName] = useState('');
    const [steps, setSteps] = useState(step ? parseInt(step) : 1);
    const [pos, setPos] = useState('');
    const [industry, setIndustry] = useState('');
    const [category, setCategoty] = useState('');
    const [stores, setStores] = useState(1);
    const [industryData, setIndustryData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [posData, setPosData] = useState([]);
    const [loading, setLoading] = useState('');
    const [dataLoading, setDataLoading] = useState(true);
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [postCode, setPostCode] = useState('');
    const [storeId, setStoreId] = useState('');
    const [posName, setPosName] = useState('');
    const [industryName, setIndustryName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [mapLocation, setMapLocation] = useState('');
    const [storeWebsite, setStoreWebsite] = useState('');
    const [storeNumber, setStoreNumber] = useState(userData?.user_phone);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const currencyList = CurrencyList.getAll('en_US');
    console.log(currencyList);
    const [currency, setCurrency] = useState(null);
    const [isAim, setIsAim] = useState({
        repeat: false,
        recall: false,
        boost: false,
        acquire: false,
        improve: false,
        know: false,
    });

    const handleClick = async () => {
        const result = await handleAddStore();
        if (result) {
            if (steps !== 5) {
                navigate(`/onboarding/${steps + 1}`);
                setSteps(steps + 1);
            }

        }
    };

    const handleAddStore = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('status', steps === 5 ? 'active' : 'inactive');
            if (industry !== 'other') {
                formData.append('industry', industryData.find(item => item.industry_id === industry)?.industry_name);
            }
            if (category !== 'other') {
                formData.append('category', categoryData.find(item => item.category_id === category)?.category_name);
            }
            if (pos !== 'other') {
                if (pos === 'notusing') {
                    formData.append('pos', 'notusing');
                } else {
                    formData.append('pos', posData.find(item => item.pos_id === pos)?.pos_name);
                }
            }
            formData.append('store_name', brandName);
            formData.append('store_website', storeWebsite);
            formData.append('store_number', storeNumber);
            formData.append('industry_id', industry);
            formData.append('category_id', category);
            formData.append('pos_id', pos);
            formData.append('store_address', streetAddress);
            formData.append('country', country ? country?.name : '');
            formData.append('currency_code', currency !== '' ? currencyList[currency]?.code : '');
            formData.append('currency_symbol', currency !== '' ? currencyList[currency]?.symbol : '');
            formData.append('state', state ? state.name : '');
            formData.append('city', city ? city.name : '');
            formData.append('postcode', postCode);
            formData.append('map_location', mapLocation);
            formData.append('total_stores', stores);
            formData.append('aim', JSON.stringify(isAim));
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = storeId === '' ? `${window.API_BASE_URL}/stores/onboard` : `${window.API_BASE_URL}/stores/onboard/${storeId}`;
            const response = await fetch(url, {
                method: storeId === '' ? 'POST' : 'PUT',
                headers: {
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
                body: formData,
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                if (storeId === '') {
                    setStoreId(data?.store?.store_id);
                }
                if (steps === 5) {
                    message.success(`Congratulations ${userData?.user_name}, your account has been successfylly created.`);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
                return true;
            }
        } catch (error) {
            console.log('Error while adding store', error);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const fetchStoreData = useCallback(async () => {
        if (sessionData?.token) {
            const url = `${window.API_BASE_URL}/stores/`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    const storeData = data?.store?.[0];
                    setStoreId(storeData?.store_id);
                    setBrandName(storeData?.store_name);
                    setIndustry(storeData?.industry_id !== 0 ? storeData?.industry_id : storeData?.industry !== 'undefined' ? 'other' : '');
                    setCategoty(storeData?.category_id !== 0 ? storeData?.category_id : storeData?.category !== 'undefined' ? 'other' : '');
                    setPos(storeData?.pos_id !== 0 ? storeData?.pos_id : storeData?.pos !== 'undefined' ? storeData?.pos === 'notusing' ? 'notusing' : 'other' : '');
                    setIndustryName(storeData?.industry);
                    setCategoryName(storeData?.category);
                    setPosName(storeData?.pos);
                    setStores(storeData?.total_stores);
                    setStreetAddress(storeData?.store_address);
                    setCountry(storeData?.country);
                    setCurrency(storeData?.currency_code);
                    setCountry(storeData?.country);
                    setCity(storeData?.city);
                    setState(storeData?.state);
                    setPostCode(storeData?.postcode);
                    setMapLocation(storeData?.map_location);
                    setStoreWebsite(storeData?.store_website);
                    setStoreNumber(storeData?.store_number === '' ? userData?.user_phone : storeData?.store_number);
                } else {
                    if (data.message === 'Invalid token') {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        // navigate('/login');
                    }
                    console.error('Error fetching user data:', data.message);
                }
            } catch (error) {
                console.error('Error during fetching store data:', error);
            } finally {
                setDataLoading(false);
            }
        }
    }, [sessionData?.token, userData?.user_phone]);

    useEffect(() => {
        setSteps(step ? parseInt(step) : 1);
    }, [navigate, step]);

    const handlePosOnChange = (value) => {
        setPos(value);
    };

    const handleIndustryOnChange = (value) => {
        setIndustry(value);
    };
    const handleCategoryOnChange = (value) => {
        setCategoty(value);
    };

    const fetchStoreDrops = useCallback(async () => {
        if (sessionData?.token) {
            const url = `${window.API_BASE_URL}/users/storedrops`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setLoading(false);
                    setIndustryData(data?.industries);
                    setCategoryData(data?.categories);
                    setDataLoading(false);
                    setPosData(data?.pos);
                } else {
                    if (data.message === 'Invalid token') {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        // navigate('/login');
                    }
                    console.error('Error fetching user data:', data.message);
                }
            } catch (error) {
                console.error('Error during fetching store drops:', error);
            }
        }
    }, [sessionData?.token]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await Promise.all([fetchStoreDrops(), fetchStoreData()]);
            } else {
                setDataLoading(false);
                setLoading(false);
            }
        };

        fetchData();
    }, [fetchStoreData, fetchStoreDrops, sessionData]);


    const handleLogout = async (e) => {

        messageApi.open({
            key: 'logout',
            type: 'loading',
            content: 'Loading...',
        });
        const url = `${window.API_BASE_URL}/auth/logout`;
        const token = localStorage.getItem('loginToken');
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            console.log(response);
            if (response.ok) {
                setTimeout(() => {
                    messageApi.open({
                        key: 'logout',
                        type: 'success',
                        content: 'Logout Successful!',
                        duration: 2,
                    });
                    setTimeout(() => {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        navigate('/login');
                    }, 300);
                }, 1000);
            } else {
                const data = await response.json();
                console.error('Logout failed:', data.message);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <Layout className='contentLayout'>
            <div className='onboarding-progress-bar'>
                <Progress showInfo={false} percent={steps * 20} strokeColor={twoColors} strokeLinecap="butt" />
            </div>
            <Row align='center'>
                <Col span={8}>
                    <div className='onboarding-main-reg-logo'>
                        <Image src={blogo} preview={false} className='reg-logo' />
                    </div>
                    <div className='onboarding-logout-btn'>
                        <Button onClick={handleLogout} shape="round" style={{ width: 40, height: 40, padding: 0 }}><PiSignOut size={16} /></Button>
                    </div>
                    {dataLoading ? (
                        <div className='onboarding-panel'>
                            <div>
                                <Flex gap={8} align='center' className='onboarding-first-div'>
                                    <Image src={namesteImg} preview={false} style={{ maxWidth: 120 }} />
                                    <Title level={4}>Hi {userData?.user_name}, We're glad that you're here. Let's quickly setup your account togather.</Title>
                                </Flex>
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <Skeleton active title={{ rows: 0 }} />
                                        <Skeleton active title={{ rows: 0 }} />
                                        <Skeleton active title={{ rows: 0 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='onboarding-panel'>
                            <div>
                                {steps !== 5 && (
                                    <Flex gap={8} align='center' className='onboarding-first-div'>
                                        <Image src={namesteImg} preview={false} style={{ maxWidth: 120 }} />
                                        <Title level={4}>Hi {userData?.user_name}, We're glad that you're here. Let's quickly setup your account togather.</Title>
                                    </Flex>
                                )}
                                {steps === 1 ? (
                                    <div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title level={2}>Love to know about your business</Title>
                                            <Paragraph style={{ fontSize: 18, color: '#697777' }}>It will help us to provide you the best<br />customer experience</Paragraph>
                                        </div>
                                        <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>What is your Brand called?</label>
                                        <Input value={brandName} onChange={(e) => setBrandName(e.target.value)} placeholder='Eg. Pizza Hut, Zara, Star Market' />
                                        <Paragraph style={{ marginTop: 10 }}>You will be able to add more stores later</Paragraph>
                                        <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>What is your Brand website?</label>
                                        <Input value={storeWebsite} onChange={(e) => setStoreWebsite(e.target.value)} placeholder='Eg. https://example.com' style={{ marginBottom: 16 }} />
                                        <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>What is your Brand contact number?</label>
                                        <Input value={storeNumber} onChange={(e) => setStoreNumber(e.target.value)} placeholder='Eg. +919876543210' style={{ marginBottom: 16 }} />
                                        <div className='onboarding-submit-btn' style={{ height: 33 }}>
                                            {brandName !== '' && (
                                                <Button type='primary' style={{ width: '100%' }} onClick={handleClick} loading={loading}>Next</Button>
                                            )}
                                        </div>
                                    </div>
                                ) : steps === 2 ? (
                                    <div>
                                        <Flex gap='small' align='center' onClick={() => navigate('/onboarding/1')} className='reg-back-btn'>
                                            <Button shape="round" className='close-btn'><PiArrowLeftDuotone /></Button>
                                            <Paragraph style={{ margin: 0 }}>Back</Paragraph>
                                        </Flex>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title level={2}>Love to know about your business</Title>
                                            <Paragraph style={{ fontSize: 18, color: '#697777' }}>It will help us to provide you the best<br />customer experience</Paragraph>
                                        </div>
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Select your Industry</label>
                                            <Select style={{ width: '100%', marginBottom: 16 }} placeholder="Select Industry" value={industry !== '' ? industry : undefined} showSearch onChange={handleIndustryOnChange} filterOption={(inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}>
                                                {industryData.map((industry) => (
                                                    <Option key={industry.industry_id} value={industry.industry_id}>
                                                        {industry.industry_name}
                                                    </Option>
                                                ))}
                                                <Option value="other">Others</Option>
                                            </Select>
                                        </div>
                                        {industry === 'other' && (
                                            <div>
                                                <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Which one exactly?</label>
                                                <Input style={{ width: '100%', marginBottom: 16 }} value={industryName} onChange={(e) => setIndustryName(e.target.value)} placeholder='Healthcare, Cloaths, etc' />
                                            </div>
                                        )}
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Select your Category</label>
                                            <Select style={{ width: '100%', marginBottom: 16 }} placeholder="Select Category" value={category !== '' ? category : undefined} showSearch onChange={handleCategoryOnChange} filterOption={(inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}>
                                                {categoryData.map((category) => (
                                                    <Option key={category.category_id} value={category.category_id}>
                                                        {category.category_name}
                                                    </Option>
                                                ))}
                                                <Option value="other">Others</Option>
                                            </Select>
                                        </div>
                                        {category === 'other' && (
                                            <div>
                                                <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Which one exactly?</label>
                                                <Input style={{ width: '100%', marginBottom: 16 }} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} placeholder='Enter category name' />
                                            </div>
                                        )}
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>How many stores do you have?</label>
                                            <Input style={{ width: '100%', marginBottom: 16 }} type='number' value={stores} onChange={(e) => setStores(e.target.value)} placeholder='Enter No. of stores' />
                                        </div>
                                        <div className='onboarding-submit-btn' style={{ height: 33 }}>
                                            <Button type='primary' style={{ width: '100%' }} onClick={handleClick} loading={loading}>Next</Button>
                                        </div>
                                    </div>
                                ) : steps === 3 ? (
                                    <div>
                                        <Flex gap='small' align='center' onClick={() => navigate('/onboarding/2')} className='reg-back-btn'>
                                            <Button shape="round" className='close-btn'><PiArrowLeftDuotone /></Button>
                                            <Paragraph style={{ margin: 0 }}>Back</Paragraph>
                                        </Flex>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title level={2}>Time to know about your address</Title>
                                            <Paragraph style={{ fontSize: 18, color: '#697777' }}>It will help us to enhance your product experience.</Paragraph>
                                        </div>
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Street address</label>
                                            <Input style={{ width: '100%', marginBottom: 16 }} value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} placeholder='Enter your street address' />
                                        </div>
                                        <div style={{ marginBottom: 16 }}>
                                            <Flex gap={16}>
                                                <div style={{ width: '100%' }}>
                                                    <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Country</label>
                                                    <CountrySelect
                                                        onChange={(e) => {
                                                            console.log(e);
                                                            setCountry(e);
                                                            setCurrency(e.currency);
                                                        }}
                                                        // defaultValue={country && country}
                                                        placeHolder="Select Country"
                                                    />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>State</label>
                                                    <StateSelect
                                                        countryid={country?.id}
                                                        onChange={(e) => {
                                                            setState(e);
                                                        }}
                                                        placeHolder="Select State"
                                                    />
                                                </div>
                                            </Flex>
                                        </div>
                                        <div style={{ marginBottom: 16 }}>
                                            <Flex gap={16}>
                                                <div style={{ width: '100%' }}>
                                                    <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>City</label>
                                                    <CitySelect
                                                        countryid={country?.id}
                                                        stateid={state?.id}
                                                        onChange={(e) => {
                                                            console.log(e);
                                                        }}
                                                        placeHolder="Select City"
                                                    />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Currency</label>
                                                    <Select showSearch onChange={(e) => setCurrency(e)} placeholder="Select Currency" style={{ width: '100%' }} value={currency}>
                                                        {Object.keys(currencyList).map((currency) => (
                                                            <Option key={currency} value={currency}>{currency} ({currencyList[currency].symbol})</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Flex>
                                        </div>
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Pincode</label>
                                            <Input style={{ width: '100%', marginBottom: 16 }} value={postCode} onChange={(e) => setPostCode(e.target.value)} placeholder='Enter Pin/Zip code' />
                                        </div>
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Map Location</label>
                                            <Input style={{ width: '100%', marginBottom: 16 }} value={mapLocation} onChange={(e) => setMapLocation(e.target.value)} placeholder='Enter Map Location link' />
                                        </div>
                                        <div className='onboarding-submit-btn' style={{ height: 33 }}>
                                            <Button type='primary' style={{ width: '100%' }} onClick={handleClick} loading={loading}>Next</Button>
                                        </div>
                                    </div>
                                ) : steps === 4 ? (
                                    <div>
                                        <Flex gap='small' align='center' onClick={() => navigate('/onboarding/3')} className='reg-back-btn'>
                                            <Button shape="round" className='close-btn'><PiArrowLeftDuotone /></Button>
                                            <Paragraph style={{ margin: 0 }}>Back</Paragraph>
                                        </Flex>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title level={2}>Which Billing Software (POS) do you use at {brandName}?</Title>
                                        </div>
                                        <div>
                                            <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Select POS</label>
                                            <Select style={{ width: '100%', marginBottom: 16 }} placeholder="Select POS" value={pos !== '' ? pos : undefined} showSearch onChange={handlePosOnChange} filterOption={(inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}>
                                                {posData.map((pos) => (
                                                    <Option key={pos.pos_id} value={pos.pos_id}>
                                                        {pos.pos_name}
                                                    </Option>
                                                ))}
                                                <Option value="other">I'm using another one</Option>
                                                <Option value="notusing">Do not use a POS</Option>
                                            </Select>
                                        </div>
                                        {pos === 'other' && (
                                            <div>
                                                <label style={{ fontSize: 14, fontWeight: 500, lineHeight: 2.1 }}>Which one exactly?</label>
                                                <Input style={{ width: '100%', marginBottom: 16 }} value={posName} onChange={(e) => setPosName(e.target.value)} placeholder='Tally, gofrugal, Marg, etc' />
                                            </div>
                                        )}
                                        {pos !== '' && (
                                            <div className='onboarding-submit-btn' style={{ height: 33 }}>
                                                <Button type='primary' style={{ width: '100%' }} onClick={handleClick} loading={loading}>Next</Button>
                                            </div>
                                        )}
                                    </div>
                                ) : steps === 5 && (
                                    <div>
                                        <Flex gap='small' align='center' onClick={() => navigate('/onboarding/4')} className='reg-back-btn'>
                                            <Button shape="round" className='close-btn'><PiArrowLeftDuotone /></Button>
                                            <Paragraph style={{ margin: 0 }}>Back</Paragraph>
                                        </Flex>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title level={2}>What do you aim to archive with zipler.io</Title>
                                            <Paragraph style={{ fontSize: 18, color: '#697777' }}>Select your top goals. (All that apply)</Paragraph>
                                        </div>
                                        <Flex gap={16} align='center' justify='space-between' wrap="wrap" style={{ marginBottom: 16 }}>
                                            <div className={`aim-checkboxes ${isAim?.repeat && 'active'}`} onClick={() => setIsAim({ ...isAim, repeat: !isAim?.repeat })}>
                                                <Checkbox checked={isAim?.repeat ? true : false} /> <Paragraph className='check-label'>Drive repeat purchase</Paragraph>
                                            </div>
                                            <div className={`aim-checkboxes ${isAim?.recall && 'active'}`} onClick={() => setIsAim({ ...isAim, recall: !isAim?.recall })}>
                                                <Checkbox checked={isAim?.recall ? true : false} /> <Paragraph className='check-label'> Strengthen brand recall</Paragraph>
                                            </div>
                                            <div className={`aim-checkboxes ${isAim?.boost && 'active'}`} onClick={() => setIsAim({ ...isAim, boost: !isAim?.boost })}>
                                                <Checkbox checked={isAim?.boost ? true : false} /> <Paragraph className='check-label'> Boost customer loyalty</Paragraph>
                                            </div>
                                            <div className={`aim-checkboxes ${isAim?.acquire && 'active'}`} onClick={() => setIsAim({ ...isAim, acquire: !isAim?.acquire })}>
                                                <Checkbox checked={isAim?.acquire ? true : false} /> <Paragraph className='check-label'> Acquire new customers</Paragraph>
                                            </div>
                                            <div className={`aim-checkboxes ${isAim?.imporve && 'active'}`} onClick={() => setIsAim({ ...isAim, imporve: !isAim?.imporve })}>
                                                <Checkbox checked={isAim?.imporve ? true : false} /> <Paragraph className='check-label'> Improve customer engagement</Paragraph>
                                            </div>
                                            <div className={`aim-checkboxes ${isAim?.know && 'active'}`} onClick={() => setIsAim({ ...isAim, know: !isAim?.know })}>
                                                <Checkbox checked={isAim?.know ? true : false} /> <Paragraph className='check-label'> Know my customers better</Paragraph>
                                            </div>
                                        </Flex>
                                        <div className='onboarding-submit-btn' style={{ height: 33 }}>
                                            <Button type='primary' style={{ width: '100%' }} onClick={handleClick} loading={loading}>Finish</Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </Layout>
    );
};
export default Onboarding;