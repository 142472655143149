import React from 'react';
import { Row, Col, Typography, Flex, Input, Button } from 'antd';
import { PiPlusDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
const { Paragraph, Title } = Typography;
const { Search } = Input;
const Flow = () => {
    
    const navigate = useNavigate();

    return (
        <Row gutter={16}>
            <Col span={24}>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <Title level={3}>Flow builder</Title>
                    <Paragraph style={{ color: '#697777' }}>Effortlessly automate communication with a powerful flow builder.</Paragraph>
                </div>
            </Col>
            <Col span={24}>
                <Flex justify='space-between' align='center'>
                    <Search placeholder='search flow by name' style={{maxWidth:300}} />
                    <Button type='primary' onClick={() => navigate(`/automation/flow/builder`)}><PiPlusDuotone size={24} /> Create new fLow</Button>
                </Flex>
            </Col>
        </Row>
    );
};
export default Flow;