import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Typography, Button, Flex, Card, Radio, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { PiArrowLeftDuotone } from 'react-icons/pi';
const { Paragraph, Title } = Typography;
const ChannelSettings = () => {

    const [pageLoading, setPageLoading] = useState(true);
    const [smsRadio, setSMSRadio] = useState('mconnectby');
    const [whatsappRadio, setWhatsappRadio] = useState('mconnectby');
    const navigate = useNavigate();
    const { channelType } = useParams();

    useEffect(() => {
        setPageLoading(false);
    }, [channelType]);

    const handleChangeSettings = (channelType) => {
        navigate(`/channel-settings/${channelType}`);
    };

    const handleSMSRadioOnChange = (value) => {
        setSMSRadio(value);
    };

    const handleWhatsappRadioOnChange = (value) => {
        setWhatsappRadio(value);
    };

    return (
        pageLoading ? (
            <Layout className='contentLayout'>
                <div className='loaderDiv'>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Layout>
        ) : (
            <Layout className='contentLayout channel-settings'>
                <Flex gap='middle' style={{marginBottom: 24 }}>
                    <Button shape="round" onClick={() => navigate(`/channels`)} className='close-btn'><PiArrowLeftDuotone /></Button>
                    <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Channel Settings</b></Title>
                </Flex>
                <Row gutter={16}>
                    <Col span={24}>
                        <Flex gap='middle'>
                            <Button type={!channelType ? 'primary' : channelType === 'sms' && 'primary'} onClick={() => handleChangeSettings('sms')} shape="round" size='large'>SMS</Button>
                            <Button type={channelType === 'whatsapp' && 'primary'} onClick={() => handleChangeSettings('whatsapp')} shape="round" size='large'>WhatsApp</Button>
                            <Button type={channelType === 'otp' && 'primary'} onClick={() => handleChangeSettings('otp')} shape="round" size='large'>OTP</Button>
                        </Flex>
                        <div className='devider' />
                    </Col>
                </Row>
                {channelType === 'sms' ? (
                    <Row gutter={16} className='channel-sms'>
                        <Col span={24}>
                            <Card hoverable bordered={false} style={{ backgroundColor: '#f7f8f8', borderRadius: 16 }}>
                                <Title level={4}>Current Header (Sender ID)</Title>
                                <Paragraph>Customers will receive SMS Communication from default mConnectby header or you can add your own</Paragraph>
                                <Radio.Group onChange={handleSMSRadioOnChange} value={smsRadio}>
                                    <Radio value='mconnectby'>mConnectby</Radio>
                                    <Radio value='own'>Add your own</Radio>
                                </Radio.Group>
                            </Card>
                        </Col>
                    </Row>
                ) : channelType === 'whatsapp' ? (
                    <Row gutter={16} className='channel-whatsapp'>
                        <Col span={24}>
                            <Card hoverable bordered={false} style={{ backgroundColor: '#f7f8f8', borderRadius: 16 }}>
                                <Title level={4}>Current Sender</Title>
                                <Paragraph>Customers will receive WhatsApp Communication from default zipler.io account or you can add your own</Paragraph>
                                <Radio.Group onChange={handleWhatsappRadioOnChange} value={whatsappRadio}>
                                    <Radio value='connectby'>Connectby</Radio>
                                    <Radio value='own'>Add your own <span style={{ padding: '6px 8px', margin: '0 0 0 10px', borderRadius: 48, background: '#facc15' }}>Paid Add-On</span></Radio>
                                </Radio.Group>
                            </Card>
                        </Col>
                    </Row>
                ) : channelType === 'otp' && (
                    <Row gutter={16}>
                        <Col span={24}>
                        </Col>
                    </Row>
                )}
            </Layout>
        )
    );
};
export default ChannelSettings;