import React, { useState, useRef, useEffect } from 'react';
import { Layout, Row, Col, Typography, Input, Button, Card, Table, Tooltip, Modal, Flex, message } from 'antd';
import { HiOutlineArrowRightOnRectangle } from 'react-icons/hi2';
// import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
const { Link, Paragraph, Title } = Typography;


const statusColors = {
    active: '#52c41a',
    inactive: '#ff4d4f',
};

const formatTimeDifference = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    const timeDiff = now - createdAtDate;
    const totalMinutes = Math.floor(timeDiff / (1000 * 60));
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    return `${totalDays} Days ${hours} hrs & ${minutes} minutes`;
};

const formatLastLoginTimeFormat = (createdAt) => {
    const date = new Date(createdAt);
    if (isNaN(date)) {
        return "Invalid date format";
    }
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    };
    return date.toLocaleString('en-US', options).replace(',', '');
};

const truncateColumn = (string) => {
    return string.length > 20 ? `${string.substring(0, 20)}...` : string
};

const Account = ({ userData, setUserData, sessionData }) => {
    const [name, setName] = useState(userData.user_name);
    const [email, setEmail] = useState(userData.user_email);
    const [phone, setPhone] = useState(userData.user_phone);
    const [editable, setEditable] = useState('');
    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const phoneInputRef = useRef(null);
    const [allSessionData, setAllSessionData] = useState([]);
    const [sessionDataLoading, setSessionDataLoading] = useState(true);
    const [sessionType, setSessionType] = useState('active');
    const deviceId = localStorage.getItem('deviceId');
    // const navigate = useNavigate();

    useEffect(() => {
        const fetchSessionData = async () => {
            const url = `${window.API_BASE_URL}/users/sessiondata`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionData?.token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setAllSessionData(data.sessionData);
                    setSessionDataLoading(false);
                } else {
                    if (data.message === 'Invalid token') {
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        // navigate('/login');
                    }
                    console.error('Error fetching session data:', data.message);
                }
            } catch (error) {
                console.error('Error during fetching session data:', error);
            }
        };
        if (sessionData) {
            fetchSessionData();
        } else {
            setSessionDataLoading(false);
        }
    }, [sessionData]);

    const handleEdit = (field) => {
        setEditable(field);
        switch (field) {
            case 'name':
                nameInputRef.current.focus();
                break;
            case 'email':
                emailInputRef.current.focus();
                break;
            case 'phone':
                phoneInputRef.current.focus();
                break;
            default:
                break;
        }
    };


    const columns = [
        {
            title: 'Token ID',
            key: 'token_id',
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'Token',
            dataIndex: 'token',
            key: 'token',
            render: (_, record) => (
                <div key={record.token_id}>
                    <Tooltip title={record.token} key={record.token}>
                        <span>{truncateColumn(record.token)}</span>
                    </Tooltip>
                </div>
            )
        },
        {
            title: 'Device ID',
            dataIndex: 'device_id',
            key: 'device_id',
            render:(deviceid) => (
                deviceid === deviceId ? <span style={{color:'blue'}} key={deviceid}>This Device</span> : <Tooltip key={deviceid} title={deviceid}>{truncateColumn(deviceid)}</Tooltip>
            )
        },
        {
            title: 'Device Type',
            dataIndex: 'device_type',
            key: 'device_type',
        },
        {
            title: 'Device OS',
            dataIndex: 'device_os',
            key: 'device_os',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <span style={{ color: statusColors[text] || '#000', fontWeight: 'bold' }}>{text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}</span>
            )
        },
        {
            title: 'Login Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Logout Time',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (_, record) => (
                <span>
                    {record.status === 'inactive' && (
                        new Date(record.updated_at).toLocaleString()
                    )}
                </span>

            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Tooltip title={'Logout'}>
                    <Button danger disabled={record.status === 'inactive' && true} onClick={() => handleLogoutIndividual(record.token)}><HiOutlineArrowRightOnRectangle /></Button>
                </Tooltip>
            )
        }
    ];

    const handleLogoutIndividual = (token) => {
        Modal.confirm({
            title: 'Are you sure you want to log out of this session?',
            content: 'This will log you out of the selected device/session.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const response = await fetch(`${window.API_BASE_URL}/auth/logout`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                    console.log(response);
                    if (response.ok) {
                        setAllSessionData(prev =>
                            prev.map(session =>
                                session.token === token ? { ...session, status: 'inactive', updated_at:moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') } : session
                            )
                        );
                        message.success('Logged out successfully!');
                    } else {
                        message.error('Error logging out session.');
                    }
                } catch (error) {
                    console.error('Error during individual logout:', error);
                    message.error('Network error during logout.');
                }
            },
        });
    };

    const handleLogoutAll = async () => {
        Modal.confirm({
            title: 'Are you sure you want to log out of all sessions?',
            content: 'This will log you out of all devices and sessions.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const response = await fetch(`${window.API_BASE_URL}/auth/logoutall`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${sessionData?.token}`,
                        },
                    });
                    if (response.ok) {
                        message.success('Logged out of all sessions successfully!');
                        localStorage.removeItem('loginToken');
                        localStorage.removeItem('deviceId');
                        localStorage.removeItem('storeId');
                        // navigate('/login');
                    } else {
                        message.error('Error logging out of all sessions.');
                    }
                } catch (error) {
                    console.error('Error during logout all:', error);
                    message.error('Network error during logout all.');
                }
            },
        });
    };

    const handleSave = (field) => {
        switch (field) {
            case 'name':
                setUserData(prevData => ({
                    ...prevData,
                    user_name: name
                }));
                break;
            case 'email':
                setUserData(prevData => ({
                    ...prevData,
                    user_email: email
                }));
                break;
            case 'phone':
                setUserData(prevData => ({
                    ...prevData,
                    user_phone: phone
                }));
                break;
            default:
                break;
        }
        setEditable('');
    };

    const handleSessionTypeClick = (sessionType) => {
        setSessionType(sessionType);
    };

    return (
        <Layout className='contentLayout'>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Account Details</b></Title>
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <Card className='account-card' bordered={false} hoverable>
                        <div style={{ maxWidth: '50%' }}>
                            <Title level={5} style={{ margin: 0 }}>Hello,</Title>
                            <Title level={2} style={{ marginTop: 0 }}>{userData.user_name}</Title>
                            <Paragraph>This is your zipler.io account and It has been exactly <b>{formatTimeDifference(userData?.created_at)}</b> since you joined us!</Paragraph>
                            <br />
                            <Paragraph style={{ marginBottom: 0 }}>Your last login</Paragraph>
                            <Paragraph style={{ marginTop: 0, color: '#3a1a55', fontWeight: 'bold' }}>{formatLastLoginTimeFormat(sessionData?.created_at)}</Paragraph>
                            <Paragraph style={{ marginBottom: 0, fontSize: 12, fontWeight: 'bold', color: '#354545' }}>Looks suspicious?</Paragraph>
                            <Link style={{ margin: 0, fontSize: 12, fontWeight: 'bold', color: '#354545', textDecoration: 'underline', textUnderlineOffset: 1 }}>Report this</Link>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className='edit-profile-card' bordered={false} hoverable>
                        <div style={{ marginBottom: 16 }}>
                            <Title level={5} style={{ marginTop: 17, marginBottom: 8 }}>Name</Title>
                            <div style={{ position: 'relative' }}>
                                <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your name"
                                    disabled={editable !== 'name'}
                                    ref={nameInputRef}
                                />
                                {editable === 'name' ? (
                                    name !== userData.user_name && (
                                        <Paragraph className='profile-edit-btn' onClick={() => handleSave('name')}>Save</Paragraph>
                                    )
                                ) : (
                                    <Paragraph className='profile-edit-btn' onClick={() => handleEdit('name')}>Edit</Paragraph>
                                )}
                            </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <Title level={5} style={{ marginBottom: 8 }}>Email</Title>
                            <div style={{ position: 'relative' }}>
                                <Input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    disabled={editable !== 'email'}
                                    ref={emailInputRef}
                                />
                                {editable === 'email' ? (
                                    email !== userData.user_email && (
                                        <Paragraph className='profile-edit-btn' onClick={() => handleSave('email')}>Save</Paragraph>
                                    )
                                ) : (
                                    <Paragraph className='profile-edit-btn' onClick={() => handleEdit('email')}>Edit</Paragraph>
                                )}
                            </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <Title level={5} style={{ marginBottom: 8 }}>Phone No.</Title>
                            <div style={{ position: 'relative' }}>
                                <Input
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Enter your Phone No."
                                    disabled={editable !== 'phone'}
                                    ref={phoneInputRef}
                                />
                                {editable === 'phone' ? (
                                    phone !== userData.user_phone && (
                                        <Paragraph className='profile-edit-btn' onClick={() => handleSave('phone')}>Save</Paragraph>
                                    )
                                ) : (
                                    <Paragraph className='profile-edit-btn' onClick={() => handleEdit('phone')}>Edit</Paragraph>
                                )}
                            </div>
                        </div>
                        <Button type='primary'>Change Password</Button>
                    </Card>
                </Col>
            </Row>
            <Row className='custom-margin' gutter={16}>
                <Col span={24}>
                    <Flex gap='middle' justify='space-between' align='center'>
                        <Flex gap='middle'>
                            <Button type={!sessionType ? 'primary' : sessionType === 'active' && 'primary'} onClick={() => handleSessionTypeClick('active')} shape="round" size='large'>Active</Button>
                            <Button type={!sessionType ? 'primary' : sessionType === 'inactive' && 'primary'} onClick={() => handleSessionTypeClick('inactive')} shape="round" size='large'>Inactive</Button>
                        </Flex>
                        <Button danger onClick={handleLogoutAll}><HiOutlineArrowRightOnRectangle /> Logout from all device</Button>
                    </Flex>
                    <Table
                        className='customerTable'
                        dataSource={allSessionData.map(item => ({...item, key: item.token_id}))}
                        columns={columns}
                        loading={sessionDataLoading}
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            showTotal: (total, range) => (
                                <span className="totaldata">Showing {range[0]} to {range[1]} of {total} entries</span>
                            ),
                        }}
                    />
                </Col>
            </Row>
        </Layout>
    );
};
export default Account;