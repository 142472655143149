import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { ApartmentOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Input } from 'antd';
import { PiPlusDuotone, PiTrashDuotone } from 'react-icons/pi';

function InitialFlow({ data }) {
    const { handleInputChange, handleAddInputBox, handleDelete, inputBoxes, inFlow } = data;
    return (
        <div className='nodes'>
            <div className='header'>
                <p><ApartmentOutlined /> {data.label}</p>
            </div>
            <div className='body'>
                <div className='inputMainBox'>
                    <div className='inBox'>
                        {inputBoxes.map((box, i) => (
                            <div key={box.id} className='inputBox'>
                                <Flex justify='space-between' align='center'>
                                    <Input
                                        placeholder='Type Message'
                                        maxLength={200}
                                        showCount
                                        value={box.value}
                                        onChange={(e) => handleInputChange(e, box.id)}
                                    />
                                    <Button danger onClick={() => handleDelete(box.id)}>
                                        <PiTrashDuotone />
                                    </Button>
                                </Flex>
                            </div>
                        ))}
                        <div style={{paddingTop:16, paddingBottom:16}}>
                            <Checkbox>Partial match</Checkbox>
                        </div>
                    </div>
                    <Button className='addMoreBtn' onClick={handleAddInputBox}>Add Keyword <PiPlusDuotone /></Button>
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} id={`${data.id}`} />
        </div>
    );
}

export default InitialFlow;