import React, { useState, useEffect, useCallback } from 'react';
import {
    DownOutlined,
    InfoCircleOutlined,
    BulbFilled
} from '@ant-design/icons';
import { Button, Layout, Space, Dropdown, Typography, Row, Col, Card, Tooltip, Progress, Flex, Image, QRCode } from 'antd';
import { IoCheckbox } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { PiBrowserDuotone, PiFacebookLogoDuotone, PiInstagramLogoDuotone, PiMapPinAreaDuotone, PiPhoneCallDuotone, PiStorefrontDuotone, PiWhatsappLogoDuotone } from 'react-icons/pi';
Chart.register(...registerables);

const { Link, Paragraph, Title } = Typography;

const datePickerItems = [
    {
        key: '1',
        label: 'Highlights for Today'
    },
    {
        key: '2',
        label: 'Last 7 Days'
    },
    {
        key: '3',
        label: 'Last 30 Days'
    },
    {
        key: '4',
        label: 'This Month'
    },
    {
        key: '5',
        label: 'Last Month'
    },
    {
        key: '6',
        label: 'Last 12 Month'
    },
    {
        key: '7',
        label: 'Custom range'
    }
];

const Home = ({ userData, sessionData, storeData }) => {
    const totalSales = 1142047;
    const [totalVisits, setTotalVisits] = useState(0);
    const [newCustomrsCount, setNewCustomrsCount] = useState(0);
    const [repeatCustomrsCount, setRepeatCustomrsCount] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalRedemption, setTotalRedemption] = useState(0);
    const [dataLoading, setDataLoading] = useState(true);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [qrcode, setQrcode] = useState(null);
    
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const donughtData = {
        labels: ['Completed', 'Remaining'],
        datasets: [
            {
                data: [repeatCustomrsCount, 0],
                backgroundColor: ['#04BEBD', '#EBBD07'],
                borderWidth: 0,
            },
        ],
    };

    const donughtOptions = {
        cutout: '70%',
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: false,
            },
        },
    };


    const fetchCustomerOverviewData = useCallback(async () => {
        const storeId = localStorage.getItem('storeId');
        const url = `${window.API_BASE_URL}/users/store-overview?storeId=${storeId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                setTotalCustomers(data?.totalCustomers);
                setTotalVisits(data?.totalVisits);
                setTotalSpent(data?.totalSpent);
                setTotalOrders(data?.totalOrders);
                setTotalRedemption(data?.totalRedemption);
                setNewCustomrsCount(data?.totalNewCustomers);
                setRepeatCustomrsCount(data?.repeatCustomers);
                setQrcode(data?.qrcode);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching store data:', error);
        } finally {
            setDataLoading(false);
        }
    }, [sessionData]);

    useEffect(() => {
        const fetchData = async () => {
            if (sessionData) {
                await fetchCustomerOverviewData();
            } else {
                setDataLoading(false);
            }
        };
        fetchData();
    }, [fetchCustomerOverviewData, sessionData]);

    const generateRandomData = () => {
        const startDate = new Date(dayjs().subtract(29, 'day').format('YYYY-MM-DD'));
        let remainingSales = totalSales;
        const data = [];

        for (let i = 0; i < 30; i++) {
            const randomValue = Math.floor(Math.random() * (remainingSales / (30 - i)));
            remainingSales -= randomValue;
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);
            const options = { month: 'short', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString('en-US', options);

            data.push({
                date: formattedDate,
                sales: randomValue,
            });
        }

        return data;
    };

    const generateRandomVisitData = () => {
        const startDate = new Date(dayjs().subtract(29, 'day').format('YYYY-MM-DD'));
        let remainingSales = 1551;
        const data = [];

        for (let i = 0; i < 30; i++) {
            const randomValue = Math.floor(Math.random() * (remainingSales / (30 - i)));
            remainingSales -= randomValue;
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);
            const options = { month: 'short', day: 'numeric' };
            const formattedDate = currentDate.toLocaleDateString('en-US', options);

            data.push({
                date: formattedDate,
                sales: randomValue,
            });
        }

        return data;
    };


    const [salesData, setSalesData] = useState([]);
    const [visitData, setVisitData] = useState([]);

    useEffect(() => {
        const data = generateRandomData();
        const visitData = generateRandomVisitData();
        setSalesData(data);
        setVisitData(visitData);
    }, []);

    const visitChartData = {
        labels: visitData.map(item => item.date),
        datasets: [
            {
                label: 'Visits',
                data: visitData.map(item => item.sales),
                borderColor: '#32e5ff',
                backgroundColor: '#E9FAEA',
                // fill: true,
                borderWidth: 2,
                tension: 1,
                pointRadius: 3,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointBackgroundColor: 'transparent'
            },
        ],
    };

    const chartData = {
        labels: salesData.map(item => item.date),
        datasets: [
            {
                label: 'Sales',
                data: salesData.map(item => item.sales),
                borderColor: '#3a1a55',
                backgroundColor: '#9484a2',
                // fill: true,
                borderWidth: 2,
                tension: 1,
                pointRadius: 3,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointBackgroundColor: 'transparent'
            },
        ],
    };
    const totalDuration = 500;
    const delayBetweenPoints = totalDuration / salesData.length;
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                display: false,
            },
            y: {
                display: false,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return ` ${label}: ${value}`;
                    }
                }
            },
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            }
        },
        animation: {
            x: {
                type: 'number',
                easing: 'linear',
                duration: delayBetweenPoints,
                from: NaN,
                delay(ctx) {
                    if (ctx.type !== 'data' || ctx.xStarted) {
                        return 0;
                    }
                    ctx.xStarted = true;
                    return ctx.index * delayBetweenPoints;
                },
            },
            y: {
                type: 'number',
                easing: 'linear',
                duration: delayBetweenPoints,
                from: (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y,
                delay(ctx) {
                    if (ctx.type !== 'data' || ctx.yStarted) {
                        return 0;
                    }
                    ctx.yStarted = true;
                    return ctx.index * delayBetweenPoints;
                },
            },
        },
        elements: {
            line: {
                tension: 1,
                borderWidth: 2,
            },
            point: {
                radius: 0,
            },
        },
    };

    return (
        <Layout className='contentLayout'>

            <Row gutter={16}>
                <Col span={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
                        <Title level={3} style={{ display: 'flex', alignItems: 'center', margin: 0 }}><b>Your business at glance</b>&nbsp;<BsStars size={24} style={{ color: '#F9C23C' }} /></Title>
                        <Button style={{ justifyContent: 'center', display: 'flex' }}><IoCheckbox color='green' fontSize={20} /> Email report</Button>
                    </div>
                    <div className='hovarablehomediv'>
                        <Card hoverable style={{ border: 'none', backgroundColor: '#3A1A55' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Dropdown
                                    menu={{
                                        items: datePickerItems,
                                    }}
                                >
                                    <Link onClick={(e) => e.preventDefault()}>
                                        <Space><Title level={4} style={{ margin: 0, color: '#FFFFFF' }}>Highlights for Today <DownOutlined /></Title></Space>
                                    </Link>
                                </Dropdown>
                                <Tooltip title={
                                    <div>
                                        <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                        <p>The quick highlight of your business for today. Know the Net Sales, customer visits, number of new customers, and the rewards redeemed today.</p>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ fontSize: 16, color: '#FFFFFF' }} />
                                </Tooltip>
                            </div>
                            <Row gutter={16} style={{ marginBottom: 127 }}>
                                <Col span={12}>
                                    <Tooltip title={
                                        <div>
                                            <p><b>Total value of sales generated during the selected time period. (This data comes from the POS, cashier portal or any transactions you have imported)</b></p>
                                            <ul style={{ marginLeft: 0, paddingLeft: 15 }}>
                                                <li>All orders - customers with valid, invalid, blocked, and no mobile number orders are all included.</li>
                                                <li>Pending and voided bills are excluded.</li>
                                                <li>The data is valid until 11.59 PM of the selected time.</li>
                                            </ul>
                                        </div>}>
                                        <Paragraph style={{ marginTop: 10, marginBottom: 4, borderBottom: '1.5px dashed #32e5ff', color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Total Sales</Paragraph>
                                    </Tooltip>
                                    <Title level={2} style={{ margin: 0, color: '#FFFFFF' }}>{storeDetail?.currency_symbol} {totalSpent}</Title>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title={
                                        <div>
                                            <p><b>Total number of orders (bills) generated during the selected time period. (This data comes from the POS, cashier portal or any transactions you have imported)</b></p>
                                            <ul style={{ marginLeft: 0, paddingLeft: 15 }}>
                                                <li>All orders - customers with valid, invalid, blocked, and no mobile number orders are all included.</li>
                                                <li>Pending and voided orders are excluded.</li>
                                                <li>The data is valid until 11.59 PM of the selected time.</li>
                                            </ul>
                                        </div>}>
                                        <Paragraph style={{ marginTop: 10, marginBottom: 4, borderBottom: '1.5px dashed #32e5ff', color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Total Orders</Paragraph>
                                    </Tooltip>
                                    <Title level={2} style={{ margin: 0, color: '#FFFFFF' }}>{totalOrders}</Title>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title={
                                        <div>
                                            <p><b>This data shows the total number of customers who made a purchase during the selected time period.</b></p>
                                            <ul style={{ marginLeft: 0, paddingLeft: 15 }}>
                                                <li>Only orders with a valid mobile numbers are included here.</li>
                                                <li>Customers with multiple purchases during the selected time period will be counted as 1.</li>
                                                <li>The data is valid until 11.59 PM of the selected time.</li>
                                            </ul>
                                        </div>
                                    }>
                                        <Paragraph style={{ marginTop: 10, marginBottom: 4, borderBottom: '1.5px dashed #32e5ff', color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Total Customers</Paragraph>
                                    </Tooltip>
                                    <Title level={2} style={{ margin: 0, color: '#FFFFFF' }}>{totalCustomers}</Title>
                                    <div style={{maxWidth:200}}>
                                    <Progress
                                        percent={100}
                                        success={{ percent: ((newCustomrsCount / repeatCustomrsCount)*100).toFixed(0), strokeColor: '#9C27B0' }}
                                        strokeColor="#32e5ff"
                                        trailColor="#3A1A55"
                                        format={() => <div style={{ alignItems: 'end', justifyContent: 'space-between', width: '97%', position: 'absolute', display: 'flex', left: 0, marginTop: 5 }}><Paragraph style={{ color: '#FFFFFF' }}>{newCustomrsCount}</Paragraph><Paragraph style={{ color: '#FFFFFF' }}>{repeatCustomrsCount}</Paragraph></div>}
                                    />
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title={
                                        <div>
                                            <p><b>Total number of rewards that are redeemed in your business in the selected time period.</b></p>
                                            <ul style={{ marginLeft: 0, paddingLeft: 15 }}>
                                                <li>This includes Loyalty, Campaign, Auto-campaign, QR code, and Referral reward redemption.</li>
                                                <li>The data is valid until 11.59 PM of the selected time.</li>
                                            </ul>
                                        </div>
                                    }>
                                        <Paragraph style={{ marginTop: 10, marginBottom: 4, borderBottom: '1.5px dashed #32e5ff', color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Rewards Redeemed</Paragraph>
                                    </Tooltip>
                                    <Title level={2} style={{ margin: 0, color: '#FFFFFF' }}>{totalRedemption}</Title>
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', justifyContent: 'left', position: 'absolute', bottom: 0 }}>
                                <Paragraph style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#FFFFFF' }}><div className='circle-1'></div>New customers </Paragraph> <Paragraph style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginLeft: 8, color: '#FFFFFF' }}><div className='circle-2'></div>Repeat customers</Paragraph>
                            </div>
                        </Card>
                        <br />
                        <Card hoverable style={{ border: 'none', backgroundColor: '#32E5ff' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Space><Title level={4} style={{ margin: 0, marginRight: 10 }}>Sales in</Title></Space>
                                    <Dropdown
                                        menu={{
                                            items: datePickerItems,
                                        }}
                                    >
                                        <Link onClick={(e) => e.preventDefault()}>
                                            {/* <Space><Paragraph style={{ margin: 0 }}>Last 30 Days 15,Aug 24 - 13,Sep 24 <DownOutlined /></Paragraph></Space> */}
                                        </Link>
                                    </Dropdown>
                                </div>
                                <Tooltip title={
                                    <div>
                                        <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                        <p>The total value of bills captured by you in this time period.</p>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ fontSize: 16 }} />
                                </Tooltip>
                            </div>
                            <Flex align='center'>
                                <div>
                                    <Title level={1} style={{ marginTop: 16, minWidth: 250 }}>{storeDetail?.currency_symbol} {totalSpent}</Title>
                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ marginRight: 16 }}>
                                            <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#3A1A55', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Valid numbers</Paragraph>
                                            <Title level={3} style={{ margin: 0 }}>{storeDetail?.currency_symbol} {totalSpent}</Title>
                                        </div>
                                        <div>
                                            <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#3A1A55', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Blocked numbers</Paragraph>
                                            <Title level={3} style={{ margin: 0 }}>{storeDetail?.currency_symbol} 0</Title>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{ width: '100%', height: '500' }}>
                                    <Line data={chartData} options={options} />
                                </div> */}
                            </Flex>
                        </Card>
                        <br />
                        <Card hoverable style={{ border: 'none', backgroundColor: '#3A1A55' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Space><Title level={4} style={{ margin: 0, marginRight: 10, color: '#FFFFFF' }}>Visits in</Title></Space>
                                    <Dropdown
                                        menu={{
                                            items: datePickerItems,
                                        }}
                                    >
                                        <Link onClick={(e) => e.preventDefault()}>
                                            {/* <Space><Paragraph style={{ margin: 0, color: '#FFFFFF' }}>Last 30 Days 15,Aug 24 - 13,Sep 24 <DownOutlined /></Paragraph></Space> */}
                                        </Link>
                                    </Dropdown>
                                </div>
                                <Tooltip title={
                                    <div>
                                        <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                        <p>The total number of customer visits (unique bills) captured by you in this time period.</p>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ fontSize: 16, color: '#FFFFFF' }} />
                                </Tooltip>
                            </div>
                            <Flex align='center'>
                                <div>
                                    <Title level={1} style={{ marginTop: 16, minWidth: 250, color: '#FFFFFF' }}>{totalVisits}</Title>
                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ marginRight: 16 }}>
                                            <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Valid numbers</Paragraph>
                                            <Title level={3} style={{ margin: 0, color: '#FFFFFF' }}>{totalVisits}</Title>
                                        </div>
                                        <div>
                                            <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#32e5ff', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Blocked numbers</Paragraph>
                                            <Title level={3} style={{ margin: 0, color: '#FFFFFF' }}>0</Title>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{ width: '100%', height: '500' }}>
                                    <Line data={visitChartData} options={options} />
                                </div> */}
                            </Flex>
                        </Card>
                        <br />
                        <Card hoverable style={{ border: 'none', backgroundColor: '#E9FAEA' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Space><Title level={4} style={{ margin: 0 }}>Total Customers with purchase</Title></Space>
                                <Tooltip title={
                                    <div>
                                        <Title level={1} style={{ margin: 0 }}><BulbFilled size={24} style={{ color: '#F9C23C' }} /></Title>
                                        <p>The total number of customers who have purchased till today with a breakup of Active (Who visited recently) and Inactive (who are at the risk of becoming lost)</p>
                                    </div>
                                }>
                                    <InfoCircleOutlined style={{ fontSize: 16 }} />
                                </Tooltip>
                            </div>
                            <Flex justify='space-between' align='center'>
                                <div>
                                    <Title level={1} style={{ marginTop: 16 }}>{repeatCustomrsCount}</Title>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <div style={{ marginRight: 16 }}>
                                                <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#04BEBD', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Active</Paragraph>
                                                <Title level={3} style={{ margin: 0 }}>{repeatCustomrsCount}</Title>
                                            </div>
                                            <div>
                                                <Paragraph style={{ marginTop: 10, marginBottom: 4, color: '#EBBD07', cursor: 'pointer', width: 'fit-content', fontSize: '16px', lineHeight: '22px', letterSpacing: '-.01em' }}>Inactive</Paragraph>
                                                <Title level={3} style={{ margin: 0 }}>0</Title>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 100 }}>
                                    <Doughnut data={donughtData} options={donughtOptions} />
                                </div>
                            </Flex>
                        </Card>
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <Card hoverable bordered={false}>
                            <Flex justify='center'>
                                {storeDetail?.store_logo !== null ? (
                                    <div className='home-card-image-logo-div'>
                                        <Image src={`${window.BASE_URL}${storeDetail?.store_logo}`} />
                                    </div>
                                ) : (
                                    <div className='home-card-logo-div'>
                                        <div style={{textAlign:'center'}}>
                                            <PiStorefrontDuotone size={36} />
                                            <Paragraph className='card-logo-name'>{storeDetail?.store_name?.slice(0, 8)}</Paragraph>
                                        </div>
                                    </div>
                                )}
                            </Flex>
                            <div className='text-center'>
                                <Title level={3}>{storeDetail?.store_name}</Title>
                                <Paragraph style={{ color: '#576464', fontWeight: 400, margin: 0 }}>{storeDetail?.industry} • {storeDetail?.category}</Paragraph>
                                <Flex justify='center' align='center' gap={16} style={{ marginTop: 16 }}>
                                    <span className='home-store-card-icon fb'><PiFacebookLogoDuotone size={24} /></span><span className='home-store-card-icon whatsapp'><PiWhatsappLogoDuotone size={24} /></span><span className='home-store-card-icon insta'><PiInstagramLogoDuotone size={24} /></span>
                                </Flex>
                            </div>
                            <hr style={{ marginTop: 16, marginBottom: 16, borderColor: 'rgb(0 0 0 / 5%)' }} />
                            <Flex justify='space-between' style={{ marginTop: 28, marginBottom: 92 }}>
                                <div>
                                    <Title level={5} style={{ fontSize: 14, display: 'flex', alignItems: 'center', gap: 8 }}><span className='home-store-card-icons'><PiPhoneCallDuotone /></span> {storeDetail?.store_number} </Title>
                                    {storeDetail?.store_website && (<Title level={5} style={{ fontSize: 14, display: 'flex', alignItems: 'center', gap: 8 }}><span className='home-store-card-icons'><PiBrowserDuotone /></span> {storeDetail?.store_website} </Title>)}
                                    <Title level={5} style={{ fontSize: 14, display: 'flex', alignItems: 'center', gap: 8 }}><span className='home-store-card-icons'><PiMapPinAreaDuotone /></span> {storeDetail?.store_address}{storeDetail?.city && `, ${storeDetail?.city}`}{storeDetail?.state && `, ${storeDetail?.state}`} {storeDetail?.postcode && `- ${storeDetail?.postcode}`}</Title>
                                </div>
                                <div className='qrcode-image-div'>
                                    <QRCode
                                        size={160}
                                        errorLevel="H"
                                        value={`https://t.zipler.io/${qrcode?.enc_qr_id}`}
                                        icon={`${window.BASE_URL}${storeDetail?.store_logo}`}
                                    />
                                </div>
                            </Flex>
                        </Card>
                    </div>
                </Col>
            </Row>
            {/* <Row gutter={16} className='mt16'>
                <Col span={12}>
                    <Card hoverable style={{ border: 'none', backgroundColor: '#F7F8F8' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Space><Title level={4} style={{ margin: 0 }}>Upcoming Celebrations</Title></Space>
                            <Link>See all</Link>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card hoverable style={{ border: 'none', backgroundColor: '#F7F8F8' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Space><Title level={4} style={{ margin: 0 }}>Customer Profile Completion</Title></Space>
                            <Tooltip title={<span>The total number and percentage of customers who have completed their profile details.</span>}>
                                <InfoCircleOutlined style={{ fontSize: 16 }} />
                            </Tooltip>
                        </div>
                    </Card>
                </Col>
            </Row> */}
        </Layout>
    );
}

export default Home;