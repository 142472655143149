import React, { useState } from 'react';
import { Layout, Row, Col, Typography, Card, Flex, Switch, Input, Button } from 'antd';
const { Paragraph, Title } = Typography;
const { TextArea } = Input;

const InboxSettings = ({ userData, sessionData, storeData }) => {
    const [outOfLoading, setOutOfLoading] = useState(false);
    const [welcomeLoading, setWelcomeLoading] = useState(false);
    const [updateOutOfLoading, setUpdateOutOfLoading] = useState(false);
    const [updateWelcomeLoading, setUpdateWelcomeLoading] = useState(false);
    const [isOutOf, setIsOutOf] = useState(userData?.is_out_office);
    const [isWelcome, setIsWelcome] = useState(userData?.is_welcome_message);
    const [outOfficeMessage, setOutOfficeMessage] = useState(userData?.out_office_message);
    const [welcomeMessage, setWelcomeMessage] = useState(userData?.welcome_message);

    const handleUpdate = async (msgtype) => {

    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <Title level={3}>Inbox settings</Title>
                    <Paragraph style={{ color: '#697777' }}>Manage Auto replies to user messages and improve your customer experience. Read here to know more about this section in detail.</Paragraph>
                </div>
            </Col>
            <Col span={24}>
                <Card hoverable title={<Flex justify='space-between' align='center'><Title level={5}>Out of office message</Title> <Switch loading={outOfLoading} checked={isOutOf === 'yes'} onChange={() => setIsOutOf(isOutOf === 'yes' ? 'no' : 'yes')} /></Flex>}>
                    <Paragraph>Set up your working hours and Out of office message. Please note that the auto reply gets triggered for new users and users whose conversation is marked closed.</Paragraph>
                    {isOutOf === 'yes' && (
                        <div>
                            <TextArea placeholder='Enter message for out of office.' rows={6} value={outOfficeMessage} onChange={(e) => setOutOfficeMessage(e.target.value)} maxLength={1024} showCount />
                            <Button type='primary' loading={updateOutOfLoading} onClick={() => handleUpdate('outof')}>Update Messge</Button>
                        </div>
                    )}
                </Card>
            </Col>
            <Col span={24} style={{ marginTop: 16 }}>
                <Card hoverable title={<Flex justify='space-between' align='center'><Title level={5}>Welcome message</Title> <Switch loading={welcomeLoading} checked={isWelcome === 'yes'} onChange={() => setIsWelcome(isWelcome === 'yes' ? 'no' : 'yes')} /></Flex>}>
                    <Paragraph>Configure Greeting message to be triggered when new customers reach out to your business for the first time or existing customers reach out after a period of 24 hours.</Paragraph>
                    {isWelcome === 'yes' && (
                        <div>
                            <TextArea placeholder='Enter welcome message' rows={6} value={welcomeMessage} onChange={(e) => setWelcomeMessage(e.target.value)} maxLength={1024} showCount />
                            <Button type='primary' loading={updateWelcomeLoading} onClick={() => handleUpdate('welcome')}>Update Messge</Button>
                        </div>
                    )}
                </Card>
            </Col>
        </Row>
    );
};
export default InboxSettings;