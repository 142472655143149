import React from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Layout, Space, Dropdown, Avatar, Typography, Select, Flex } from 'antd';
import { HiOutlineUser, HiOutlineBell, HiOutlineBuildingStorefront, HiOutlineClipboardDocumentList, HiOutlineUserPlus, HiOutlineCog, HiOutlinePlayCircle, HiOutlineQuestionMarkCircle, HiOutlineArrowRightOnRectangle } from "react-icons/hi2";
import { redirect, useNavigate } from 'react-router-dom';
import { PiWhatsappLogo } from 'react-icons/pi';

const { Header } = Layout;
const { Paragraph } = Typography;
const { Option } = Select;

const HeaderContent = ({ colorBgContainer, collapsed, setCollapsed, messageApi, userData, storeData, storeId, setStoreId }) => {
  const navigate = useNavigate();
  const key = 'updatable';
  const items = [
    {
      key: 'account',
      label: (
        <div style={{ minWidth: 300, display: 'flex', alignItems: 'center' }}>
          <Space direction="horizontal" size={16}>
            <Space wrap size={16}>
              <Avatar size={48} icon={<HiOutlineUser />} />
            </Space>
            <Paragraph style={{ marginTop: 10 }}>
              <b>{userData?.user_name}</b>
              <Paragraph>Store Owner</Paragraph>
            </Paragraph>
          </Space>
        </div>
      ),
    },
    {
      key: 'your-account',
      label: <div className='topBarMenuHovers'><HiOutlineUser size={24} /> <span style={{ marginLeft: 10 }}>Your Account</span></div>,
      navigateto: '/account',
    },
    ...(userData?.whatsappAccessToken !== '' ? [
      {
        key: 'whatsapp-settings',
        label: <div className='topBarMenuHovers'><PiWhatsappLogo size={24} /> <span style={{ marginLeft: 10 }}>WhatsApp Settings</span></div>,
        navigateto: '/whatsapp-settings',
      },
    ] : []),
    {
      key: 'stores',
      label: <div className='topBarMenuHovers'><HiOutlineBuildingStorefront size={24} /> <span style={{ marginLeft: 10 }}>Store Details</span></div>,
      navigateto: '/stores',
    },
    {
      key: 'segment-setting',
      label: <div className='topBarMenuHovers'><HiOutlineCog size={24} /> <span style={{ marginLeft: 10 }}>Segment Settings</span></div>,
      navigateto: '/segment-setting',
    },
    {
      key: 'plans',
      label: <div className='topBarMenuHovers'><HiOutlineClipboardDocumentList size={24} /> <span style={{ marginLeft: 10 }}>My Plans</span></div>,
      navigateto: '/plans',
    },
    // {
    //   key: 'invite-team',
    //   label: <div className='topBarMenuHovers'><HiOutlineUserPlus size={24} /> <span style={{ marginLeft: 10 }}>Invite Team</span></div>,
    //   navigateto: '/invite-team',
    // },
    {
      key: 'channels',
      label: <div className='topBarMenuHovers'><HiOutlineCog size={24} /> <span style={{ marginLeft: 10 }}>Channels</span></div>,
      navigateto: '/channels',
    },
    // {
    //   key: 'video-academy',
    //   label: <div className='topBarMenuHovers'><HiOutlinePlayCircle size={24} /> <span style={{ marginLeft: 10 }}>Video Academy</span></div>,
    //   navigateto: '/video-academy',
    // },
    // {
    //   key: 'help',
    //   label: <div className='topBarMenuHovers'><HiOutlineQuestionMarkCircle size={24} /> <span style={{ marginLeft: 10 }}>Help Center</span></div>,
    //   navigateto: '/help',
    // },
    {
      key: 'logout',
      label: <div className='topBarMenuHoversLogout'><HiOutlineArrowRightOnRectangle size={24} /> <span style={{ marginLeft: 10 }}>Logout</span></div>,
      navigateto: '/logout',
    }
  ];

  const handleMenuClick = async (e) => {
    const selectedItem = items.find(item => item.key === e.key);
    if (selectedItem.key === 'help') {
      console.log(selectedItem.key);
    } else if (selectedItem.key === 'logout') {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Loading...',
      });
      const url = `${window.API_BASE_URL}/auth/logout`;
      const token = localStorage.getItem('loginToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log(response);
        if (response.ok) {
          setTimeout(() => {
            messageApi.open({
              key,
              type: 'success',
              content: 'Logout Successful!',
              duration: 2,
            });
            setTimeout(() => {
              localStorage.removeItem('loginToken');
              localStorage.removeItem('deviceId');
              localStorage.removeItem('storeId');
              window.location.reload();
            }, 300);
          }, 1000);
        } else {
          const data = await response.json();
          console.error('Logout failed:', data.message);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    } else if (selectedItem.key === 'video-academy') {
      console.log(selectedItem.key);
    } else {
      if (selectedItem && selectedItem.navigateto) {
        navigate(selectedItem.navigateto);
      }
    }
  };

  const handleSelectStore = async (value) => {
    setStoreId(value);
    localStorage.setItem('storeId', value);
  };

  return (
    <Header className='mainHeader' style={{ background: colorBgContainer }}>
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: '18px',
          width: 64,
          height: 64,
        }}
      />
      <Space direction="vertical" style={{ width: '100%', padding: 24 }}>
        <Space className='topBarMenus'>
          <Flex align='center' justify='start'>
            <Select showSearch placeholder="Select Store" value={storeId !== '' ? storeData?.find(store => parseInt(store.store_id) === parseInt(storeId))?.store_name : undefined} onChange={handleSelectStore} filterOption={(inputValue, option) => option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0} style={{ width: 200, float: 'left' }} >
              {storeData && storeData.map((store) => (
                <Option value={store?.store_id} key={store?.store_id}>{store?.store_name}</Option>
              ))}
            </Select>
          </Flex>
          <Flex align='center' justify='end' gap={16}>
            {/* <Link className='clcLink'>
              <svg width="24" height="24" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#127df2', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#32d3d3', stopOpacity: 1 }} />
                  </linearGradient>
                </defs>
                <path fill="url(#grad1)" d="M253.66,133.66l-24,24a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L216,132.69V128A88,88,0,0,0,56.49,76.67a8,8,0,0,1-13-9.34A104,104,0,0,1,232,128v4.69l10.34-10.35a8,8,0,0,1,11.32,11.32Zm-41.18,55A104,104,0,0,1,24,128v-4.69L13.66,133.66A8,8,0,0,1,2.34,122.34l24-24a8,8,0,0,1,11.32,0l24,24a8,8,0,0,1-11.32,11.32L40,123.31V128a87.62,87.62,0,0,0,22.24,58.41A79.66,79.66,0,0,1,98.3,157.66a48,48,0,1,1,59.4,0,79.59,79.59,0,0,1,36.08,28.78,89.68,89.68,0,0,0,5.71-7.11,8,8,0,0,1,13,9.34ZM128,152a32,32,0,1,0-32-32A32,32,0,0,0,128,152Zm0,64a88.2,88.2,0,0,0,53.92-18.49,64,64,0,0,0-107.84,0A87.57,87.57,0,0,0,128,216Z" />
              </svg>
              Customer life cycle
            </Link> */}
            <Button>Book a Demo</Button>
            {/* <Button>Get free months</Button> */}
            <Button><HiOutlineBell size={24} /></Button>
            <Dropdown
              menu={{
                items: items.map(item => ({
                  ...item,
                  className: item.key === 'logout' ? 'topMenuDropdownsLogout' : 'topMenuDropdowns',
                })),
                onClick: handleMenuClick,
              }}
              placement="bottomLeft"
            >
              <Button><HiOutlineUser size={24} /></Button>
            </Dropdown>
          </Flex>
        </Space>
      </Space>
    </Header>
  );
};

export default HeaderContent;