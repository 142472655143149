import React from 'react';
import { Form, InputNumber, Select, Button, Row, Col, Typography } from 'antd';
const { Title } = Typography;
const CustomerSegmentSettings = () => {
    const [form] = Form.useForm();

    const initialValues = {
        potentialCustomer: 7,
        newCustomers: 15,
        promisingCustomerOrders: 2,
        promisingCustomerOrdersInDays: 7,
        potentialLoyalCustomerOrders: 10,
        potentialLoyalCustomerOrdersInDays: 30,
        loyalCustomerOrders: 30,
        loyalCustomerOrdersInDays: 120,
        vipCustomerSpendIn7Days: 500,
        vipCustomerSpendIn30Days: 2000,
        atRiskCustomer: 60,
        lostCustomer: 100,
        cantLoseCustomerSpendIn7Days: 500,
        cantLoseCustomerSpendIn30Days: 2000,
        aboutToSleepCustomer: 30,
        needAttention: 'cancel/refund/negative feedback',
        spenderLevel: ['Low Spenders', 'Medium Spenders', 'High Spenders', 'Top High Spenders'],
      };

    const handleSubmit = (values) => {
        console.log('Segment Conditions Saved:', values);
        // Save to backend or context store
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={initialValues}
            layout="vertical"
        >
            <Title level={4}>Define your customer segments</Title>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Form.Item label="Potential Customer (QR Scanned in Days)" name="potentialCustomer">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="New Customers (1 Order in Days)" name="newCustomers">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Promising (Orders)" name="promisingCustomerOrders">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Promising (Orders in Days)" name="promisingCustomerOrdersInDays">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Potential Loyal (Orders)" name="potentialLoyalCustomerOrders">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Potential Loyal (Orders in Days)" name="potentialLoyalCustomerOrdersInDays">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Loyal (Orders)" name="loyalCustomerOrders">
                        <InputNumber style={{width:'100%'}} min={1} max={120} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Loyal (Orders in Days)" name="loyalCustomerOrdersInDays">
                        <InputNumber style={{width:'100%'}} min={1} max={120} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="VIP Spend in 7 Days" name="vipCustomerSpendIn7Days">
                        <InputNumber style={{width:'100%'}} min={1} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="VIP Spend in 30 Days" name="vipCustomerSpendIn30Days">
                        <InputNumber style={{width:'100%'}} min={1} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="At Risk (No Order in Days)" name="atRiskCustomer">
                        <InputNumber style={{width:'100%'}} min={1} max={60} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Lost (No Order in Days)" name="lostCustomer">
                        <InputNumber style={{width:'100%'}} min={1} max={100} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Can't Lose Spend in 7 Days" name="cantLoseCustomerSpendIn7Days">
                        <InputNumber style={{width:'100%'}} min={1} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Can't Lose Spend in 30 Days" name="cantLoseCustomerSpendIn30Days">
                        <InputNumber style={{width:'100%'}} min={1} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="About to Sleep (No Order in Days)" name="aboutToSleepCustomer">
                        <InputNumber style={{width:'100%'}} min={1} max={30} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Spender Level" name="spenderLevel">
                        <Select mode="multiple" placeholder="Select spender levels">
                            <Select.Option value="Low Spenders">Low Spenders</Select.Option>
                            <Select.Option value="Medium Spenders">Medium Spenders</Select.Option>
                            <Select.Option value="High Spenders">High Spenders</Select.Option>
                            <Select.Option value="Top High Spenders">Top High Spenders</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label='&nbsp;'>
                        <Button type="primary" htmlType="submit" style={{width:'100%'}}>
                            Save Settings
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default CustomerSegmentSettings;
