import React, { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
    Layout,
    Row,
    Col,
    Typography,
    Flex,
    Button,
    Input,
    Spin,
    DatePicker,
    List,
    Skeleton,
    Avatar,
    Divider,
    Tooltip,
} from 'antd';
import { ArrowLeftOutlined, CloseOutlined, DownOutlined, LoadingOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import dayjs from 'dayjs';
import ChatModule from './ChatModule';

const { Paragraph, Title } = Typography;
const { Search } = Input;
const { RangePicker } = DatePicker;

const isEmoji = (character) => {
    const emojiRegex = /(\p{Emoji})/u;
    return emojiRegex.test(character);
};

const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'This Month',
        value: [dayjs().startOf('month'), dayjs().endOf('month')],
    },
    {
        label: 'Last Month',
        value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
        label: 'Last 12 Months',
        value: [dayjs().subtract(12, 'months'), dayjs()],
    },
    {
        label: 'This Year',
        value: [dayjs().startOf('year'), dayjs().endOf('year')],
    },
    {
        label: 'Custom Range',
        value: [null, null],
    },
    {
        label: 'All Time',
        value: [dayjs('0000-00-00'), dayjs()],
    },
];

const getMessage = (message) => {
    try {
        const parsedMessage = JSON.parse(message);
        if (parsedMessage.body) {
            return parsedMessage.body;
        } else {
            return parsedMessage;
        }
    } catch (e) {
        return message;
    }
};

function getTimeFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12;

    const hours12 = twelveHourFormat.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');

    return `${hours12}:${minutesStr} ${amPM}`;
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date >= today) {
        if (date >= tomorrow) {
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        } else if (date >= today) {
            return getTimeFromDate(date);
        }
    } else if (date >= yesterday) {
        return 'Yesterday';
    } else if (date >= today - 6 * 24 * 60 * 60 * 1000) {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    }

    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
};

const truncateMessage = (notes) => {
    if (notes.length > 20) {
        return notes.substring(0, 20) + '...';
    }
    return notes;
};

const Chats = ({ userData, sessionData, socketData }) => {
    const [showSearch, setShowSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [chatUserData, setChatuserData] = useState([]);
    const [selectedRange, setSelectedRange] = useState([moment().subtract(30, 'days'), moment()]);
    const [selectedLabel, setSelectedLabel] = useState('Last 30 Days');
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const searchInputRef = useRef(null);
    const [customRange, setCustomRange] = useState([dayjs().add(-30, 'd'), dayjs()]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [selectedUser, setSelectedUser] = useState({});
    const isFirstRender = useRef(true);

    const handleSearch = (value) => {
        setSearchQuery(value.toLowerCase());
    };

    const handleChatBox = (user) => {
        setChatuserData((prevData) => {
            const updatedData = prevData.map((userdata) => {
                if (userdata.customer_id === user.customer_id) {
                    return { ...userdata, msgstatus: 'read' };
                }
                return userdata;
            });
            return updatedData;
        });
        setSelectedUser(user);
    };

    const loadMoreData = async (page) => {
        if (loading) {
            return;
        }
        setLoading(true);
        let url;
        if (page === 1) {
            url = `${window.API_BASE_URL}/chats/users?page=${page}&search=${searchQuery}&start_date=${selectedRange[0]?.toISOString()}&end_date=${selectedRange[1]?.toISOString()}&phoneid=${userData?.phonenumberid}`;
        } else {
            url = `${window.API_BASE_URL}/chats/users?page=${currentPage}&search=${searchQuery}&start_date=${selectedRange[0]?.toISOString()}&end_date=${selectedRange[1]?.toISOString()}&phoneid=${userData?.phonenumberid}`;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });

            const data = response.data;
            if (response.status === 200) {
                console.log(data?.customers);
                setChatuserData(prevData => [...prevData, ...data?.customers]);
                if (page === 1) {
                    setCurrentPage(1 + 1);
                } else {
                    setCurrentPage(prevPage => prevPage + 1);
                }
                setHasMore(data?.customers.length > 19);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching user data:', data.message);
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                console.error('Error during fetching store drops:', error.response.data.message);
            } else {
                console.error('Error during fetching store drops:', error.message);
            }
        } finally {
            setLoading(false);
            setLoadingUsers(false);
        }
    };

    useEffect(() => {
        if (socketData) {
            console.log(socketData);
            switch (socketData?.is_message) {
                case 'from':
                    if (socketData?.phone_number_id === userData?.phonenumberid) {
                        const countryCode = socketData.wanumber.substring(0, 2);
                        const customerPhone = socketData.wanumber.substring(2);
                        const customerName = socketData.whatsappname || "Unknown";
                        if (socketData?.is_user === 'new') {
                            const newUserData = {
                                customer_id: socketData?.user_id?.insertId,
                                customer_number: socketData?.wanumber,
                                customer_name: customerName,
                                created: Math.floor(Date.now() / 1000).toString(),
                                message: socketData?.message,
                                msgtype: socketData?.msgtype,
                                msgstatus: "unread"
                            };
                            setChatuserData(prevData => [newUserData, ...prevData]);
                        } else {
                            setChatuserData((PrevData) => {
                                const updatedData = PrevData.map((userdata) => {
                                    if (userdata.customer_number === socketData.wanumber) {
                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: socketData?.message, msgstatus: selectedUser ? selectedUser?.customer_id === userdata?.customer_id ? 'read' : 'unread' : 'unread', msgtype: socketData?.msgtype };
                                    }
                                    return userdata;
                                });
                                const movedUser = updatedData.find((userdata) => userdata.customer_number === socketData.wanumber);
                                const restOfUsers = updatedData.filter((userdata) => userdata.customer_number !== socketData.wanumber);
                                return [movedUser, ...restOfUsers];
                            });
                        }
                    }
                    break;
                case 'to':
                    // setChatuserData(prevData => prevData.map(chat => chat.mainid === socketData.id ? { ...chat, status: socketData.status } : chat));
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketData]);

    useEffect(() => {
        if (sessionData?.token) {
            loadMoreData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionData?.token]);

    const handleCustomRangeChange = (dates) => {
        if (dates) {
            const selectedItem = rangePresets.find(item => item.value[0]?.isSame(dates[0]) && item.value[1]?.isSame(dates[1]));
            setCustomRange(dates);
            const selectedCustomRange = [moment(dates[0].startOf('day').format('YYYY-MM-DD')), moment(dates[1].endOf('day').format('YYYY-MM-DD'))];
            setSelectedRange(selectedCustomRange);
            if (selectedItem) {
                if (selectedItem.label === 'All Time') {
                    setCustomRange([null, null]);
                    setSelectedRange([null, null]);
                }
                setSelectedLabel(selectedItem.label);
            } else {
                setSelectedLabel('Custom Range');
            }
        } else {
            setCustomRange([null, null]);
            setSelectedRange([null, null]);
            setSelectedLabel('All Time');
        }
        setShowDateRangePicker(false);
    };

    const handleClickOutside = (event) => {
        if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
            setShowSearch(false);
        }
    };

    useEffect(() => {
        if (searchQuery === '') {
            if (showSearch) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [searchQuery, showSearch]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setChatuserData([]);
        setCurrentPage(1);
        setHasMore(true);
        loadMoreData(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, selectedRange]);

    const memoizedFilteredData = useMemo(() => {
        return chatUserData.filter(user => {
            const nameMatch = user?.customer_name?.toLowerCase().includes(searchQuery);
            const phoneMatch = user?.customer_number?.toLowerCase().includes(searchQuery);
            if (selectedRange[0] && selectedRange[1] && searchQuery === '') {
                const dateMatch = user?.created ? moment(user?.created * 1000).isBetween(selectedRange[0], selectedRange[1], 'day', '[]') : moment(user?.created_at).isBetween(selectedRange[0], selectedRange[1], 'day', '[]');
                return (nameMatch || phoneMatch) && dateMatch;
            } else {
                return (nameMatch || phoneMatch);
            }
        });
    }, [chatUserData, searchQuery, selectedRange]);

    return loadingUsers ? (
        <Layout className='contentLayout'>
            <div className='loaderDiv'>
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{ fontSize: 48 }}
                            spin
                        />
                    }
                />
            </div>
        </Layout>
    ) : (
        <Layout className='contentLayout'>
            <Row gutter={16}>
                <Col span={6}>
                    {showSearch ? (
                        <Flex gap={16} justify='space-between' align='center' ref={searchInputRef} style={{ marginBottom: 16 }}>
                            <Search
                                className='chatboxSearch'
                                placeholder="Search by Name, phone ."
                                onSearch={handleSearch}
                                autoFocus
                                allowClear
                                addonBefore={
                                    <Paragraph className='searchCloseBtn' onClick={() => { setShowSearch(false); setSearchQuery('') }}>
                                        <ArrowLeftOutlined />
                                    </Paragraph>
                                }
                            />
                        </Flex>
                    ) : (
                        <div style={{ marginBottom: 16 }}>
                            <Flex gap={16} justify='space-between' align='center'>
                                <Title level={4} style={{ margin: 0 }}>Chats</Title>
                                <Button type='link' style={{ color: "#000000" }} onClick={() => setShowDateRangePicker(true)}>
                                    {selectedLabel} <DownOutlined />
                                </Button>
                                {searchQuery === '' ? (
                                    <Button icon={<SearchOutlined />} onClick={() => setShowSearch(true)} />
                                ) : (
                                    <Button icon={<CloseOutlined />} onClick={() => setSearchQuery('')} />
                                )}
                            </Flex>
                            {showDateRangePicker === true && (
                                <div className='dateRangePickerDiv'>
                                    <RangePicker
                                        presets={rangePresets}
                                        value={customRange}
                                        onChange={handleCustomRangeChange}
                                        style={{ marginTop: '10px', width: '100%' }}
                                        placeholder={['Start date', 'End date']}
                                        autoFocus
                                        defaultOpen
                                        className='dateRangePicker'
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div className='chat-users' id='chat-users'>
                        <InfiniteScroll
                            dataLength={memoizedFilteredData.length}
                            next={loadMoreData}
                            hasMore={hasMore}
                            loader={
                                <Skeleton
                                    avatar
                                    paragraph={{
                                        rows: 1,
                                    }}
                                    active
                                />
                            }
                            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                            scrollableTarget="chat-users"
                        >
                            <List
                                dataSource={memoizedFilteredData}
                                renderItem={(user) => (
                                    <List.Item key={user?.customer_id} className={`chat-user-item ${selectedUser?.customer_id === user?.customer_id && 'active'}`} onClick={() => handleChatBox(user)}>
                                        <List.Item.Meta className={`${(!user.msgstatus ? ('read') : (user.msgstatus))}`}
                                            avatar={<Avatar>{user?.customer_name ? isEmoji(user?.customer_name?.trim()?.charAt(0)) ? <UserOutlined /> : user?.customer_name?.trim()?.charAt(0)?.toUpperCase() : <UserOutlined />}</Avatar>}
                                            title={
                                                <Tooltip title={user?.customer_name}>
                                                    {user?.customer_name && user?.customer_name?.length > 15
                                                        ? `${user?.customer_name?.substring(0, 15)}...`
                                                        : user?.customer_name}
                                                </Tooltip>
                                            }
                                            description={<div>{
                                                user?.msgtype === 'text' ? <span className="last-conv"><Tooltip title={getMessage(user?.message)}>{truncateMessage(getMessage(user?.message))}</Tooltip></span> : <span className="last-conv">{user?.msgtype}</span>}
                                                {user?.created === null ? <span>&nbsp;</span> : <span className="last-conv-time">{formatDate(user?.created)}</span>}</div>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                </Col>
                <Col span={18}>
                    <ChatModule selectedUser={selectedUser} sessionData={sessionData} socketData={socketData} userData={userData} />
                </Col>
            </Row>
        </Layout>
    );
};

export default Chats;