import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

const FooterContent = () => {

    return(
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          zipler.io ©{new Date().getFullYear()} Created by zipler.io
        </Footer>
    );

};

export default FooterContent;