import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Flex, Button, Card, Dropdown, Typography, Input, DatePicker } from 'antd';
import { GiftOutlined, PercentageOutlined, ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const { Paragraph, Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const datePickerItems = [
    {
        key: '1',
        label: 'Highlights for Today'
    },
    {
        key: '2',
        label: 'Last 7 Days'
    },
    {
        key: '3',
        label: 'Last 30 Days'
    },
    {
        key: '4',
        label: 'This Month'
    },
    {
        key: '5',
        label: 'Last Month'
    },
    {
        key: '6',
        label: 'Last 12 Month'
    },
    {
        key: '7',
        label: 'Custom range'
    }
];
const CampaignPreformence = ({ userData, sessionData, storeData, socketData }) => {
    const storeDetail = storeData?.filter(store => store?.store_id.toString() === localStorage.getItem('storeId'))?.[0];
    const navigate = useNavigate();
    const [campaignData, setCampaignData] = useState([]);
    const [customers, setCustomers] = useState([]);

    const endDate = dayjs().format('D, MMM YY');
    const startDate = dayjs().subtract(30, 'days').format('D, MMM YY');

    const fetchCustomerData = useCallback(async () => {
        const url = `${window.API_BASE_URL}/users/campaign-customers/?store_id=${storeDetail?.store_id}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                console.log(data);
                setCustomers(data.user);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching customer data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching customer data:', error);
        }
    }, [sessionData, storeDetail]);

    const fetchCampaigns = useCallback(async () => {
        const url = `${window.API_BASE_URL}/templates/all-campaigns/?store_id=${storeDetail?.store_id}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionData?.token}`,
                },
            });
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                console.log('campaigns', data);
                setCampaignData(data.campaigns);
            } else {
                if (data.message === 'Invalid token') {
                    localStorage.removeItem('loginToken');
                    localStorage.removeItem('deviceId');
                    localStorage.removeItem('storeId');
                }
                console.error('Error fetching customer data:', data.message);
            }
        } catch (error) {
            console.error('Error during fetching customer data:', error);
        }
    }, [sessionData, storeDetail]);

    useEffect(() => {
        if (sessionData) {
            fetchCampaigns();
            fetchCustomerData();
        }
    }, [fetchCustomerData, fetchCampaigns, sessionData]);

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card bordered={false} style={{ backgroundColor: '#EFF8FF' }}>
                    <Flex gap='middle' align='center'>
                        <Title level={4} style={{ margin: 0 }}>Summary</Title>
                        <Dropdown
                            menu={{
                                items: datePickerItems,
                            }}
                            className='summarydroptitle'
                        ><span >Last 30 Days {startDate} - {endDate} <DownOutlined /></span>
                        </Dropdown>
                    </Flex>
                    <Row gutter={[16, 16]} style={{ backgroundColor: '#FFFFFF', padding: 16, marginTop: 16, marginBottom: 16, borderRadius: 16 }}>
                        <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                            <div style={{ textAlign: 'left' }}>
                                <Paragraph style={{ margin: 0 }}>Campaigns sent</Paragraph>
                                <Title level={4} style={{ margin: 0 }}><b>{campaignData?.length}</b></Title>
                            </div>
                        </Col>
                        <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                            <div style={{ textAlign: 'left' }}>
                                <Paragraph style={{ margin: 0 }}>Revenue</Paragraph>
                                <Title level={4} style={{ margin: 0 }}><b>{storeDetail?.currency_symbol} {customers ? customers?.reduce((acc, { total_spent }) => parseFloat(acc) + parseFloat(total_spent), 0) : 0}</b></Title>
                            </div>
                        </Col>
                        <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                            <div style={{ textAlign: 'left' }}>
                                <Paragraph style={{ margin: 0 }}>Avg visit rate</Paragraph>
                                <Title level={4} style={{ margin: 0 }}><b>{campaignData?.reduce((acc, { total_sent }) => acc + parseFloat(total_sent), 0) ? ((campaignData?.reduce((acc, { total_visits }) => acc + parseFloat(total_visits), 0) / campaignData?.reduce((acc, { total_sent }) => acc + parseFloat(total_sent), 0)) * 100).toFixed(2) : 0}%</b></Title>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={{ textAlign: 'left' }}>
                                <Paragraph style={{ margin: 0 }}>Total visits</Paragraph>
                                <Title level={4} style={{ margin: 0 }}><b>{campaignData?.reduce((acc, { total_visits }) => parseFloat(acc) + parseFloat(total_visits), 0)}</b></Title>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Row>
                    <Col span={24}>
                        <Flex gap="middle" justify="space-between" align='center'>
                            <Title level={2}>Recent Campaigns</Title>
                        </Flex>
                    </Col>
                </Row>
                {campaignData.length > 0 && campaignData.map((campaign) => (
                <Row style={{ alignItems: 'center', marginBottom: 16 }}>
                    <Col span={5}>
                        <div className='campaignPerfomenceParagraphdiv'>
                            <Paragraph className={campaign?.campaign_sent_status === 'ongoing' ? 'topTitle' : 'topTitleComp'}>{campaign?.campaign_sent_status?.charAt(0)?.toUpperCase() + campaign?.campaign_sent_status?.slice(1)}</Paragraph>
                            <Title className='titleTwo' level={4}>{campaign?.campaign_title}</Title>
                            <span className='iconSpan'>
                                <GiftOutlined />
                            </span>
                        </div>
                    </Col>
                    <Col span={19}>
                        <div className='campaignPerfomencegraphdiv'>
                            <Row gutter={[16, 16]} style={{ backgroundColor: '#FFFFFF', padding: 16, marginTop: 16, marginBottom: 16, borderRadius: 16 }}>
                                <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Paragraph style={{ margin: 0 }}>Revenue</Paragraph>
                                        <Title level={4} style={{ margin: 0 }}><b>{storeDetail?.currency_symbol} {customers ? customers?.filter(customer => customer?.campaign_id === campaign?.campaign_id).reduce((acc, { total_spent }) => acc + (total_spent ? parseFloat(total_spent) : 0), 0) : 0}</b></Title>
                                    </div>
                                </Col>
                                <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Paragraph style={{ margin: 0 }}>Sent</Paragraph>
                                        <Title level={4} style={{ margin: 0 }}><b>{campaign?.total_sent}</b></Title>
                                    </div>
                                </Col>
                                <Col span={6} style={{ borderRight: '1px solid #dddddd' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Paragraph style={{ margin: 0 }}>Visited</Paragraph>
                                        <Title level={4} style={{ margin: 0 }}><b>{campaign?.total_visits}</b></Title>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Paragraph style={{ margin: 0 }}>Visit Rate</Paragraph>
                                        <Title level={4} style={{ margin: 0 }}><b>{((campaign?.total_visits || 0 / campaign?.total_sent || 0) * 100).toFixed(2)}%</b></Title>
                                    </div>
                                </Col>
                            </Row>
                            <Flex gap='middle' justify='space-between' align='center'>
                                <div>
                                    <Paragraph style={{ margin: 0 }}><span style={{ color: '#616161' }}>Duration:</span> {dayjs(campaign?.created_at).format("DD MMM, h:mm a")} - {dayjs(campaign?.updated_at).format("DD MMM, h:mm a")} </Paragraph>
                                    <Paragraph style={{ margin: 0 }}><span style={{ color: '#616161' }}>Channels:</span> {Object.keys(JSON.parse(campaign?.channels)).filter(key => JSON.parse(campaign?.channels)[key]).join(', ').toUpperCase()}</Paragraph>
                                </div>
                                <Flex gap='middle'>
                                    <Button className='showDetailsBtn' onClick={() => navigate('/customer-insights/customer-list/')}>Show Details <ArrowRightOutlined /></Button>
                                </Flex>
                            </Flex>
                        </div>
                    </Col>
                </Row>
                ))}
            </Col>
        </Row>
    );
};

export default CampaignPreformence;